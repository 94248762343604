import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import * as Utility from "../Shared/Utility";
import { getModeForUsageLocation } from "typescript";

type BPMGraphProps = {
  beatDivShowHide: boolean;
  mmsValueSplitView: any;
  globalW: any;
  SelectedDataSet: any;
  BPMDataSet: any;
  DataStartDate: Date;
  DataEndDate: Date;
  handleMoveGraph: Function;
  setintervalSplitView: Function;
  SelectedTime: any;
  lastClick: any;
  setlastClick: Function;
  AppArea: any;
};

function BPMGraph(props: BPMGraphProps) {
  const svgBPMGraphRef = useRef<any>();
console.log("Beats View: " + props.globalW)
  const [clickPositionX, setclickPositionX] = useState(-1);

  let svgLeft = 0;
  let svgTop = 0;
  let gridWidth = 0;
  let range = 0;
  let margin = { top: 10, right: 40, bottom: 25, left: 30 };

  function getSVGx(x: any) {
    return x - svgLeft;
  }

  function getSVGy(y: any) {
    return y - svgTop;
  }

  function getTimeFromXPixel(x: any) {
    let graphX = x - margin.left;
    let newStartTime = Math.floor((graphX / gridWidth) * range);
    return newStartTime;
  }

  function getXPixelFromTime(inputTime: any) {
    let x = 0;

    x = (inputTime / range) * gridWidth;

    return x + margin.left;
  }

  const MouseDown = (e: any) => {
    let localX = getSVGx(e.pageX);
    setclickPositionX(localX);
    let newStartTime = getTimeFromXPixel(localX);
    props.handleMoveGraph(newStartTime, 0);
    props.setlastClick(props.AppArea.BPMGraph);
  };

  function getNearestIndexFromGridX(x: any) {
    let newStartTime = getTimeFromXPixel(x);
    let index = Math.floor(newStartTime / 6);
    if (index >= 0 && index < props.BPMDataSet.length) {
      return index;
    }
    return -1;
  }

  // This is for drawing the HRTrends view
  useEffect(() => {
    //  if(moveStartChanged) {
    //  if (moveStartTime > 0) {
    //    props.handleMoveGraph(moveStartTime, 0);
    //  }
    //}

    if (
      props.beatDivShowHide === true &&
      props.BPMDataSet &&
      props.BPMDataSet.length > 0
    ) {
      //      var BottomDivWidth = document.getElementById("BPMGraphDiv")?.clientWidth;
      let w = props.globalW;
      let heightOfSVG = 220;
      let maxHR = 200;
      //      if (BottomDivWidth) {
      //        w = BottomDivWidth - 10;
      //      }
      gridWidth = w - (margin.left + margin.right);

      let localIntervalSplitView = w / (props.mmsValueSplitView * 5);
      props.setintervalSplitView(localIntervalSplitView);

      let svgBPMGraph = d3
        .select(svgBPMGraphRef.current)
        .attr("width", w) //globalW
        .attr("height", heightOfSVG)
        .on("mousedown", MouseDown);
      //        .style("overflow", "hidden");

      let boundingRect: any = document
        .getElementById("bpmGraphSVG")
        ?.getBoundingClientRect();

      svgLeft = boundingRect.left;
      svgTop = boundingRect.top;

      range =
        props.BPMDataSet[props.BPMDataSet.length - 1].StartTime -
        props.BPMDataSet[0].StartTime;

      // setting the scaling
      let xScale = d3
        .scaleLinear()
        .domain([
          props.BPMDataSet[0].StartTime,
          props.BPMDataSet[props.BPMDataSet.length - 1].StartTime,
        ])
        .range([margin.left, w - margin.right]);

      let yScale = d3
        .scaleLinear()
        .domain([0, maxHR])
        .range([heightOfSVG - margin.bottom, margin.top]);

      let StartDateTime = new Date(props.DataStartDate);
      //console.log("Start Before adding : " + StartDateTime);
      StartDateTime.setSeconds(props.BPMDataSet[0].StartTime);
      //console.log("Start after adding : " + StartDateTime);
      let EndDateTime = new Date(props.DataEndDate);
      EndDateTime.setSeconds(
        props.BPMDataSet[props.BPMDataSet.length - 1].StartTime + 1
      );

      // Making another scale to show the x axis - not using this as the main scale since converting seconds to datetime would be inefficient
      let xScaleTime = d3
        .scaleTime()
        .domain([StartDateTime, EndDateTime])
        .range([margin.left, w - margin.right]);

      d3.selectAll(".bpmGraphView").remove();
      d3.selectAll("#bpmGraphViewLeftAxis").remove();
      d3.selectAll("#bpmGraphViewRightAxis").remove();
      d3.selectAll("#bpmGraphViewBottomAxis").remove();

      svgBPMGraph
        .append("g")
        .attr("id", "bpmGraphViewLeftAxis")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale));

      svgBPMGraph
        .append("g")
        .attr("id", "bpmGraphViewRightAxis")
        .attr("transform", `translate(${w + -margin.right},0)`)
        .call(d3.axisRight(yScale));

      svgBPMGraph
        .append("g")
        .attr("id", "bpmGraphViewBottomAxis")
        .attr("transform", `translate(0,${heightOfSVG + -margin.bottom})`)
        .call(d3.axisBottom(xScaleTime));

      d3.selectAll(".gridlineBPM").remove();
      // add the gridlines
      svgBPMGraph
        .selectAll(".gridlineBPM")
        .data(yScale.ticks())
        .enter()
        .append("line")
        .attr("class", "gridlineBPM")
        .attr("x1", margin.left)
        .attr("y1", (d: number) => yScale(d))
        .attr("x2", margin.left + gridWidth)
        .attr("y2", (d: number) => yScale(d))
        .attr("stroke", "#EEE");

      if (props.BPMDataSet && props.BPMDataSet.length > 1) {
        // Draw the dots
        svgBPMGraph
          .selectAll("circle")
          .raise()
          .data(props.BPMDataSet)
          .enter()
          .append("circle")
          .attr("cx", (d: any) => xScale(d.StartTime))
          .attr("cy", (d: any) => yScale(d.BPM))
          .attr("r", 1);
      }

      d3.selectAll(".clickBPMGraphLine").remove();
      d3.selectAll(".clickBPMGraphExternal").remove();

      if (
        props.lastClick === props.AppArea.BPMGraph &&
        clickPositionX > margin.left &&
        clickPositionX < margin.left + gridWidth
      ) {
        //alert("clickPositionX : " + clickPositionX);
        svgBPMGraph
          .append("rect")
          .attr("class", "clickBPMGraphLine")
          .attr("x", clickPositionX - 1)
          .attr("y", margin.top + 15)
          .attr("width", 2)
          .attr("height", heightOfSVG - (margin.top + margin.bottom + 15))
          .attr("fill", "rgba(70, 130, 180,0.6)");

        let index = getNearestIndexFromGridX(clickPositionX);

        if (index >= 0) {
          svgBPMGraph
            .append("text")
            .attr("class", "clickBPMGraphLine")
            .text(props.BPMDataSet[index].BPM + " bpm")
            .attr("fill", "#666666")
            .attr("font-size", "11px")
            .attr("x", clickPositionX - 16)
            .attr("y", 20);
        }
      } else {
        //      console.log(Utility.ConvertTimeFormatIntoSeconds(props.SelectedTime));
        let timeInSeconds = Utility.ConvertTimeFormatIntoSeconds(
          props.SelectedTime
        );
        if (timeInSeconds > 0) {
          //console.log("clickPositionX : " + clickPositionX);
          // getXPixelFromTime(props.SelectedTime)
          svgBPMGraph
            .append("rect")
            .attr("class", "clickBPMGraphExternal")
            .attr("x", getXPixelFromTime(timeInSeconds))
            .attr("y", margin.top)
            .attr("width", 2)
            .attr("height", heightOfSVG - (margin.top + margin.bottom))
            .attr("fill", "rgba(70, 130, 180,0.6)");
        }
      }
    }
  }, [props.beatDivShowHide, clickPositionX, props.SelectedTime,props.globalW]);

  return (
    <div className="ecg--trends__right">
      <svg id="bpmGraphSVG" ref={svgBPMGraphRef}></svg>
    </div>
  );
}

export default BPMGraph;
