import Box from "../Box";
//import PatientReportLayout from './../PatientReportLayout'
import MCTSummaryReport from "./ReportsType/MCTSummaryReport";

export default function ReportsPage() {
  return (
    //<PatientReportLayout>
    <>
      <TechnicianComments />
      <MCTSummaryReport />
    </>
    // </PatientReportLayout>
  );
}

function TechnicianComments() {
  return (
    <Box width="w-full" pb="pt-8" pt="0" px="0">
      <h2 className="  flex  items-center text-xl font-bold px-[74px]  ">
        Technician Comments
      </h2>
      <div className=" px-[74px] pt-6 pb-16 text-[#616161]  ">
        <p> 1. Predominant rhythm was sinus.</p>
        <p> 2. Min HR of 56 bpm Max HR of 98 bpm and Avg HR of 55 bpm.</p>
        <p> 3. Supraventricular ectopics were noted.</p>
        <p> 4. Ventricular ectopics were noted.</p>
        <div className="mt-8"> KM.CCT/MCT</div>
      </div>
    </Box>
  );
}
