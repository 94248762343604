import React, { useState, useRef, useEffect } from "react";
import { EdfDecoder } from "./EDF/EdfDecoder";
import ECG from "./components/Pages/ECG";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import AppContext from "./components/Context/Context";
import { Routes, Route } from "react-router-dom";
import Patient from "./components/Pages/Patient";
import { useNavigate } from "react-router-dom";
import Login from "./components/Pages/Login";
import RequireAuth from "./components/Auth/RequireAuth";
import { useLocation } from "react-router-dom";
import "./style.css";
import axios, { AxiosError } from "axios";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";
import * as Utility from "./components/Shared/Utility";
function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showAnimation, setShowAnimation] = useState(false);
  const [showErrorPopup, setshowErrorPopup] = useState(false);
  const [buffer, setBuffer] = useState<any>(null);
  const [progressBarCoutDown, setProgressBarCoutDown] = useState(0);

  const [selectedView, setSelectedView] = useState(AppContext.view.Analysis);
  const [isEventClicked, setIsEventClicked] = useState(false);

  const [patientName, setPatientName] = useState("Arlene Wilson");

  const [dataSetForSelectedPatient, setDataSetForSelectedPatient] = useState(2);

  const [isLeftMenu, setIsLeftMenu] = useState(true);

  const [showTestSymbols, SetshowTestSymbols] = useState(false);

  const ShowHideTestSymbols = () => {
    SetshowTestSymbols(!showTestSymbols);
  };

  function ShowHideErrorModal() {
    setshowErrorPopup(!showErrorPopup);
  }

  function redirectToPatient() {
    navigate("/patient");
  }

  function redirectToECG() {
    navigate("/ecg");
  }
  function EDFFileReader(e1: any) {
    setProgressBarCoutDown(50);
    var files = e1.target?.files;
    var reader = new FileReader();
    if (!files.length) {
      return;
    }
    setshowErrorPopup(false);
    reader.onloadend = function (event) {
      var l_buffer = event.target?.result;
      setBuffer(l_buffer);
      //readEdfFile(buffer);
    };
    reader.readAsArrayBuffer(files[0]);
  }

  function readEdfFile(buff: any) {
    var decoder: any = new EdfDecoder();
    decoder.setInput(buff);
    decoder.decode();
    var output = decoder.getOutput();
    console.log(output);
  }

  function logOutUser() {
    AppContext.SetCookie(AppContext.Token, "", 0);
    navigate("/login");
  }

  const [fileUrl, setFileUrl] = useState(
    //"https://firebasestorage.googleapis.com/v0/b/pulse-gate.appspot.com/o/00-16-48.EDF-7949089.edf?alt=media&token=43714c74-6f5d-4ec8-bebf-9d87e7715981"

    //"https://firebasestorage.googleapis.com/v0/b/pulse-gate.appspot.com/o/00-46-30.EDF-2204176.edf?alt=media&token=5162c7b3-5a69-4c05-9747-ef246bf2e595"

    "https://firebasestorage.googleapis.com/v0/b/pulse-gate.appspot.com/o/14-47-52.EDF-638D7F2BC526F.EDF-9590280.edf?alt=media&token=dd871e38-61a9-42bf-b207-1228fe53e4b3"
  );

  function DownloadAndReadEDF() {
    handleFileDownload();
  }

  const handleFileDownload = async () => {
    setShowAnimation(true);
    try {
      console.log("Fetching file from:", fileUrl);

      const response = await axios.get(fileUrl, {
        responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      //const blob = await response.data;

      // Create a File object from the blob
      const fileName = "downloaded-file.edf"; // You can set any desired file name
      const newFile = await new File([blob], fileName, {
        type: response.headers["content-type"] || undefined,
      });
      setShowAnimation(false);
      // Set the File object in the component state
      EDFFileReaderV2(newFile);

      // You can now use 'newFile' as a File object in your application
    } catch (error) {
      console.error("Error converting URL to File:", error);
    }
  };

  function EDFFileReaderV2(file: any) {
    setProgressBarCoutDown(50);
    var reader = new FileReader();
    reader.onloadend = function (event) {
      var l_buffer = event.target?.result;
      setBuffer(l_buffer);
    };
    reader.readAsArrayBuffer(file);
  }

  return (
    <>
      {showAnimation && <LoadingAnimation />}
      {location.pathname !== "/" && location.pathname !== "/login" && (
        <div className="header">
          <div className="header--left">
            {selectedView !== AppContext.view.Patient && (
              <a
                title="Show/Hide Bookmarks Panel"
                onClick={() => setIsLeftMenu(!isLeftMenu)}
                className={`${
                  isLeftMenu === true ? "left--menu--btn" : " left--menu--btn"
                }`}
                // className="btn--left__menu"
              >
                <img
                  src="/images/m1.svg"
                  width="20px"
                  height="20px"
                  alt="cross"
                  className=""
                />
              </a>
            )}
            <img
              src="/images/logo.png"
              className="holter-logo"
              width="20px"
              height="20px"
              alt="cross"
            />
            <div className="menu-top-tabs">
              <a
                onClick={() => {
                  setSelectedView(AppContext.view.Patient);
                  redirectToPatient();
                }}
                className={`${
                  selectedView === AppContext.view.Patient ? "selected" : ""
                }`}
              >
                Patient
              </a>
              <a
                onClick={() => {
                  setSelectedView(AppContext.view.Analysis);
                  redirectToECG();
                }}
                className={`${
                  selectedView === AppContext.view.Analysis ? "selected" : ""
                }`}
              >
                Analysis
              </a>
              <a
                onClick={() => {
                  setSelectedView(AppContext.view.Events);
                  setIsEventClicked(true);
                }}
                className={`${
                  selectedView === AppContext.view.Events ? "selected" : ""
                }`}
              >
                Events
              </a>
              <a
                onClick={() => {
                  setSelectedView(AppContext.view.Strips);
                }}
                className={`${
                  selectedView === AppContext.view.Strips ? "selected" : ""
                }`}
              >
                Strips
              </a>
              <a
                onClick={() => setSelectedView(AppContext.view.Reports)}
                className={`${
                  selectedView === AppContext.view.Reports ? "selected" : ""
                }`}
              >
                Reports
              </a>
            </div>
          </div>
          <div className="header--right">
            {/* <div className="ecg--right__buttons">
            <a className="ecg--right__a" onClick={ShowHideErrorModal}>
              +
            </a>
            <a className="btn--save">Save</a>
            <a className="btn--close">Close</a>
          </div> */}
            {selectedView !== AppContext.view.Patient && (
              <div className="patient--data">
                <span className="p--name" onClick={ShowHideErrorModal}>
                  {patientName}
                </span>
                <span className="p--data">
                  {" "}
                  <span>56 (M) - 17/07/2022</span>{" "}
                  <img
                    src="images/heart.png"
                    width="16"
                    height="18"
                    alt="heart"
                  />
                </span>
              </div>
            )}
            {selectedView !== AppContext.view.Patient && (
              <div className="icon--notify">
                <img
                  src="/images/m3.svg"
                  width="20px"
                  height="20px"
                  alt="cross"
                  onClick={ShowHideTestSymbols}
                />
                <img
                  src="/images/m4.svg"
                  width="20px"
                  height="20px"
                  alt="cross"
                />
              </div>
            )}
            <img
              onClick={() => logOutUser()}
              src="/images/logout.png"
              width="20px"
              height="20px"
              alt="logout"
              className="logout"
            />
          </div>
        </div>
      )}
      <Modal
        className="Error--Modal"
        show={showErrorPopup}
        onHide={ShowHideErrorModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
          <a onClick={() => setshowErrorPopup(false)}>
            <img src="/images/cross.png" width="18" height="18" alt="cross" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="error-message">
            <input
              type="file"
              id="fileInput"
              onChange={(e) => EDFFileReader(e)}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Routes>
        <Route path="" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route
          path="patient"
          element={
            <RequireAuth>
              <Patient
                setPatientName={setPatientName}
                setSelectedView={setSelectedView}
                setDataSetForSelectedPatient={setDataSetForSelectedPatient}
                DownloadAndReadEDF={DownloadAndReadEDF}
              />
            </RequireAuth>
          }
        />
        <Route
          path="ecg"
          element={
            <RequireAuth>
              <ECG
                EDFBuffer={buffer}
                progressBarCoutDown={progressBarCoutDown}
                setProgressBarCoutDown={setProgressBarCoutDown}
                selectedView={selectedView}
                setSelectedView={setSelectedView}
                isEventClicked={isEventClicked}
                dataSetForSelectedPatient={dataSetForSelectedPatient}
                isLeftMenu={isLeftMenu}
                showTestSymbols={showTestSymbols}
              />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
}

export default App;
