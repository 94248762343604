import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../Context/Context";
type PatientProps = {
  setPatientName: Function;
  setSelectedView: Function;
  setDataSetForSelectedPatient: Function;
  DownloadAndReadEDF: any;
};
function Patient(props: PatientProps) {
  const navigate = useNavigate();

  useEffect(() => {
    props.setSelectedView(AppContext.view.Patient);
  }, []);

  function redirectToECG(patientName: string, PatientDatasetType: number) {
    props.setPatientName(patientName);
    props.setSelectedView(AppContext.view.Analysis);
    props.setDataSetForSelectedPatient(PatientDatasetType);
    navigate("/ecg");
    props.DownloadAndReadEDF('');
  }

  return (
    <>
      {/* <a
        title="Show/Hide Bookmarks Panel"
        className="btn--left__menu pbtn--left__menu "
      >
        <img src="/images/m1.svg" width="26px" height="18px" alt="cross" />
      </a> */}

      <div className="ecg--main patient--main">
        <div className="ecg--left custom--scroll">
          <a className="menu--link">
            <img
              src="/images/patient.png"
              width="32px"
              height="32px"
              alt="review"
            />
          </a>
          <a
            className="menu--link"
            onClick={() => redirectToECG("Arlene Wilson", 2)}
          >
            <img
              src="/images/reading.png"
              width="32px"
              height="32px"
              alt="review"
            />
          </a>
        </div>
        <div className="ecg--right ecg--right--home">
          <div className="patient-head">
            <h1>Patient List</h1>
            <span>Holter</span>
          </div>
          <div className="patient--list">
            <table className="patient--table">
              <thead>
                <tr>
                  <th>Priority</th>
                  <th>File Status</th>
                  <th>
                    Patient ID{" "}
                    <div className="th-sort">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className=" bg-blu-400"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                      </svg>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="bg-re-400 "
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                      </svg>
                    </div>
                  </th>
                  <th>
                    Patient Name{" "}
                    <div className="th-sort">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className=" bg-blu-400"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                      </svg>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="bg-re-400 "
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                      </svg>
                    </div>
                  </th>
                  <th>
                    Study ID{" "}
                    <div className="th-sort">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className=" bg-blu-400"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                      </svg>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="bg-re-400 "
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                      </svg>
                    </div>
                  </th>
                  <th>
                    Device ID{" "}
                    <div className="th-sort">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className=" bg-blu-400"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                      </svg>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="bg-re-400 "
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                      </svg>
                    </div>
                  </th>
                  <th>Study Type</th>
                  <th>
                    Study Start Date & Time{" "}
                    <div className="th-sort">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className=" bg-blu-400"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                      </svg>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="bg-re-400 "
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                      </svg>
                    </div>
                  </th>
                  <th>
                    Study Duration{" "}
                    <div className="th-sort">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className=" bg-blu-400"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                      </svg>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="bg-re-400 "
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                      </svg>
                    </div>
                  </th>
                  <th>Technician</th>
                  <th>Clinical Notes</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Clinic Name</th>
                  <th>Physician Name</th>
                  <th>Clinical Notes</th>
                  <th>Clinical Indication</th>
                </tr>
              </thead>
              <tbody>
                <tr onClick={() => redirectToECG("Arlene Wilson", 2)}>
                  <td>
                    <span className="dot-green"></span>
                  </td>
                  <td>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.50004 9.66671L12.4248 12.6103C12.8433 12.9241 13.4329 12.8625 13.7774 12.4688L21.3334 3.83337"
                        stroke="#222222"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                      <path
                        d="M22.5 12C22.5 14.1939 21.8128 16.3327 20.5349 18.116C19.257 19.8993 17.4526 21.2376 15.3751 21.9428C13.2976 22.648 11.0514 22.6847 8.95201 22.0479C6.85257 21.411 5.00536 20.1325 3.66979 18.392C2.33422 16.6515 1.5774 14.5363 1.50562 12.3436C1.43384 10.1508 2.0507 7.99068 3.26957 6.16651C4.48844 4.34235 6.24808 2.94579 8.30137 2.17299C10.3547 1.40018 12.5984 1.28995 14.7176 1.85778"
                        stroke="#222222"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </td>
                  <td>HIT2931</td>
                  <td>Arlene Wilson</td>
                  <td>64274-278</td>
                  <td>43829</td>
                  <td>Holter</td>
                  <td>11/12/2022</td>
                  <td>12 hours</td>
                  <td>TI</td>
                  <td>Report Normal</td>
                  <td>38</td>
                  <td>F</td>
                  <td>Cleveland Clinic</td>
                  <td>Dr.Park Ave</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr onClick={() => redirectToECG("Chris Johnson", 3)}>
                  <td>
                    <span className="dot-yellow"></span>
                  </td>
                  <td>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.50004 9.66671L12.4248 12.6103C12.8433 12.9241 13.4329 12.8625 13.7774 12.4688L21.3334 3.83337"
                        stroke="#222222"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                      <path
                        d="M22.5 12C22.5 14.1939 21.8128 16.3327 20.5349 18.116C19.257 19.8993 17.4526 21.2376 15.3751 21.9428C13.2976 22.648 11.0514 22.6847 8.95201 22.0479C6.85257 21.411 5.00536 20.1325 3.66979 18.392C2.33422 16.6515 1.5774 14.5363 1.50562 12.3436C1.43384 10.1508 2.0507 7.99068 3.26957 6.16651C4.48844 4.34235 6.24808 2.94579 8.30137 2.17299C10.3547 1.40018 12.5984 1.28995 14.7176 1.85778"
                        stroke="#222222"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </td>
                  <td>HIT3945</td>
                  <td>Chris Johnson</td>
                  <td>42859-284</td>
                  <td>28392</td>
                  <td>Holter</td>
                  <td>05/02/2023</td>
                  <td> 18 hours</td>
                  <td> TI</td>
                  <td>Report PVC</td>
                  <td>45</td>
                  <td>M</td>
                  <td>Health Rogh Clinic</td>
                  <td>Dr.Amanda Johnson</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
            <div className="pagination--ecg">
              <div className="pagination--left">
                <span className="arrow-left">&lsaquo;</span>
                <span className="page-number">1</span>
                <span className="arrow-right">&lsaquo;</span>
                <span className="entries">Showing 1 to 2 of 2 entries</span>
              </div>
              <div className="pagination--right">
                <span>Rows Per Page</span>
                <select>
                  <option>10</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Patient Page
      <br />
      <Link to={"/ecg"}>Go to ECG</Link> */}
    </>
  );
}

export default Patient;
