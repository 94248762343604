import { HiDocumentReport } from "react-icons/hi";
import { jsPDF } from "jspdf";
import React from "react";
import Box from "../../Box";
import Pdf from "react-to-pdf";

const ref = React.createRef();
export default function ReportLayout({ children }) {
  // function SavePdf() {
  //   var doc = new jsPDF('p', 'pt', 'a4')
  //   console.log('onClick is working')
  //   doc.html(document.querySelector('#pdfImg'), {
  //     callback: function (pdf) {
  //       pdf.save('new3.pdf')
  //     },
  //   })
  // }

  return (
    <Box width="w-full" pb="0" pt="0" px="0" className="mt-3 mb-8  ">
      <div className="flex items-center justify-between border-b h-14 px-10 ">
        <h2 className="    flex  items-center text-xl font-bold   ">Reports</h2>
        {/* <GenerateReportBtn onClick={SavePdf} /> */}
        <GenerateReportBtn />
      </div>
      <div
        ref={ref}
        className=" px-12 2xl:px-16 pt-24 pb-28 text-[#231F20]"
        id="pdfImg"
      >
        {/* {children} */}
        <div className="report-images">
          <img src="/Arlene/1.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/2.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/3.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/4.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/5.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/6.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/7.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/8.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/9.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/10.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/11.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/12.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/13.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/14.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/15.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/16.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/17.jpg" alt="1" width="100" height="100" />
          <img src="/Arlene/18.jpg" alt="1" width="100" height="100" />
        </div>
      </div>
    </Box>
  );
}
function GenerateReportBtn({ ...props }) {
  const options = {
    // unit: 'in',
    // format: [4, 2],
    // scale: 2,
  };
  return (
    <>
      {/* <Pdf
        scale={0.4}
        options={options}
        targetRef={ref}
        filename="Report-file.pdf"
      >
        {({ toPdf }) => (
          <button
            {...props}
            targetRef={ref}
            onClick={toPdf}
            className="h-11 w-[223px] flex items-center justify-center rounded-lg bg-[#27962D] shadow-[0px_20px_40px_rgba(153,153,153,0.25)] gap-2 text-white font-bold"
          >
            <HiDocumentReport className="text-2xl" />
            <span>Generate Report</span>
          </button>
        )}
      </Pdf> */}

      <a
        href="/64274-278.pdf"
        download="/64274-278.pdf"
        className="h-11 w-[223px] flex items-center justify-center rounded-lg bg-[#27962D] shadow-[0px_20px_40px_rgba(153,153,153,0.25)] gap-2 text-white font-bold"
      >
        <HiDocumentReport className="text-2xl" />
        <span>Generate Report</span>
      </a>
    </>
  );
}
