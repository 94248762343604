import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";

type SplitViewProps = {
  splitViewShowHide: boolean;
  mmsValueSplitView: any;
  mmvValueSplitView: any;
  globalW: any;
  startSignal: any;
  IndexedDataSet: any;
  startOfSplitBoxRef: any;
  secondsInSplit: any;
  h: any;
  showRuler: any;
  mmvValue: any;
  invertGraph: any;
  AllAnnotations: any;
  startOfSplitBox: any;
  setmmsValueSplitView: Function;
  setmmvValueSplitView: Function;
  setintervalSplitView: Function;
  timeDifferenceFixedY: Function;
  getTimeFromXandY: Function;
  getBPMAndBeats: Function;
  PushMeasurementInArray: Function;
  GraphStartTimeRef: any;
  setTagStartX: Function;
  setTagStartY: Function;
  setTagEndX: Function;
  setTagEndY: Function;
  setShowRhythmPopup: Function;
  getNearestPeak: Function;
  SelectedDataSetAll: any;
};

function SplitView(props: SplitViewProps) {
  let svgLeft = 275;
  let svgTop = 465;

  function getSVGx(x: any) {
    return x - svgLeft;
  }

  function getSVGy(y: any) {
    return y - svgTop;
  }

  const MouseDown = (e: any) => {
    if (currentControlRefSplitView.current === CurrentTool.Caliper) {
      handleHorizontalCaliper(true, getSVGx(e.pageX), getSVGy(e.pageY), false);
    } else if (currentControlRefSplitView.current === CurrentTool.TagTool) {
      mouseDownOnRhythm(getSVGx(e.pageX), getSVGy(e.pageY));
    } else if (
      currentControlRefSplitView.current === CurrentTool.AnnotationTool
    ) {
      // First convert the x/y into time based on y in first row AND add the start time
      let timeInSeconds =
        props.timeDifferenceFixedY(0, 0, getSVGx(e.pageX), getSVGy(e.pageY)) +
        props.GraphStartTimeRef.current;
      // let peakIndex: any = getNearestPeak(timeInSeconds);
      // setSelectedPeakIndex({ i: peakIndex.i, j: peakIndex.j });
    }
  };

  const MouseUp = (e: any) => {
    console.log("MouseUp");
    dragStarted = false;
    if (currentControlRefSplitView.current === CurrentTool.Caliper) {
      handleHorizontalCaliper(false, getSVGx(e.pageX), getSVGy(e.pageY), true);
    }

    // if (BottomDiv.ECGSplitView) {
    //   setSplitBoxDragStarted(false);
    //   setClickPixelsFromLeftOfBox(0);
    // }
  };

  const MouseMove = (e: any) => {
    // if (BottomDiv.ECGSplitView) {
    //   if (splitBoxDragStartedRef.current) {
    //     // Since Drag has started, we need to move the box
    //     let mouseX = getSVGx(e.pageX);
    //     let mouseY = getSVGy(e.pageY);
    //     let newXOfStartOfBox = mouseX - clickPixelsFromLeftOfBoxRef.current;
    //     if (newXOfStartOfBox < 0) {
    //       newXOfStartOfBox = 0;
    //     }
    //     let zone = Math.floor(mouseY / heightOfRow);
    //     let startOfThisZone = GraphStartTimeRef.current + zone * interval;
    //     let diffOfBoxFromLeftInSeconds =
    //       (newXOfStartOfBox / globalW) * interval;
    //     let newStartOfSplitBox = diffOfBoxFromLeftInSeconds + startOfThisZone;
    //     setStartOfSplitBox(newStartOfSplitBox);

    //   }
    // } else {
    if (currentControlRefSplitView.current === CurrentTool.Caliper) {
      handleHorizontalCaliper(false, getSVGx(e.pageX), getSVGy(e.pageY), false);
    } else if (
      currentControlRefSplitView.current === CurrentTool.AnnotationTool
    ) {
      handleAnnotationToolEvent(2, getSVGx(e.pageX), getSVGy(e.pageY));
    }
    // }
  };

  const MouseOut = (e: any) => {
    // console.log('MouseOut. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    /*   if(currentControlRefSplitView.current === 4) {
        console.log('mouse out');
       handleAnnotationToolEvent(4, getSVGx(e.pageX), getSVGy(e.pageY));
     }*/
    /* if(BottomDiv.ECGSplitView) {
      setSplitBoxDragStarted(false);
    }*/
  };

  const svgSplitViewRef = useRef<any>();
  let svgSplitView = d3
    .select(svgSplitViewRef.current)
    .on("mousedown", MouseDown)
    .on("mouseup", MouseUp)
    .on("mousemove", MouseMove)
    .on("mouseout", MouseOut);

  let svgSplitViewLeft = 0;
  let svgSplitViewTop = 0;

  //Split view const/states
  const [showGridSplitView, setshowGridSplitView] = useState(1);
  const [showRulerSplitView, setshowRulerSplitView] = useState(0);
  const [invertGraphSplitView, setinvertGraphSplitView] = useState(0);
  const [currentControlSplitView, setcurrentControlSplitView] = useState(1);

  const currentControlRefSplitView = useRef<any | null>(null);
  currentControlRefSplitView.current = currentControlSplitView;

  const [DropDownSplitView, setDropDownSplitView] = useState({
    MV: false,
    MS: false,
  });

  const CurrentTool = {
    Pointer: 1,
    Caliper: 2,
    TagTool: 3,
    AnnotationTool: 4,
    SplitBox: 5,
  };

  const [SelectedmmsValueSplitView, setSelectedmmsValueSplitView] =
    useState("15mm/s");
  const [SelectedmmvValueSplitView, setmmvSelectedmmvValueSplitView] =
    useState("10mm/mV");

  // Split view variables
  let xScaleSplitView = d3.scaleLinear().domain([0, 0]).range([0, 0]);
  let yScaleSplitView = d3.scaleLinear().domain([0, 0]).range([0, 0]);

  function EcgGraphClickSplitView() {
    setcurrentControlSplitView(1);
  }

  function HorizontalCaliperToolSplitView() {
    if (currentControlSplitView !== CurrentTool.Caliper) {
      setcurrentControlSplitView(CurrentTool.Caliper);
    } else {
      setcurrentControlSplitView(1);
    }
  }

  function TagToolSplitView() {
    if (currentControlSplitView !== CurrentTool.TagTool) {
      setcurrentControlSplitView(CurrentTool.TagTool);
    } else {
      setcurrentControlSplitView(1);
    }
  }

  function BeatAnnotationToolSplitView() {
    if (currentControlSplitView !== CurrentTool.AnnotationTool) {
      setcurrentControlSplitView(CurrentTool.AnnotationTool);
    } else {
      setcurrentControlSplitView(1);
    }
  }

  function EcgInvertSplitView() {
    if (invertGraphSplitView === 1) {
      setinvertGraphSplitView(0);
    } else {
      setinvertGraphSplitView(1);
    }
  }

  function ShowHideGridSplitView() {
    if (showGridSplitView === 1) {
      setshowGridSplitView(0);
    } else {
      setshowGridSplitView(1);
    }
  }

  function ShowHideRulerSplitView() {
    if (showRulerSplitView === 1) {
      setshowRulerSplitView(0);
    } else {
      setshowRulerSplitView(1);
    }
  }

  function ChangeAmplitudeSplitView() {
    ShowHideDropDownSplitView(false, false);
  }

  function ChangeSpeedSplitView() {
    ShowHideDropDownSplitView(false, false);
  }

  function ShowHideDropDownSplitView(p_boolMV: any, p_boolMS: any) {
    setDropDownSplitView({
      MV: p_boolMV,
      MS: p_boolMS,
    });
  }

  function getBeatBeforeFromIndexedSet(Seconds: any) {
    for (let i = 0; i < props.IndexedDataSet.length; i++) {
      for (let j = 0; j < props.IndexedDataSet[i].length; j++) {
        if (props.IndexedDataSet[i][j][0] >= Seconds) {
          if (i === 0 && j === 0) {
            return { i: 0, j: 0 };
          } else {
            if (j > 0) {
              return { i: i, j: j - 1 };
            } else {
              return { i: i - 1, j: j };
            }
          }
        }
      }
    }
    return { i: -1, j: -1 };
  }

  // This is for drawing the SplitView
  useEffect(() => {
    if (props.splitViewShowHide === true) {
      var BottomDivWidth =
        document.getElementById("MainBottomDiv")?.clientWidth;
      let w = props.globalW;
      let heighOfSplitRow = 200;
      if (BottomDivWidth) {
        w = BottomDivWidth;
      }

      let localIntervalSplitView = w / (props.mmsValueSplitView * 5);
      props.setintervalSplitView(localIntervalSplitView);

      svgSplitView = d3
        .select(svgSplitViewRef.current)
        .attr("width", props.globalW)
        .attr("height", heighOfSplitRow)
        .style("overflow", "hidden");

      // let boundingRect: any = document
      //   .getElementById("splitViewSVG")
      //   ?.getBoundingClientRect();
      //   svgLeft = boundingRect.left;
      //   svgTop = boundingRect.top;

      // setting the scaling
      xScaleSplitView = d3
        .scaleLinear()
        .domain([
          props.startOfSplitBoxRef.current,
          props.startOfSplitBoxRef.current + props.secondsInSplit,
        ])
        .range([0, w]);

      let localYRange = 30000 / props.mmvValueSplitView;

      yScaleSplitView = d3
        .scaleLinear()
        .domain([-localYRange, localYRange])
        .range([heighOfSplitRow, 0]);

      d3.selectAll(".splitViewLine").remove();

      if (
        props.IndexedDataSet &&
        props.IndexedDataSet.length > 0 &&
        props.startSignal === 1
      ) {
        d3.selectAll(".splitViewGrid").remove();

        if (showGridSplitView === 1) {
          // Define the pattern for the image
          const pattern = svgSplitView
            .append("defs")
            .append("pattern")
            .attr("class", "splitViewGrid")
            .attr("id", "image-pattern")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", heighOfSplitRow * 2)
            .attr("height", heighOfSplitRow)
            .attr("patternUnits", "userSpaceOnUse")
            .append("image")
            .attr("xlink:href", "/images/Grid3.png")
            .attr("width", heighOfSplitRow * 2)
            .attr("height", heighOfSplitRow);

          // Append a rectangle element with the image pattern
          svgSplitView
            .append("rect")
            .attr("class", "splitViewGrid")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", w)
            .attr("height", props.h)
            .attr("fill", "url(#image-pattern)");
        }

        let startOfSplit = getBeatBeforeFromIndexedSet(
          props.startOfSplitBoxRef.current
        );
        let endOfSplit = getBeatBeforeFromIndexedSet(
          props.startOfSplitBoxRef.current + props.secondsInSplit
        );

        let prevI = -1;
        let prevJ = -1;
        for (let i = startOfSplit.i; i <= endOfSplit.i; i++) {
          for (let j = startOfSplit.j; j <= endOfSplit.j; j++) {
            if (prevI >= 0 && prevJ >= 0) {
              svgSplitView
                .append("line")
                .attr("class", "splitViewLine")
                .style("stroke", "green")
                .attr(
                  "x1",
                  xScaleSplitView(props.IndexedDataSet[prevI][prevJ][0])
                )
                .attr(
                  "y1",
                  yScaleSplitView(
                    invertGraphSplitView === 0
                      ? props.IndexedDataSet[prevI][prevJ][1]
                      : -props.IndexedDataSet[prevI][prevJ][1]
                  )
                )
                .attr("x2", xScaleSplitView(props.IndexedDataSet[i][j][0]))
                .attr(
                  "y2",
                  yScaleSplitView(
                    invertGraphSplitView === 0
                      ? props.IndexedDataSet[i][j][1]
                      : -props.IndexedDataSet[i][j][1]
                  )
                );
            }
            prevI = i;
            prevJ = j;
          }
        }

        // Mark the Peaks
        let startPeakPosition = props.getNearestPeak(
          props.startOfSplitBoxRef.current,
          1
        );
        let endPeakPosition = props.getNearestPeak(
          props.startOfSplitBoxRef.current + props.secondsInSplit,
          2
        );

        d3.selectAll(".classificationSplitText").remove();
        for (let i = startPeakPosition.i; i <= endPeakPosition.i; i++) {
          for (let j = 0; j < props.SelectedDataSetAll[i].peaks.length; j++) {
            var isIncluded = true;
            if (i === startPeakPosition.i && j < startPeakPosition.j) {
              isIncluded = false;
            }
            if (i === endPeakPosition.i && j > endPeakPosition.j) {
              isIncluded = false;
            }
            if (isIncluded) {
              svgSplitView
                .append("text")
                .attr("class", "classificationSplitText")
                .text(props.SelectedDataSetAll[i].peaks[j].classification)
                .attr("fill", "#666666")
                .attr("font-size", "12px")
                .attr(
                  "x",
                  xScaleSplitView(
                    props.SelectedDataSetAll[i].peaks[j].value[0]
                  ) - 4
                )
                .attr("y", 15);
            }
          }
        }
      }
    }
  }, [
    props.IndexedDataSet,
    showGridSplitView,
    props.showRuler,
    props.mmvValue,
    props.invertGraph,
    props.AllAnnotations,
    props.startOfSplitBox,
    props.splitViewShowHide,
    invertGraphSplitView,
  ]);

  useEffect(() => {
    // document.addEventListener("keydown", detectKeyDown, true);
    // window.addEventListener("resize", screenResized);
    window.addEventListener("mouseup", handleWindowMouseUp);
  }, []);

  const handleWindowMouseUp = (e: any) => {
    dragStarted = false;
    // setSplitBoxDragStarted(false);
    // setScrollDragStarted(false);
  };

  let dragStarted = false;
  let dragStartX = 0;
  let dragStartY = 0;
  const heightOfRow = 200;

  function getZoneFromY(y: any) {
    return Math.floor(y / heightOfRow);
  }

  function handleHorizontalCaliper(start: any, x: any, y: any, finish: any) {
    if (start) {
      dragStarted = true;
      dragStartX = x;
      let zone = getZoneFromY(y);
      dragStartY = heightOfRow / 2; //+ zone * heightOfRow;
    } else if (dragStarted || finish) {
      //Start of horizontal Caliper
      let caliperEndX = x;
      let endZone = getZoneFromY(y);
      let caliperEndY = heightOfRow / 2; //+ endZone * heightOfRow;

      // Checking if drag is forward or back
      let dragForward = true;

      if (caliperEndY === dragStartY) {
        // If we are on the same row, check X
        if (caliperEndX < dragStartX) {
          dragForward = false;
        }
      } else if (caliperEndY < dragStartY) {
        dragForward = false;
      }

      let caliperStartX = dragStartX;
      let caliperStartY = dragStartY;

      if (!dragForward) {
        // Flip the start and the end to simplify things
        caliperStartX = caliperEndX;
        caliperStartY = caliperEndY;
        caliperEndX = dragStartX;
        caliperEndY = dragStartY;
      }
      //      drawRythmBar("ABC", caliperStartX, caliperStartY - heightOfRow/2, caliperEndX, caliperEndY - heightOfRow/2);
      let timeDifference = props.timeDifferenceFixedY(
        caliperStartX,
        caliperStartY,
        caliperEndX,
        caliperEndY
      );

      let caliperStartTime = props.getTimeFromXandY(
        caliperStartX,
        caliperStartY
      );
      let caliperEndTime = props.getTimeFromXandY(caliperEndX, caliperEndY);

      let beatAndBPM: any = props.getBPMAndBeats(
        caliperStartTime,
        caliperEndTime
      );
      // To get Time from start of day - use
      //      let secondsFromStartOfDay = timeDifferenceFixedY(0, 0, startX, startY);

      let roundedBPM = Math.round(beatAndBPM.BPM);

      if (finish) {
        props.PushMeasurementInArray(
          parseFloat(caliperStartTime.toFixed(2)),
          parseFloat(timeDifference.toFixed(2)),
          beatAndBPM.Beats,
          roundedBPM
        );
        d3.selectAll(".currentCaliperSplitView").remove();
      } else {
        d3.selectAll(".currentCaliperSplitView").remove();

        drawHorizontalCaliper(
          caliperStartX,
          caliperStartY,
          caliperEndX,
          caliperEndY,
          parseFloat(timeDifference.toFixed(2)),
          roundedBPM,
          "currentCaliperSplitView"
        );
      }
    }
  }

  function drawHorizontalCaliper(
    caliperStartX: any,
    caliperStartY: any,
    caliperEndX: any,
    caliperEndY: any,
    duration: any,
    bpm: any,
    className: any
  ) {
    let timeDisplayText = duration + " s";
    let fulldisplayText = timeDisplayText + ", " + bpm + " bpm";

    // Create a group for the caliper lines
    let caliperGroup = svgSplitView.append("g").attr("class", className);

    // Draw the Start Bar
    caliperGroup
      .append("line")
      .attr("class", className)
      .style("stroke", "blue")
      .style("stroke-width", 2)
      .attr("x1", caliperStartX)
      .attr("y1", caliperStartY - 15)
      .attr("x2", caliperStartX)
      .attr("y2", caliperStartY + 15);

    // Drwa the End Bar
    caliperGroup
      .append("line")
      .attr("class", className)
      .style("stroke", "blue")
      .style("stroke-width", 2)
      .attr("x1", caliperEndX)
      .attr("y1", caliperEndY - 15)
      .attr("x2", caliperEndX)
      .attr("y2", caliperEndY + 15);

    if (caliperStartY === caliperEndY) {
      // If drag is in the same row, draw a line
      caliperGroup
        .append("line")
        .attr("class", className)
        .style("stroke", "blue")
        .style("stroke-width", 2)
        .attr("x1", caliperStartX)
        .attr("y1", caliperStartY)
        .attr("x2", caliperEndX)
        .attr("y2", caliperEndY);

      var caliperText = caliperGroup
        .append("text")
        .attr("class", className)
        .text(fulldisplayText)
        .attr("fill", "blue")
        .attr("font-size", "12px")
        .attr("x", -1000)
        .attr("y", 0);

      var textWidth = caliperText.node().getComputedTextLength();

      caliperText
        .attr("x", (caliperStartX + caliperEndX - textWidth) / 2)
        .attr("y", caliperStartY - 5);
    } else {
      // Since the drag is to another line, draw a line to the end of this row
      // First draw a line from start to end of row
      caliperGroup
        .append("line")
        .attr("class", className)
        .style("stroke", "blue")
        .style("stroke-width", 2)
        .attr("x1", caliperStartX)
        .attr("y1", caliperStartY)
        .attr("x2", props.globalW)
        .attr("y2", caliperStartY);

      var caliperTextFirst = caliperGroup
        .append("text")
        .attr("class", className)
        .text(fulldisplayText)
        .attr("fill", "blue")
        .attr("font-size", "12px")
        .attr("x", -1000)
        .attr("y", 0);

      var textWidthFirst = caliperTextFirst.node().getComputedTextLength();

      caliperTextFirst
        .attr("x", (caliperStartX + props.globalW - textWidthFirst) / 2)
        .attr("y", caliperStartY - 5);

      // First draw a line from start of the last row to the end of the caliper
      caliperGroup
        .append("line")
        .attr("class", className)
        .style("stroke", "blue")
        .style("stroke-width", 2)
        .attr("x1", 0)
        .attr("y1", caliperEndY)
        .attr("x2", caliperEndX)
        .attr("y2", caliperEndY);

      var caliperTextLast = caliperGroup
        .append("text")
        .attr("class", className)
        .text(fulldisplayText)
        .attr("fill", "blue")
        .attr("font-size", "12px")
        .attr("x", -1000)
        .attr("y", 0);

      var textWidthLast = caliperTextLast.node().getComputedTextLength();

      caliperTextLast
        .attr("x", (0 + caliperEndX - textWidthLast) / 2)
        .attr("y", caliperEndY - 5);

      // Check if there are rows between the two
      if (caliperEndY - caliperStartY > heightOfRow) {
        let numberOfRowsBetween =
          (caliperEndY - caliperStartY) / heightOfRow - 1;
        for (let i = 0; i < numberOfRowsBetween; i++) {
          caliperGroup
            .append("line")
            .attr("class", className)
            .style("stroke", "blue")
            .style("stroke-width", 2)
            .attr("x1", 0)
            .attr("y1", caliperStartY + (i + 1) * heightOfRow)
            .attr("x2", props.globalW)
            .attr("y2", caliperStartY + (i + 1) * heightOfRow);

          var caliperTextMiddle = caliperGroup
            .append("text")
            .attr("class", className)
            .text(fulldisplayText)
            .attr("fill", "blue")
            .attr("font-size", "12px")
            .attr("x", -1000)
            .attr("y", 0);

          var textWidthMiddle = caliperTextMiddle
            .node()
            .getComputedTextLength();

          caliperTextMiddle
            .attr("x", (0 + props.globalW - textWidthMiddle) / 2)
            .attr("y", caliperStartY + (i + 1) * heightOfRow - 5);
        }
      }
    }
  }

  function handleAnnotationToolEvent(typeOfEvent: any, x: any, y: any) {
    d3.selectAll(".annotationToolsSplitView").remove();
    // Mouse Move Event - type 2
    if (typeOfEvent === 2) {
      // Clear previous circle and draw new one
      svgSplitView
        .append("circle")
        .attr("class", "annotationToolsSplitView")
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 50)
        .attr("stroke", "rgba(250, 160, 160,0.8)")
        .attr("fill", "rgba(250, 160, 160,0.4)");
    } else if (typeOfEvent === 4) {
      // alert('hello');
    }
  }

  let rythmStarted = false;
  let rythmStartX = 0;
  let rythmStartY = 0;
  let rythmEndX = 0;
  let rythmEndY = 0;

  function mouseDownOnRhythm(x: any, y: any) {
    d3.selectAll(".rythmMarkersSplitView").remove();

    let arrowBaseFromMainLine = 10;
    let arrowTipFromMainLine = 30;
    let pixelsFromTop = 0.15 * heightOfRow;
    let pixelsFromBottom = heightOfRow - pixelsFromTop;

    //grey arrow head
    svgSplitView
      .append("defs")
      .append("marker")
      .attr("class", "rythmMarkersSplitView")
      .attr("id", "greyarrowhead")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 6)
      .attr("refY", 0)
      .attr("markerWidth", 4)
      .attr("markerHeight", 4)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-4L12,0L0,4")
      .attr("fill", "grey");

    if (!rythmStarted) {
      // Start a new record
      rythmStarted = true;
      rythmStartX = x;
      let zone = getZoneFromY(y);
      rythmStartY = zone * heightOfRow;

      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .style("stroke", "grey")
        .style("stroke-width", 4)
        .attr("x1", rythmStartX)
        .attr("y1", rythmStartY)
        .attr("x2", rythmStartX)
        .attr("y2", rythmStartY + heightOfRow);

      //top left arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmStartX - arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromTop)
        .attr("x2", rythmStartX - arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // bottom left arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmStartX - arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromBottom)
        .attr("x2", rythmStartX - arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      //top right arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromTop)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      //bottom right arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromBottom)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // Define the marker for the grey arrow head ends
    } else {
      // Start a new record
      rythmStarted = false;

      rythmEndX = x;
      let zone = getZoneFromY(y);
      rythmEndY = zone * heightOfRow;

      // Checking if drag is forward or back
      let tagForward = true;

      if (rythmEndY === rythmStartY) {
        // If we are on the same row, check X
        if (rythmEndX < rythmStartX) {
          tagForward = false;
        }
      } else if (rythmEndY < rythmStartY) {
        tagForward = false;
      }

      if (!tagForward) {
        // Flip the start and the end to simplify things
        let tempX = rythmStartX;
        let tempY = rythmStartY;
        rythmStartX = rythmEndX;
        rythmStartY = rythmEndY;
        rythmEndX = tempX;
        rythmEndY = tempY;
      }

      // Make the Start Line
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .style("stroke", "grey")
        .style("stroke-width", 4)
        .attr("x1", rythmStartX)
        .attr("y1", rythmStartY)
        .attr("x2", rythmStartX)
        .attr("y2", rythmStartY + heightOfRow);

      //top right arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromTop)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      //bottom right arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromBottom)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // Make the End Line
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .style("stroke", "grey")
        .style("stroke-width", 4)
        .attr("x1", rythmEndX)
        .attr("y1", rythmEndY)
        .attr("x2", rythmEndX)
        .attr("y2", rythmEndY + heightOfRow);

      //top left arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmEndX - arrowBaseFromMainLine)
        .attr("y1", rythmEndY + pixelsFromTop)
        .attr("x2", rythmEndX - arrowTipFromMainLine)
        .attr("y2", rythmEndY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // bottom left arrow
      svgSplitView
        .append("line")
        .attr("class", "rythmMarkersSplitView")
        .attr("x1", rythmEndX - arrowBaseFromMainLine)
        .attr("y1", rythmEndY + pixelsFromBottom)
        .attr("x2", rythmEndX - arrowTipFromMainLine)
        .attr("y2", rythmEndY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      props.setTagStartX(rythmStartX);
      props.setTagStartY(rythmStartY);
      props.setTagEndX(rythmEndX);
      props.setTagEndY(rythmEndY);
      // Open the popup to get the rest of the input
      props.setShowRhythmPopup(true);
    }
  }

  function drawRythmBar(
    typeText: any,
    startX: any,
    startY: any,
    endX: any,
    endY: any
  ) {
    let barHeight = 13;
    let fontSize = "11px";
    let fontPosition = 11;

    // Draw the starting Line
    svgSplitView
      .append("line")
      .attr("class", "rythmBarSplitView")
      .style("stroke", "red")
      .style("stroke-width", 2)
      .attr("x1", startX)
      .attr("y1", startY)
      .attr("x2", startX)
      .attr("y2", startY + barHeight);

    // Draw the ending Line
    svgSplitView
      .append("line")
      .attr("class", "rythmBarSplitView")
      .style("stroke", "red")
      .style("stroke-width", 2)
      .attr("x1", endX)
      .attr("y1", endY)
      .attr("x2", endX)
      .attr("y2", endY + barHeight);

    if (startY === endY) {
      // Since it is just one row - draw the shaded area and write the text in there
      svgSplitView
        .append("rect")
        .attr("class", "rythmBarSplitView")
        .attr("x", startX)
        .attr("y", startY)
        .attr("width", endX - startX)
        .attr("height", barHeight)
        .attr("fill", "rgba(250, 160, 160,0.4)");

      svgSplitView
        .append("text")
        .attr("class", "rythmBarSplitView")
        .text(typeText)
        .attr("fill", "red")
        .attr("font-size", fontSize)
        .attr("x", startX + (endX - startX) / 2 - 10)
        .attr("y", startY + fontPosition);
    } else {
      // As it is multiple rows, start with making the bar and writing the text for the first and last row
      svgSplitView
        .append("rect")
        .attr("class", "rythmBarSplitView")
        .attr("x", startX)
        .attr("y", startY)
        .attr("width", props.globalW - startX)
        .attr("height", barHeight)
        .attr("fill", "rgba(250, 160, 160,0.4)");

      svgSplitView
        .append("text")
        .attr("class", "rythmBarSplitView")
        .text(typeText)
        .attr("fill", "red")
        .attr("font-size", fontSize)
        .attr("x", startX + (props.globalW - startX) / 2 - 10)
        .attr("y", startY + fontPosition);

      // last row
      svgSplitView
        .append("rect")
        .attr("class", "rythmBarSplitView")
        .attr("x", 0)
        .attr("y", endY)
        .attr("width", endX)
        .attr("height", barHeight)
        .attr("fill", "rgba(250, 160, 160,0.4)");

      svgSplitView
        .append("text")
        .attr("class", "rythmBarSplitView")
        .text(typeText)
        .attr("fill", "red")
        .attr("font-size", fontSize)
        .attr("x", endX / 2 - 10)
        .attr("y", endY + fontPosition);

      // Check if there are rows between the two
      if (endY - startY > heightOfRow) {
        let numberOfRowsBetween = (endY - startY) / heightOfRow - 1;
        for (let i = 0; i < numberOfRowsBetween; i++) {
          svgSplitView
            .append("rect")
            .attr("class", "rythmBarSplitView")
            .attr("x", 0)
            .attr("y", startY + (i + 1) * heightOfRow)
            .attr("width", props.globalW)
            .attr("height", barHeight)
            .attr("fill", "rgba(250, 160, 160,0.4)");

          svgSplitView
            .append("text")
            .attr("class", "rythmBarSplitView")
            .text(typeText)
            .attr("fill", "red")
            .attr("font-size", fontSize)
            .attr("x", props.globalW / 2 - 10)
            .attr("y", startY + (i + 1) * heightOfRow + fontPosition);
        }
      }
    }
  }

  return (
    <div className="ecg--right__bottom__pop3">
      <div className="ecg--right__top__controls ecg--back">
        <div className="controls--pt1">
          <a
            onClick={() => EcgGraphClickSplitView()}
            className={`${
              currentControlSplitView === 1 ? "control--open" : ""
            }`}
          >
            <img src="/images/e1.svg" width="20px" height="20px" alt="cross" />
          </a>
          <a
            onClick={() => HorizontalCaliperToolSplitView()}
            className={`${
              currentControlSplitView === 2 ? "control--open" : ""
            }`}
          >
            <img src="/images/e2.svg" width="20px" height="20px" alt="cross" />
          </a>
          <a
            className={`${
              currentControlSplitView === 3 ? "control--open" : ""
            }`}
            onClick={() => {
              TagToolSplitView();
              //setShowRhythmPopup(true);
            }}
          >
            <img src="/images/e3.svg" width="20px" height="20px" alt="cross" />
          </a>
          <a
            className={`${
              currentControlSplitView === 4 ? "control--open" : ""
            }`}
            onClick={() => BeatAnnotationToolSplitView()}
          >
            <img src="/images/e4.svg" width="20px" height="20px" alt="cross" />
          </a>
          {/* <a
            onClick={() => EcgInvertSplitView()}
            className={`${invertGraphSplitView === 1 ? "control--open" : ""}`}
          >
            <img src="/images/e5.svg" width="20px" height="20px" alt="cross" />
          </a>
          <a
            onClick={() => ShowHideGridSplitView()}
            className={`${showGridSplitView === 1 ? "control--open" : ""}`}
          >
            <img src="/images/e6.svg" width="20px" height="20px" alt="cross" />
          </a>
          <a
            onClick={() => ShowHideRulerSplitView()}
            className={`${showRulerSplitView === 1 ? "control--open" : ""}`}
          >
            <img src="/images/e7.svg" width="20px" height="20px" alt="cross" />
          </a> */}
          <hr />
        </div>
        <div className="controls--pt2">
          <div className="controls--pt2__ddl">
            <a
              onClick={() =>
                ShowHideDropDownSplitView(!DropDownSplitView.MV, false)
              }
            >
              <span>{SelectedmmvValueSplitView}</span>
              <img
                src="/images/e8.png"
                width="20px"
                height="20px"
                alt="cross"
              />
            </a>
            {DropDownSplitView.MV && (
              <div className="controls--pt2__ddl__drop custom--scroll">
                <a
                  onClick={() => {
                    props.setmmvValueSplitView(10);
                    setmmvSelectedmmvValueSplitView("10mm/mV");
                    ChangeAmplitudeSplitView();
                  }}
                >
                  10mm/mV
                </a>
                <a
                  onClick={() => {
                    props.setmmvValueSplitView(15);
                    setmmvSelectedmmvValueSplitView("15mm/mV");
                    ChangeAmplitudeSplitView();
                  }}
                >
                  15mm/mV
                </a>
                <a
                  onClick={() => {
                    props.setmmvValueSplitView(20);
                    setmmvSelectedmmvValueSplitView("20mm/mV");
                    ChangeAmplitudeSplitView();
                  }}
                >
                  20mm/mV
                </a>
              </div>
            )}
          </div>
          <div className="controls--pt2__ddl">
            <a
              onClick={() =>
                ShowHideDropDownSplitView(false, !DropDownSplitView.MS)
              }
            >
              <span>{SelectedmmsValueSplitView}</span>
              <img
                src="/images/e8.png"
                width="20px"
                height="20px"
                alt="cross"
              />
            </a>
            {DropDownSplitView.MS && (
              <div
                className="controls--pt2__ddl__drop custom--scroll"
                onClick={() => ShowHideDropDownSplitView(false, false)}
              >
                <a
                  onClick={() => {
                    props.setmmsValueSplitView(15);
                    setSelectedmmsValueSplitView("15mm/s");
                    ChangeSpeedSplitView();
                  }}
                >
                  15mm/s
                </a>
                <a
                  onClick={() => {
                    props.setmmsValueSplitView(20);
                    setSelectedmmsValueSplitView("20mm/s");
                    ChangeSpeedSplitView();
                  }}
                >
                  20mm/s
                </a>
                <a
                  onClick={() => {
                    props.setmmsValueSplitView(25);
                    setSelectedmmsValueSplitView("25mm/s");
                    ChangeSpeedSplitView();
                  }}
                >
                  25mm/s
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="ecg--split__view">
        <svg id="splitViewSVG" ref={svgSplitViewRef}></svg>
      </div>
    </div>
  );
}

export default SplitView;
