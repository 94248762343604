import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import moment from "moment";
// This one is not being used - can be used for other format data
import JsonTestData from "../../TestData/testdata2.json";
// This next one is the one which has been used for all the testing - Use for Arlene
import JsonTestData2 from "../../TestData/testdata2.json";
// This is the dataset to use for Chris
import JsonTestData3 from "../../TestData/testdata3.json";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Annotation } from "../Model/Annotation";
import * as Utility from "../Shared/Utility";
import Templates from "../ECG/Templates/Templates";
import HRTrendView from "../ECG/HRTrendView";
import HRTrendBarsView from "../ECG/HRTrendBarsView";
import BPMGraph from "../ECG/BPMGraph";
import LeftMenu from "../ECG/LeftMenu/LeftMenu";
import LeftMenuAnnotationsView from "../ECG/LeftMenu/LeftMenuAnnotationsView";
import SplitView from "../ECG/SplitView";
import { EdfDecoder } from "../../EDF/EdfDecoder";
import StripsPanel from "../ECG/StripsPanel/StripsPanel";
import InputMask from "react-input-mask";
import { JsxFlags } from "typescript";
import { ProgressBar } from "react-bootstrap";
import AppContext from "../Context/Context";
import ProgressBarAnimation from "../ProgressBar/ProgressBar";
import Events from "../ECG/Events/Events";
import HREventChart from "../ECG/Events/HREventChart";
import GridSelection from "../GridSelection/GridSelection";
import HistogramPage from "../PatientReportPage/HistogramPage";
import SummaryPage from "../PatientReportPage/SummaryPage";
import NotificationCriteriaPage from "../PatientReportPage/NotificationCriteriaPage";
import PatientDemographicsPage from "../PatientReportPage/PatientDemographicsPage";
import ReportsPage from "../PatientReportPage/ReportsPage";
import PatientDemographicsPage2 from "../PatientReportPage/PatientDemographicsPage2";
import HistogramPage2 from "../PatientReportPage/HistogramPage2";
import ReportsPage2 from "../PatientReportPage/ReportsPage2";
import NotificationCriteriaPage2 from "../PatientReportPage/NotificationCriteriaPage2";
import SummaryPage2 from "../PatientReportPage/SummaryPage2";
import { detectPeaksInEDFData, preprocessECG } from "../Shared/ECGFunctions";
import axiosAuth from "../Axios/Interceptors";

type ECGProps = {
  EDFBuffer: any;
  progressBarCoutDown: any;
  selectedView: any;
  isEventClicked: any;
  dataSetForSelectedPatient: any;
  setProgressBarCoutDown: Function;
  setSelectedView: Function;
  isLeftMenu: any;
  showTestSymbols: boolean;
};

export default function ECG(props: ECGProps) {
  const [ECGTemplates, setECGTemplates] = useState<any | []>([]);
  const [AllEctopies, setAllEctopies] = useState<any | []>([]);

  const [selectedMeasurementIndex, setSelectedMeasurementIndex] = useState(-1);
  const [selectedAnnotationIndex, setSelectedAnnotationIndex] = useState(-1);
  const [AnnotationType, setAnnotationType] = useState("");

  const svgRef = useRef<any>();

  const svgScrollRef = useRef<any>();

  const [EDFDataSetALL, setEDFDataSetALL] = useState<any | []>([]);

  //Finalize Button on right code Starts
  const [Clickfinalize, SetClickfinalize] = useState(false);
  const CheckUncheckFinalize = () => {
    SetClickfinalize(!Clickfinalize);
  };

  //Finalize Button on right code Ends

  const [showRhythmPopup, setShowRhythmPopup] = useState(false);
  const [showErrorPopup, setshowErrorPopup] = useState(false);
  function ShowHideErrorModal() {
    setshowErrorPopup(!showErrorPopup);
  }
  // Temp data for d3 testing

  let dataSetFormat = 2;
  const scrollBoxHeight = 20;

  const [scrollY, setScrollY] = useState(-1);
  const scrollYRef = useRef<any | null>(null);
  scrollYRef.current = scrollY;

  const [showScroll, setShowScroll] = useState(false);

  const [highestHR, sethighestHR] = useState<any | []>([]);
  const [lowestHR, setlowestHR] = useState<any | []>([]);

  const [afibData, setAfibData] = useState([]);
  const [sinusData, setSinusData] = useState([]);

  // State Variables
  const [startSignal, setStartSignal] = useState(0);
  const [isEDFData, setIsEDFData] = useState(false);
  const [decodedEDFData, setDecodedEDFData] = useState<any>({});

  const isEDFDataRef = useRef<any | null>(null);
  const decodedEDFDataRef = useRef<any | null>(null);

  isEDFDataRef.current = isEDFData;
  decodedEDFDataRef.current = decodedEDFData;

  const [splitBoxDragStarted, setSplitBoxDragStarted] = useState(false);
  const splitBoxDragStartedRef = useRef<any | null>(null);
  splitBoxDragStartedRef.current = splitBoxDragStarted;

  const [scrollDragStarted, setScrollDragStarted] = useState(false);
  const scrollDragStartedRef = useRef<any | null>(null);
  scrollDragStartedRef.current = scrollDragStarted;

  const [scrollHeight, setScrollHeight] = useState(600);
  const scrollHeightRef = useRef<any | null>(null);
  scrollHeightRef.current = scrollHeight;

  const [clickPixelsFromLeftOfBox, setClickPixelsFromLeftOfBox] = useState(0);
  const clickPixelsFromLeftOfBoxRef = useRef<any | null>(null);
  clickPixelsFromLeftOfBoxRef.current = clickPixelsFromLeftOfBox;

  const [dimensionsDone, setdimensionsDone] = useState(false);
  const dimensionsDoneRef = useRef<any | null>(null);
  dimensionsDoneRef.current = dimensionsDone;

  const [scrollDimensionsDone, setscrollDimensionsDone] = useState(false);
  const scrollDimensionsDoneRef = useRef<any | null>(null);
  scrollDimensionsDoneRef.current = scrollDimensionsDone;

  const [updateScroll, setUpdateScroll] = useState(true);
  const updateScrollRef = useRef<any | null>(null);
  updateScrollRef.current = updateScroll;

  const [IndexedDataSet, setIndexedDataSet] = useState<any | []>([]);
  const [IndexedPeaksDataSet, setIndexedPeaksDataSet] = useState<any | []>([]);

  const [PeaksDataSet, setPeaksDataSet] = useState<any | []>([]);
  const [SVEDataSet, setSVEDataSet] = useState<any | []>([]);

  // Using these datasets to show P waves and other extra things on the graph during testing
  const [IndexedExtraDataSet, setIndexedExtraDataSet] = useState<any | []>([]);
  const [ExtraDataSet, setExtraDataSet] = useState<any | []>([]);

  const [BPMDataSet, setBPMDataSet] = useState<any | []>([]);
  const [minBPM, setminBPM] = useState(0);
  const [maxBPM, setmaxBPM] = useState(0);

  const [allMinutesHR, setallMinutesHR] = useState<any | []>([]);

  // Stores the number data rate
  const [dataRate, setdataRate] = useState(20);
  const [peakDetectionDone, setpeakDetectionDone] = useState(false);

  const [clickRectStart, setclickRectStart] = useState(-1);
  const [clickPointerStart, setclickPointerStart] = useState(-1);

  const [SelectedmmsValue, setSelectedmmsValue] = useState("15mm/s");
  const [SelectedmmvValue, setmmvSelectedmmvValue] = useState("10mm/mV");

  const [mmsValue, setmmsValue] = useState(15);
  const [mmvValue, setmmvValue] = useState(10);

  const [showGrid, setshowGrid] = useState(1);
  const [showRuler, setshowRuler] = useState(0);
  const [invertGraph, setinvertGraph] = useState(0);
  const [currentControl, setcurrentControl] = useState(1);

  const [lastClick, setlastClick] = useState(0);

  const currentControlRef = useRef<any | null>(null);
  currentControlRef.current = currentControl;

  const [globalW, setglobalW] = useState(400);
  const globalWRef = useRef<any | null>(null);
  globalWRef.current = globalW;

  const [h, seth] = useState(1600);

  const [apiReturnData, setApiReturnData] = useState<any | []>([]);

  const [AllAnnotations, setAllAnnotations] = useState<any | []>([]);
  const [DeletedAnnotations, setDeletedAnnotations] = useState<any | []>([]);

  const [AllBPMs, setAllBPMs] = useState<any | []>([]);

  const [AllMeasurements, setAllMeasurements] = useState<any | []>([]);
  const AllMeasurementsRef = useRef<any | null>(null);
  AllMeasurementsRef.current = AllMeasurements;

  const [SelectedDataSet, setSelectedDataSet] = useState<any | []>([]);
  const SelectedDataSetRef = useRef<any | null>(null);
  SelectedDataSetRef.current = SelectedDataSet;

  const [SelectedDataSetAll, setSelectedDataSetAll] = useState<any | []>([]);
  const SelectedDataSetAllRef = useRef<any | null>(null);
  SelectedDataSetAllRef.current = SelectedDataSetAll;

  const [BackupDataSetAll, setBackupDataSetAll] = useState<any | []>([]);
  const BackupDataSetAllRef = useRef<any | null>(null);
  BackupDataSetAllRef.current = BackupDataSetAll;

  const [showStripsPopup, setshowStripsPopup] = useState(false);
  function ShowHideStripsModal() {
    setshowStripsPopup(!showStripsPopup);
  }

  const [SelectedChannel, setSelectedChannel] = useState({
    ID: 1,
    Name: "Channel 1",
  });
  const [SelectedTime, setSelectedTime] = useState("");
  const [SelectedDate, setSelectedDate] = useState("");
  const [DropDown, setDropDown] = useState({
    MV: false,
    MS: false,
    Channel: false,
    Date: false,
  });
  const [BottomDiv, setBottomDiv] = useState({
    HRTrendView: false,
    BottomDiv2: false,
    ECGSplitView: false,
  });
  const [BeatDiv, setBeatDiv] = useState(false);
  const [ShoweHRTrends1, SetShoweHRTrends1] = useState(true);
  const [ShoweHRTrends2, SetShoweHRTrends2] = useState(false);

  //const [Undo, setUndo] = useState(false);
  const [ShowHideUndo, setShowHideUndo] = useState(true);

  const [ShowStrips, setShowStrips] = useState(false);
  const [TotalCountOfSelected, setTotalCountOfSelected] = useState<any>(0);
  const [reportedStripCount, setReportedStripCount] = useState<any>(0);

  const [RythmAnnotationObj, setRythmAnnotationObj] = useState<any | {}>({
    Pause: false,
    AVB: false,
    AF: false,
    SVT: false,
    Noise: false,
    AT: false,
    Normal: false,
  });
  const [MCTEvent, SetMCTEvent] = useState(false);
  const [Report, SetReport] = useState(false);
  const [ReportOption, SetReportOption] = useState(0);
  const [Type, SetType] = useState(0);
  const [showaccordionVE, SetshowaccordionVE] = useState(true);
  const ShowHideAccordionVE = () => {
    SetshowaccordionVE(!showaccordionVE);
  };

  const [showaccordionSVE, SetshowaccordionSVE] = useState(true);
  const ShowHideAccordionSVE = () => {
    SetshowaccordionSVE(!showaccordionSVE);
  };

  const RythmAnnotationObjRef = useRef<any | null>(null);
  RythmAnnotationObjRef.current = RythmAnnotationObj;

  const [mmsValueSplitView, setmmsValueSplitView] = useState(15);
  const [mmvValueSplitView, setmmvValueSplitView] = useState(10);

  // Global Variables
  // let numberOfRows = 6;
  //  let interval = 10;
  const heightOfRow = 129;
  const secondsInSplit = 10;

  const [interval, setinterval] = useState(10);
  const intervalRef = useRef<any | null>(null);
  intervalRef.current = interval;

  // This is the current start time of the visible data i.e. start of the first row currently visible on the screen
  const [GraphStartTime, setGraphStartTime] = useState(0);
  const GraphStartTimeRef = useRef<any | null>(null);
  GraphStartTimeRef.current = GraphStartTime;

  const [LineCount, setLineCount] = useState(6);
  const [intervalSplitView, setintervalSplitView] = useState(10);

  const LineCountRef = useRef<any | null>(null);
  LineCountRef.current = LineCount;

  // TODO: This needs to be updated whenever new data is loaded - currently hardcoded to 0 since our test data starts at 0

  // This is the overall Start Time of the Data which is currently loaded i.e. the first record of the day. Nothing to do with what is visible
  const [LoadedDataStartTime, setLoadedDataStartTime] = useState(0);
  const LoadedDataStartTimeRef = useRef<any | null>(null);
  LoadedDataStartTimeRef.current = LoadedDataStartTime;

  // TODO: This needs to be updated whenever load new data
  const [LoadedDataRows, setLoadedDataRows] = useState(0);
  const LoadedDataRowsRef = useRef<any | null>(null);
  LoadedDataRowsRef.current = LoadedDataRows;

  const [selectedPeakIndex, setSelectedPeakIndex] = useState({ i: -1, j: -1 });
  const selectedPeakIndexRef = useRef<any | null>(null);
  selectedPeakIndexRef.current = selectedPeakIndex;

  const [svgLeft, setsvgLeft] = useState(0);
  const svgLeftRef = useRef<any | null>(null);
  svgLeftRef.current = svgLeft;

  const [svgTop, setsvgTop] = useState(0);
  const svgTopRef = useRef<any | null>(null);
  svgTopRef.current = svgTop;

  const [svgScrollTop, setsvgScrollTop] = useState(0);
  const svgScrollTopRef = useRef<any | null>(null);
  svgScrollTopRef.current = svgScrollTop;

  let dragStarted = false;
  let dragStartX = 0;
  let dragStartY = 0;

  let rythmStarted = false;
  let rythmStartX = 0;
  let rythmStartY = 0;
  let rythmEndX = 0;
  let rythmEndY = 0;

  const [tagStartX, setTagStartX] = useState(0);
  const [tagStartY, setTagStartY] = useState(0);
  const [tagEndX, setTagEndX] = useState(0);
  const [tagEndY, setTagEndY] = useState(0);

  let svg = d3.select(svgRef.current);
  let xScale = d3.scaleLinear().domain([0, 0]).range([0, 0]);
  let yScale = d3.scaleLinear().domain([0, 0]).range([0, 0]);

  // Split view variables
  let svgScrollView = d3.select(svgScrollRef.current);

  const [startOfSplitBox, setStartOfSplitBox] = useState(0);
  const startOfSplitBoxRef = useRef<any | null>(null);
  startOfSplitBoxRef.current = startOfSplitBox;

  const [leftMenuEventSelected, setLeftMenuEventSelected] = useState(6);
  const [leftMenuDataObject, setLeftMenuDataObject] = useState({
    Totalbeats: 0,
    MaxHr: 0,
    MinHr: 0,
    Afib: 0,
    Normal: 0,
    SinusRhythm: 0,
    SinusBradycardia: 0,
    SinusTachycardia: 0,
    SinusArrhythmia: 0,
    SVE: 0,
    IsolatedSVE: 0,
    SVEPair: 0,
    SVERun: 0,
    SVERunLongest: 0,
    SVEBigeminy: 0,
    SVETrigeminy: 0,
    SVEQuadrigeminy: 0,
    VE: 0,
    IsolatedVE: 0,
    VEPair: 0,
    VERun: 0,
    VERunLongest: 0,
    VEBigeminy: 0,
    VETrigeminy: 0,
    VEQuadrigeminy: 0,
    Pause: 0,
    AVBlocks: 0,
    PacedRhythm: 0,
    Noise: 0,
  });

  function setDataForLeftMenu(SelectedPatient: number) {
    if (SelectedPatient === 2) {
      setLeftMenuDataObject({
        Totalbeats: 90254,
        MaxHr: 98,
        MinHr: 56,
        Afib: 0,
        Normal: 88904,
        SinusRhythm: 88904,
        SinusBradycardia: 352,
        SinusTachycardia: 756,
        SinusArrhythmia: 0,
        SVE: 132,
        IsolatedSVE: 132,
        SVEPair: 0,
        SVERun: 0,
        SVERunLongest: 0,
        SVEBigeminy: 0,
        SVETrigeminy: 0,
        SVEQuadrigeminy: 0,
        VE: 110,
        IsolatedVE: 110,
        VEPair: 0,
        VERun: 0,
        VERunLongest: 0,
        VEBigeminy: 0,
        VETrigeminy: 0,
        VEQuadrigeminy: 0,
        Pause: 0,
        AVBlocks: 0,
        PacedRhythm: 0,
        Noise: 12,
      });
    } else if (SelectedPatient === 3) {
      setLeftMenuDataObject({
        Totalbeats: 92504,
        MaxHr: 99,
        MinHr: 68,
        Afib: 2,
        Normal: 91562,
        SinusRhythm: 91562,
        SinusBradycardia: 256,
        SinusTachycardia: 410,
        SinusArrhythmia: 0,
        SVE: 140,
        IsolatedSVE: 140,
        SVEPair: 0,
        SVERun: 0,
        SVERunLongest: 0,
        SVEBigeminy: 0,
        SVETrigeminy: 0,
        SVEQuadrigeminy: 0,
        VE: 128,
        IsolatedVE: 128,
        VEPair: 0,
        VERun: 0,
        VERunLongest: 0,
        VEBigeminy: 0,
        VETrigeminy: 0,
        VEQuadrigeminy: 0,
        Pause: 0,
        AVBlocks: 0,
        PacedRhythm: 0,
        Noise: 21,
      });
    }
  }

  useEffect(() => {
    AssignData();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    window.addEventListener("resize", screenResized);
    window.addEventListener("mouseup", handleWindowMouseUp);
  }, []);

  const handleWindowMouseUp = (e: any) => {
    dragStarted = false;
    setSplitBoxDragStarted(false);
    setScrollDragStarted(false);
  };

  // TODO: What all needs to be done when the screen is resized ?
  const screenResized = (e: any) => {
    setTimeout(() => {
      changeGraphsAfterResize();
    }, 100);
  };

  useEffect(() => {
    if (props.isLeftMenu) {
      document
        .getElementById("MainBottomDiv2")
        ?.setAttribute("style", "width:calc(100% - 275px)");
    } else {
      document
        .getElementById("MainBottomDiv2")
        ?.setAttribute("style", "width:100%");
    }

    ShowHideLeftMenu();
  }, [props.isLeftMenu]);

  useEffect(() => {
    if (DeletedAnnotations && DeletedAnnotations.length > 0) {
      setShowHideUndo(true);
    } else {
      setShowHideUndo(false);
    }
  }, [DeletedAnnotations]);

  const detectKeyDown = (e: any) => {
    if (
      selectedPeakIndexRef.current.i >= 0 &&
      selectedPeakIndexRef.current.j >= 0
    ) {
      let keypressed: any = e.key.toUpperCase();

      if (
        keypressed === "A" ||
        keypressed === "N" ||
        keypressed === "V" ||
        keypressed === "S" ||
        keypressed === "J" ||
        keypressed === "T"
      ) {
        SelectedDataSetAllRef.current[selectedPeakIndexRef.current.i].peaks[
          selectedPeakIndexRef.current.j
        ].classification = keypressed;
        MakeIndexedList(
          GraphStartTimeRef.current,
          intervalRef.current,
          LineCountRef.current
        );
      }
    }
  };

  function resetVariables() {
    setSelectedMeasurementIndex(-1);
    setSelectedAnnotationIndex(-1);
    clearTempStuff();
  }

  function clearTempStuff() {
    // Removes the Rect shown when you click on other clicks to load a place in main graph
    setclickRectStart(-1);
    d3.selectAll(".clickRect").remove();
    // Removes the Rect shown when you click graph in Pointer mode
    setclickPointerStart(-1);
    d3.selectAll(".clickPointerRect").remove();

    // Remove other things drawn
    d3.selectAll(".rythmMarkers").remove();
    d3.selectAll(".annotationTools").remove();
    d3.selectAll(".savedCalipers").remove();
  }

  const CurrentTool = {
    Pointer: 1,
    Caliper: 2,
    TagTool: 3,
    AnnotationTool: 4,
    SplitBox: 5,
  };

  const AppArea = {
    None: 0,
    MainGraph: 1,
    BPMGraph: 2,
    HRTrend: 3,
    HRTrendBars: 4,
  };

  const TagReport = {
    EntireEpisode: 1,
    Onset: 2,
    Termination: 3,
  };

  const AnnotationTagType = {
    None: 0,
    AF: 1,
    Pause: 2,
    Noise: 3,
    AVB: 4,
    AT: 5,
    SVT: 6,
    VE: 7,
    SVE: 8,
    Normal: 9,
  };

  const [gridViewInfo, setIsGridViewInfo] = useState({ Rows: 3, Columns: 7 });
  const [reportType, setReportType] = useState(1);
  const [leftMenuType, setLeftMenuType] = useState(
    Utility.ECGClassification.Atrial_Flutter_Fixed_Block
  );

  const MouseDown = (e: any) => {
    //   console.log('from global left: ' + svgLeftRef.current + 'top :' + svgTopRef.current);
    //   console.log('MouseDown. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    //   console.log(globalCurrentControl);
    if (currentControlRef.current === CurrentTool.Pointer) {
      let timeInSeconds = findTimeForMainMouseCoordinates(e.pageX, e.pageY);
      handlePointClick(timeInSeconds);
      setclickPointerStart(timeInSeconds);
      setlastClick(AppArea.MainGraph);

      // TODO - TEST
      let nearestBeat = getNearestBeatFromIndexedDataSet(timeInSeconds);
      console.log("-----");
      if (nearestBeat.j >= 1) {
        if (nearestBeat.j >= 2) {
          console.log(
            "-2  :  " +
              IndexedDataSet[nearestBeat.i][nearestBeat.j - 2][0] +
              "  :  " +
              IndexedDataSet[nearestBeat.i][nearestBeat.j - 2][1]
          );
        }
        console.log(
          "-1  :  " +
            IndexedDataSet[nearestBeat.i][nearestBeat.j - 1][0] +
            "  :  " +
            IndexedDataSet[nearestBeat.i][nearestBeat.j - 1][1]
        );
      }
      console.log(
        " 0  :  " +
          IndexedDataSet[nearestBeat.i][nearestBeat.j][0] +
          "  :  " +
          IndexedDataSet[nearestBeat.i][nearestBeat.j][1]
      );

      if (IndexedDataSet[nearestBeat.i].length - nearestBeat.j > 1) {
        console.log(
          "+1  :  " +
            IndexedDataSet[nearestBeat.i][nearestBeat.j + 1][0] +
            "  :  " +
            IndexedDataSet[nearestBeat.i][nearestBeat.j + 1][1]
        );
        if (IndexedDataSet[nearestBeat.i].length - nearestBeat.j > 2) {
          console.log(
            "+2  :  " +
              IndexedDataSet[nearestBeat.i][nearestBeat.j + 2][0] +
              "  :  " +
              IndexedDataSet[nearestBeat.i][nearestBeat.j + 2][1]
          );
        }
        if (IndexedDataSet[nearestBeat.i].length - nearestBeat.j > 3) {
          console.log(
            "+3  :  " +
              IndexedDataSet[nearestBeat.i][nearestBeat.j + 3][0] +
              "  :  " +
              IndexedDataSet[nearestBeat.i][nearestBeat.j + 3][1]
          );
        }
      }

      // END TEST
    } else if (currentControlRef.current === CurrentTool.Caliper) {
      //     console.log('start drag - horizontal cliper');
      handleHorizontalCaliper(true, getSVGx(e.pageX), getSVGy(e.pageY), false);
    } else if (currentControlRef.current === CurrentTool.TagTool) {
      mouseDownOnRhythm(getSVGx(e.pageX), getSVGy(e.pageY));
    } else if (currentControlRef.current === CurrentTool.AnnotationTool) {
      let timeInSeconds = findTimeForMainMouseCoordinates(e.pageX, e.pageY);
      let peakIndex: any = getNearestPeak(timeInSeconds, 0);
      //      console.log("setting peak index : " + peakIndex);
      setSelectedPeakIndex({ i: peakIndex.i, j: peakIndex.j });
      //      console.log("after peak index : " + selectedPeakIndexRef.current);

      //  SelectedDataSet[0].peaks[peakIndex].classification = "V";
      //      console.log(SelectedDataSet);

      //  MakeIndexedList(SelectedDataSet, interval);
    }
  };

  const MouseUp = (e: any) => {
    //   console.log('MouseUp. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    dragStarted = false;
    if (currentControlRef.current === CurrentTool.Caliper) {
      handleHorizontalCaliper(false, getSVGx(e.pageX), getSVGy(e.pageY), true);
    }

    if (BottomDiv.ECGSplitView) {
      setSplitBoxDragStarted(false);
      setClickPixelsFromLeftOfBox(0);
    }
  };

  const MouseMove = (e: any) => {
    // console.log('MouseMove. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    if (BottomDiv.ECGSplitView) {
      if (splitBoxDragStartedRef.current) {
        //   console.log("click now : " + clickPixelsFromLeftOfBoxRef.current);
        // Since Drag has started, we need to move the box
        let mouseX = getSVGx(e.pageX);
        let mouseY = getSVGy(e.pageY);
        let newXOfStartOfBox = mouseX - clickPixelsFromLeftOfBoxRef.current;
        if (newXOfStartOfBox < 0) {
          newXOfStartOfBox = 0;
        }
        let zone = Math.floor(mouseY / heightOfRow);
        let startOfThisZone = GraphStartTimeRef.current + zone * interval;
        let diffOfBoxFromLeftInSeconds =
          (newXOfStartOfBox / globalW) * interval;
        let newStartOfSplitBox = diffOfBoxFromLeftInSeconds + startOfThisZone;
        setStartOfSplitBox(newStartOfSplitBox);

        //      setStartOfSplitBox(startOfSplitBoxRef.current + 0.1);
      }
    } else {
      if (currentControlRef.current === CurrentTool.Caliper) {
        handleHorizontalCaliper(
          false,
          getSVGx(e.pageX),
          getSVGy(e.pageY),
          false
        );
      } else if (currentControlRef.current === CurrentTool.AnnotationTool) {
        handleAnnotationToolEvent(2, getSVGx(e.pageX), getSVGy(e.pageY));
      }
    }
  };

  const MouseOut = (e: any) => {
    // console.log('MouseOut. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    /*   if(currentControlRef.current === 4) {
        console.log('mouse out');
       handleAnnotationToolEvent(4, getSVGx(e.pageX), getSVGy(e.pageY));
     }*/
    /* if(BottomDiv.ECGSplitView) {
      setSplitBoxDragStarted(false);
    }*/
  };

  // let dragStartY = 0;

  const SplitBoxMouseDown = (e: any) => {
    //  console.log('MouseDown. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    // alert("Split");
    setSplitBoxDragStarted(true);
    let clickX = getSVGx(e.pageX);
    let clickY = getSVGy(e.pageY);
    // At this point, we figure out how far along on the width was the initial click
    let zone = Math.floor(clickY / heightOfRow);
    //Math.round((startOfSplitBoxRef.current - GraphStartTimeRef.current)/Interval);

    let startOfThisZone = GraphStartTimeRef.current + zone * interval;
    let diffOfBoxFromLeftInSeconds =
      startOfSplitBoxRef.current - startOfThisZone;
    let diffOfBoxFromLeftInPixels =
      (diffOfBoxFromLeftInSeconds / interval) * globalW;
    let localClickPixelsFromLeftOfBox = clickX - diffOfBoxFromLeftInPixels;
    setClickPixelsFromLeftOfBox(localClickPixelsFromLeftOfBox);
    //  console.log("Click inside box : " + localClickPixelsFromLeftOfBox);

    //    GraphStartTimeRef.current
  };

  // TODO: Delete this as it is obselete
  const SplitBoxMouseUp = (e: any) => {
    //  console.log('MouseUp. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    // alert("Split");
    setSplitBoxDragStarted(false);
    setClickPixelsFromLeftOfBox(0);
  };

  // TODO: Delete this as it is obselete
  const SplitBoxMouseMove = (e: any) => {
    //  console.log('MouseMove. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    // alert("Split");

    if (splitBoxDragStartedRef.current) {
      //   console.log("click now : " + clickPixelsFromLeftOfBoxRef.current);
      // Since Drag has started, we need to move the box
      let mouseX = getSVGx(e.pageX);
      let mouseY = getSVGy(e.pageY);
      let newXOfStartOfBox = mouseX - clickPixelsFromLeftOfBoxRef.current;
      if (newXOfStartOfBox < 0) {
        newXOfStartOfBox = 0;
      }
      let zone = Math.floor(mouseY / heightOfRow);
      let startOfThisZone = GraphStartTimeRef.current + zone * interval;
      let diffOfBoxFromLeftInSeconds = (newXOfStartOfBox / globalW) * interval;
      let newStartOfSplitBox = diffOfBoxFromLeftInSeconds + startOfThisZone;
      setStartOfSplitBox(newStartOfSplitBox);

      //      setStartOfSplitBox(startOfSplitBoxRef.current + 0.1);
    }
  };

  // TODO: Delete this as it is obselete
  const SplitBoxMouseOut = (e: any) => {
    /*

//    console.log('MouseOut. X:' + getSVGx(e.pageX) + ', Y:' + getSVGy(e.pageY));
    let mouseY = getSVGy(e.pageY);
    let mouseZone = Math.floor(mouseY / heightOfRow);
    let boxZone = Math.floor((startOfSplitBoxRef.current - GraphStartTimeRef.current)/interval);
  //  console.log("mouseZone : " + mouseZone + ", boxZone : " + boxZone);
    if(mouseZone != boxZone) {
      if(mouseZone >= 0 && mouseZone < 6) {
        if(mouseZone > boxZone) {
          setStartOfSplitBox(startOfSplitBoxRef.current + interval);
        }
        else if(mouseZone > boxZone) {
          setStartOfSplitBox(startOfSplitBoxRef.current - interval);
        }
      }  
    }
  */
  };

  /*
  function getZoneFromSeconds(seconds) {
    return Math.floor(seconds/interval);
  }
*/
  function handleAnnotationToolEvent(typeOfEvent: any, x: any, y: any) {
    d3.selectAll(".annotationTools").remove();
    // Mouse Move Event - type 2
    if (typeOfEvent === 2) {
      // Clear previous circle and draw new one
      svg
        .append("circle")
        .attr("class", "annotationTools")
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 25)
        .attr("stroke", "rgba(250, 160, 160,0.8)")
        .attr("fill", "rgba(250, 160, 160,0.4)");
    } else if (typeOfEvent === 4) {
      // alert('hello');
    }
  }

  function findTimeForMainMouseCoordinates(x: any, y: any) {
    // First convert the x/y into time based on y in first row AND add the start time
    let timeInSeconds =
      timeDifferenceFixedY(0, 0, getSVGx(x), getYatTopOfZone(getSVGy(y))) +
      GraphStartTimeRef.current;
    return timeInSeconds;
  }

  function mouseDownOnRhythm(x: any, y: any) {
    setSelectedAnnotationIndex(-1);
    resetVariables();

    d3.selectAll(".rythmMarkers").remove();

    let arrowBaseFromMainLine = 10;
    let arrowTipFromMainLine = 30;
    let pixelsFromTop = 0.15 * heightOfRow;
    let pixelsFromBottom = heightOfRow - pixelsFromTop;

    //grey arrow head
    svg
      .append("defs")
      .append("marker")
      .attr("class", "rythmMarkers")
      .attr("id", "greyarrowhead")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 6)
      .attr("refY", 0)
      .attr("markerWidth", 4)
      .attr("markerHeight", 4)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-4L12,0L0,4")
      .attr("fill", "grey");

    if (!rythmStarted) {
      // Start a new record
      rythmStarted = true;
      rythmStartX = x;
      let zone = getZoneFromY(y);
      rythmStartY = zone * heightOfRow;

      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .style("stroke", "grey")
        .style("stroke-width", 4)
        .attr("x1", rythmStartX)
        .attr("y1", rythmStartY)
        .attr("x2", rythmStartX)
        .attr("y2", rythmStartY + heightOfRow);

      //top left arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmStartX - arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromTop)
        .attr("x2", rythmStartX - arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // bottom left arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmStartX - arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromBottom)
        .attr("x2", rythmStartX - arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      //top right arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromTop)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      //bottom right arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromBottom)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // Define the marker for the grey arrow head ends
    } else {
      // Start a new record
      rythmStarted = false;

      rythmEndX = x;
      let zone = getZoneFromY(y);
      rythmEndY = zone * heightOfRow;

      // Checking if drag is forward or back
      let tagForward = true;

      if (rythmEndY === rythmStartY) {
        // If we are on the same row, check X
        if (rythmEndX < rythmStartX) {
          tagForward = false;
        }
      } else if (rythmEndY < rythmStartY) {
        tagForward = false;
      }

      if (!tagForward) {
        // Flip the start and the end to simplify things
        let tempX = rythmStartX;
        let tempY = rythmStartY;
        rythmStartX = rythmEndX;
        rythmStartY = rythmEndY;
        rythmEndX = tempX;
        rythmEndY = tempY;
      }

      // Make the Start Line
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .style("stroke", "grey")
        .style("stroke-width", 4)
        .attr("x1", rythmStartX)
        .attr("y1", rythmStartY)
        .attr("x2", rythmStartX)
        .attr("y2", rythmStartY + heightOfRow);

      //top right arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromTop)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      //bottom right arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmStartX + arrowBaseFromMainLine)
        .attr("y1", rythmStartY + pixelsFromBottom)
        .attr("x2", rythmStartX + arrowTipFromMainLine)
        .attr("y2", rythmStartY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // Make the End Line
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .style("stroke", "grey")
        .style("stroke-width", 4)
        .attr("x1", rythmEndX)
        .attr("y1", rythmEndY)
        .attr("x2", rythmEndX)
        .attr("y2", rythmEndY + heightOfRow);

      //top left arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmEndX - arrowBaseFromMainLine)
        .attr("y1", rythmEndY + pixelsFromTop)
        .attr("x2", rythmEndX - arrowTipFromMainLine)
        .attr("y2", rythmEndY + pixelsFromTop)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      // bottom left arrow
      svg
        .append("line")
        .attr("class", "rythmMarkers")
        .attr("x1", rythmEndX - arrowBaseFromMainLine)
        .attr("y1", rythmEndY + pixelsFromBottom)
        .attr("x2", rythmEndX - arrowTipFromMainLine)
        .attr("y2", rythmEndY + pixelsFromBottom)
        .attr("stroke", "grey")
        .attr("stroke-width", 3)
        .attr("marker-end", "url(#greyarrowhead)");

      setTagStartX(rythmStartX);
      setTagStartY(rythmStartY);
      setTagEndX(rythmEndX);
      setTagEndY(rythmEndY);
      // Open the popup to get the rest of the input
      setShowRhythmPopup(true);
    }
  }

  function drawRythmBar(
    typeText: any,
    startX: any,
    startY: any,
    endX: any,
    endY: any
  ) {
    // console.log(
    //   "text : " +
    //     typeText +
    //     ", startX: " +
    //     startX +
    //     ", startY: " +
    //     startY +
    //     ", endX: " +
    //     endX +
    //     ", endY: " +
    //     endY
    // );

    let barHeight = 13;
    let fontSize = "11px";
    let fontPosition = 11;

    // // Draw the starting Line
    // svg
    //   .append("line")
    //   .attr("class", "rythmBar")
    //   .style("stroke", "red")
    //   .style("stroke-width", 2)
    //   .attr("x1", startX)
    //   .attr("y1", startY)
    //   .attr("x2", startX)
    //   .attr("y2", startY + barHeight);

    // // Draw the ending Line
    // svg
    //   .append("line")
    //   .attr("class", "rythmBar")
    //   .style("stroke", "red")
    //   .style("stroke-width", 2)
    //   .attr("x1", endX)
    //   .attr("y1", endY)
    //   .attr("x2", endX)
    //   .attr("y2", endY + barHeight);

    if (startY === endY) {
      // Since it is just one row - draw the shaded area and write the text in there
      svg
        .append("rect")
        .attr("class", "rythmBar")
        .attr("x", startX)
        .attr("y", startY)
        .attr("width", endX - startX)
        .attr("height", heightOfRow)
        .attr("fill", "rgba(250, 160, 160,0.4)");

      svg
        .append("text")
        .attr("class", "rythmBar")
        .text(typeText)
        .attr("fill", "red")
        .attr("font-size", fontSize)
        .attr("x", startX + (endX - startX) / 2 - 10)
        .attr("y", startY + fontPosition);
    } else {
      // As it is multiple rows, start with making the bar and writing the text for the first and last row
      svg
        .append("rect")
        .attr("class", "rythmBar")
        .attr("x", startX)
        .attr("y", startY)
        .attr("width", globalW - startX)
        .attr("height", heightOfRow)
        .attr("fill", "rgba(250, 160, 160,0.4)");

      svg
        .append("text")
        .attr("class", "rythmBar")
        .text(typeText)
        .attr("fill", "red")
        .attr("font-size", fontSize)
        .attr("x", startX + (globalW - startX) / 2 - 10)
        .attr("y", startY + fontPosition);

      // last row
      svg
        .append("rect")
        .attr("class", "rythmBar")
        .attr("x", 0)
        .attr("y", endY)
        .attr("width", endX)
        .attr("height", heightOfRow)
        .attr("fill", "rgba(250, 160, 160,0.4)");

      svg
        .append("text")
        .attr("class", "rythmBar")
        .text(typeText)
        .attr("fill", "red")
        .attr("font-size", fontSize)
        .attr("x", endX / 2 - 10)
        .attr("y", endY + fontPosition);

      // Check if there are rows between the two
      if (endY - startY > heightOfRow) {
        let numberOfRowsBetween = (endY - startY) / heightOfRow - 1;
        for (let i = 0; i < numberOfRowsBetween; i++) {
          svg
            .append("rect")
            .attr("class", "rythmBar")
            .attr("x", 0)
            .attr("y", startY + (i + 1) * heightOfRow)
            .attr("width", globalW)
            .attr("height", heightOfRow)
            .attr("fill", "rgba(250, 160, 160,0.4)");

          svg
            .append("text")
            .attr("class", "rythmBar")
            .text(typeText)
            .attr("fill", "red")
            .attr("font-size", fontSize)
            .attr("x", globalW / 2 - 10)
            .attr("y", startY + (i + 1) * heightOfRow + fontPosition);
        }
      }
    }
  }

  // Returns the Time from an X and Y values of the main grid
  function getTimeFromXandY(x: any, y: any) {
    let zone = getZoneFromY(y);
    let secondsInThisRow = (x / globalW) * interval;
    return zone * interval + secondsInThisRow + GraphStartTime;
  }

  function drawHorizontalCaliper(
    caliperStartX: any,
    caliperStartY: any,
    caliperEndX: any,
    caliperEndY: any,
    duration: any,
    bpm: any,
    className: any
  ) {
    let timeDisplayText = duration + " s";
    let fulldisplayText = timeDisplayText + ", " + bpm + " bpm";

    // Create a group for the caliper lines
    let caliperGroup = svg.append("g").attr("class", className);

    // Draw the Start Bar
    caliperGroup
      .append("line")
      .attr("class", className)
      .style("stroke", "blue")
      .style("stroke-width", 2)
      .attr("x1", caliperStartX)
      .attr("y1", caliperStartY - 15)
      .attr("x2", caliperStartX)
      .attr("y2", caliperStartY + 15);

    // Drwa the End Bar
    caliperGroup
      .append("line")
      .attr("class", className)
      .style("stroke", "blue")
      .style("stroke-width", 2)
      .attr("x1", caliperEndX)
      .attr("y1", caliperEndY - 15)
      .attr("x2", caliperEndX)
      .attr("y2", caliperEndY + 15);

    if (caliperStartY === caliperEndY) {
      // If drag is in the same row, draw a line
      caliperGroup
        .append("line")
        .attr("class", className)
        .style("stroke", "blue")
        .style("stroke-width", 2)
        .attr("x1", caliperStartX)
        .attr("y1", caliperStartY)
        .attr("x2", caliperEndX)
        .attr("y2", caliperEndY);

      var caliperText = caliperGroup
        .append("text")
        .attr("class", className)
        .text(fulldisplayText)
        .attr("fill", "blue")
        .attr("font-size", "12px")
        .attr("x", -1000)
        .attr("y", 0);

      var textWidth = caliperText.node().getComputedTextLength();

      caliperText
        .attr("x", (caliperStartX + caliperEndX - textWidth) / 2)
        .attr("y", caliperStartY - 5);
    } else {
      // Since the drag is to another line, draw a line to the end of this row
      // First draw a line from start to end of row
      caliperGroup
        .append("line")
        .attr("class", className)
        .style("stroke", "blue")
        .style("stroke-width", 2)
        .attr("x1", caliperStartX)
        .attr("y1", caliperStartY)
        .attr("x2", globalW)
        .attr("y2", caliperStartY);

      var caliperTextFirst = caliperGroup
        .append("text")
        .attr("class", className)
        .text(fulldisplayText)
        .attr("fill", "blue")
        .attr("font-size", "12px")
        .attr("x", -1000)
        .attr("y", 0);

      var textWidthFirst = caliperTextFirst.node().getComputedTextLength();

      caliperTextFirst
        .attr("x", (caliperStartX + globalW - textWidthFirst) / 2)
        .attr("y", caliperStartY - 5);

      // First draw a line from start of the last row to the end of the caliper
      caliperGroup
        .append("line")
        .attr("class", className)
        .style("stroke", "blue")
        .style("stroke-width", 2)
        .attr("x1", 0)
        .attr("y1", caliperEndY)
        .attr("x2", caliperEndX)
        .attr("y2", caliperEndY);

      var caliperTextLast = caliperGroup
        .append("text")
        .attr("class", className)
        .text(fulldisplayText)
        .attr("fill", "blue")
        .attr("font-size", "12px")
        .attr("x", -1000)
        .attr("y", 0);

      var textWidthLast = caliperTextLast.node().getComputedTextLength();

      caliperTextLast
        .attr("x", (0 + caliperEndX - textWidthLast) / 2)
        .attr("y", caliperEndY - 5);

      // Check if there are rows between the two
      if (caliperEndY - caliperStartY > heightOfRow) {
        let numberOfRowsBetween =
          (caliperEndY - caliperStartY) / heightOfRow - 1;
        for (let i = 0; i < numberOfRowsBetween; i++) {
          caliperGroup
            .append("line")
            .attr("class", className)
            .style("stroke", "blue")
            .style("stroke-width", 2)
            .attr("x1", 0)
            .attr("y1", caliperStartY + (i + 1) * heightOfRow)
            .attr("x2", globalW)
            .attr("y2", caliperStartY + (i + 1) * heightOfRow);

          var caliperTextMiddle = caliperGroup
            .append("text")
            .attr("class", className)
            .text(fulldisplayText)
            .attr("fill", "blue")
            .attr("font-size", "12px")
            .attr("x", -1000)
            .attr("y", 0);

          var textWidthMiddle = caliperTextMiddle
            .node()
            .getComputedTextLength();

          caliperTextMiddle
            .attr("x", (0 + globalW - textWidthMiddle) / 2)
            .attr("y", caliperStartY + (i + 1) * heightOfRow - 5);
        }
      }
    }
  }

  function handleHorizontalCaliper(start: any, x: any, y: any, finish: any) {
    if (start) {
      resetVariables();
      setSelectedMeasurementIndex(-1);
      dragStarted = true;
      dragStartX = x;
      let zone = getZoneFromY(y);
      dragStartY = heightOfRow / 2 + zone * heightOfRow;
    } else if (dragStarted || finish) {
      //Start of horizontal Caliper
      let caliperEndX = x;
      let endZone = getZoneFromY(y);
      let caliperEndY = heightOfRow / 2 + endZone * heightOfRow;

      // Checking if drag is forward or back
      let dragForward = true;

      if (caliperEndY === dragStartY) {
        // If we are on the same row, check X
        if (caliperEndX < dragStartX) {
          dragForward = false;
        }
      } else if (caliperEndY < dragStartY) {
        dragForward = false;
      }

      let caliperStartX = dragStartX;
      let caliperStartY = dragStartY;

      if (!dragForward) {
        // Flip the start and the end to simplify things
        caliperStartX = caliperEndX;
        caliperStartY = caliperEndY;
        caliperEndX = dragStartX;
        caliperEndY = dragStartY;
      }
      //      drawRythmBar("ABC", caliperStartX, caliperStartY - heightOfRow/2, caliperEndX, caliperEndY - heightOfRow/2);
      let timeDifference = timeDifferenceFixedY(
        caliperStartX,
        caliperStartY,
        caliperEndX,
        caliperEndY
      );

      let caliperStartTime = getTimeFromXandY(caliperStartX, caliperStartY);
      let caliperEndTime = getTimeFromXandY(caliperEndX, caliperEndY);

      let beatAndBPM: any = getBPMAndBeats(caliperStartTime, caliperEndTime);
      // To get Time from start of day - use
      //      let secondsFromStartOfDay = timeDifferenceFixedY(0, 0, startX, startY);

      let roundedBPM = Math.round(beatAndBPM.BPM);

      if (finish) {
        PushMeasurementInArray(
          parseFloat(caliperStartTime.toFixed(2)),
          parseFloat(timeDifference.toFixed(2)),
          beatAndBPM.Beats,
          roundedBPM
        );
        d3.selectAll(".currentCaliper").remove();
      } else {
        d3.selectAll(".currentCaliper").remove();

        drawHorizontalCaliper(
          caliperStartX,
          caliperStartY,
          caliperEndX,
          caliperEndY,
          parseFloat(timeDifference.toFixed(2)),
          roundedBPM,
          "currentCaliper"
        );
      }
    }
  }

  // This function gets the time difference in seconds - only works if the Ys are the same for one zone and End is after Start
  function timeDifferenceFixedY(
    startX: any,
    startY: any,
    endX: any,
    endY: any
  ) {
    //  console.log('startx : ' + startX + ', startY : ' + startY + ', endX : ' + endX + ', endY: ' + endY);
    if (startY === endY) {
      let pixelDifference = endX - startX;
      return (pixelDifference / globalW) * interval;
    } else {
      let numberOfRowsBetween = (endY - startY) / heightOfRow - 1;
      let pixelDifference = globalW - startX;
      pixelDifference = pixelDifference + (endX - 0);
      return (pixelDifference / globalW + numberOfRowsBetween) * interval;
    }
    return 0;
  }

  // This function gets Y at top of the zone
  function getYatTopOfZone(inputY: any) {
    return Math.floor(inputY / heightOfRow) * heightOfRow;
  }

  function changeGraphsAfterResize() {
    let returnObj = setMainDimensions();

    let newStartTime = GraphStartTimeRef.current;
    if (newStartTime != 0) {
      let remainder = newStartTime % returnObj.localInterval;
      newStartTime = newStartTime - remainder;
      setGraphStartTime(newStartTime);
    }

    //TODO: Check new height and see if we should reduce the number of lines picked up here.
    MakeIndexedList(
      newStartTime,
      returnObj.localInterval,
      LineCountRef.current
    );

    setUpdateScroll(true);
  }

  function setMainDimensions() {
    // Get the width of the container to set the SVG width
    var BottomDivWidth =
      document.getElementById("MainSVGContainer")?.clientWidth;
    //console.log("main draw width: " + BottomDivWidth);

    let w = globalW;
    if (BottomDivWidth) {
      w = BottomDivWidth;
      setglobalW(w);
    }

    // Pick up the bounding rectangle of the SVG to get the Left and Top of the Grid - used in converting screen coordinates to SVG coordinates
    let boundingRect: any = document
      .getElementById("mainSVG")
      ?.getBoundingClientRect();
    setsvgLeft(boundingRect?.left);
    setsvgTop(boundingRect?.top);

    // Set the interval i.e. how many seconds per row
    let localInterval = getAndUpdateInterval(
      w,
      BottomDiv.ECGSplitView,
      mmsValue
    );

    var returnObj = { w: w, localInterval: localInterval };

    setdimensionsDone(true);
    return returnObj;
  }

  useEffect(() => {
    if (props.selectedView === AppContext.view.Analysis) {
      setMainDimensions();
    }
  }, [props.selectedView]);

  // DrawMainGrid - This function is responsible for drawing and updating the main Grid
  useEffect(() => {
    if (
      dimensionsDoneRef.current &&
      props.selectedView === AppContext.view.Analysis
    ) {
      // This zone variable is used to track which Row is being drawn
      let zone = 0;
      let w = globalWRef.current;

      let localInterval = intervalRef.current;

      // Set up the SVG
      svg = d3
        .select(svgRef.current)
        .attr("width", w)
        .attr("height", h)
        .style("overflow", "visible")
        .on("mousedown", MouseDown)
        .on("mouseup", MouseUp)
        .on("mousemove", MouseMove)
        .on("mouseout", MouseOut);

      // setting the scaling
      xScale = d3
        .scaleLinear()
        .domain([
          GraphStartTimeRef.current,
          GraphStartTimeRef.current + localInterval,
        ])
        .range([0, w]);

      let localYRange = 30000 / mmvValue;

      yScale = d3
        .scaleLinear()
        .domain([-localYRange, localYRange])
        .range([heightOfRow, 0]);

      const generateScaledLine = d3
        .line()
        .x((d: any, i: any) => xScale(d[0] - zone * localInterval))
        .y(
          (d: any, i: any) =>
            yScale(invertGraph === 0 ? d[1] : -d[1]) + zone * heightOfRow
        )
        .curve(d3.curveLinear);

      // Remove current lines
      d3.selectAll(".mainViewLine").remove();

      // If the signal has started, draw the grid
      if (IndexedDataSet && IndexedDataSet.length > 0 && startSignal === 1) {
        // Remove the background grid if it exists already
        d3.selectAll(".mainViewGrid").remove();

        // Draw the background grid if it is turned on
        if (showGrid === 1 && BottomDiv.ECGSplitView === false) {
          // Define the pattern for the image
          const pattern = svg
            .append("defs")
            .append("pattern")
            .attr("id", "mainGrid-pattern")
            .attr("class", "mainViewGrid")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", heightOfRow * 2)
            .attr("height", heightOfRow)
            .attr("patternUnits", "userSpaceOnUse")
            .append("image")
            .attr("xlink:href", "/images/Grid3.png")
            .attr("width", heightOfRow * 2)
            .attr("height", heightOfRow);

          // Append a rectangle element with the image pattern
          svg
            .append("rect")
            .attr("class", "mainViewGrid")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", w)
            .attr("height", h)
            .attr("fill", "url(#mainGrid-pattern)");
        }

        // Draw the main ECG Line - first row and then append the rest in the loop below
        svg
          .selectAll(".line")
          .data([IndexedDataSet[0]])
          .join("path")
          .attr("class", "mainViewLine")
          .attr("d", (d: any) => generateScaledLine(d))
          .attr("fill", "none")
          .attr("stroke", "green");

        for (let i = 1; i < IndexedDataSet.length; i++) {
          zone = i;
          svg
            .append("path")
            .attr("class", "mainViewLine")
            .data([IndexedDataSet[i]])
            .attr("d", (d: any) => generateScaledLine(d))
            .attr("fill", "none")
            .attr("stroke", "green");
        }

        //TODO: Remove this Test

        //        console.log(IndexedDataSet);

        d3.selectAll(".testDots").remove();
        // if (PeaksDataSet.length > 2000) {
        //   zone = 1;
        //   for (let index = 0; index < 200; index++) {
        //     zone = Math.floor(
        //       (PeaksDataSet[index][0] - GraphStartTimeRef.current) / interval
        //     );

        //     if (zone <= 6) {
        //       svg
        //         .append("circle")
        //         .attr("class", "testDots")
        //         .attr(
        //           "cx",
        //           xScale(PeaksDataSet[index][0] - zone * localInterval)
        //         )
        //         .attr(
        //           "cy",
        //           yScale(
        //             invertGraph === 0
        //               ? PeaksDataSet[index][1]
        //               : PeaksDataSet[index][1]
        //           ) +
        //             zone * heightOfRow
        //         )
        //         .attr("r", 2)
        //         .attr("stroke", "rgba(0, 0, 250,0.8)")
        //         .attr("fill", "rgba(0, 0, 250,0.1)");
        //     }
        //   }
        // }

        for (let z = 0; z < SVEDataSet.length; z++) {
          zone = Math.floor(
            (SVEDataSet[z][0] - GraphStartTimeRef.current) / interval
          );

          if (zone <= 6) {
            let Range = getBeatDataInRangeEDF(
              SVEDataSet[z][0] - 0.2,
              SVEDataSet[z][0] + 0.2,
              0
            ) as any;
            //  console.log(Range);
            if (Range.length > 0) {
              for (let j = 1; j < Range.length; j++) {
                svg
                  .append("line")
                  .attr("class", "mainViewLine")
                  .style("stroke", AppContext.GraphColors.SVELineColor)
                  .attr("stroke-width", 2)
                  .attr("x1", xScale(Range[j - 1][0] - zone * localInterval))
                  .attr(
                    "y1",
                    yScale(
                      invertGraph === 0 ? Range[j - 1][1] : -Range[j - 1][1]
                    ) +
                      zone * heightOfRow
                  )
                  .attr("x2", xScale(Range[j][0] - zone * localInterval))
                  .attr(
                    "y2",
                    yScale(invertGraph === 0 ? Range[j][1] : -Range[j][1]) +
                      zone * heightOfRow
                  );
              }
            }

            // svg
            //   .append("circle")
            //   .attr("class", "testDots")
            //   .attr("cx", xScale(SVEDataSet[z][0] - zone * localInterval))
            //   .attr(
            //     "cy",
            //     yScale(
            //       invertGraph === 0 ? SVEDataSet[z][1] : SVEDataSet[z][1]
            //     ) +
            //       zone * heightOfRow
            //   )
            //   .attr("r", 2)
            //   .attr("stroke", "rgba(250, 0, 0,0.8)")
            //   .attr("fill", "rgba(250, 0, 0,0.1)");
          }

          // if (z < 1) {
          //    let SVEDataArray = getBeatDataInRangeEDF(
          //      SVEDataSet[z][0] - 1,
          //      SVEDataSet[z][0] + 1,
          //      0
          //    );
          //      console.log("data found");
          //     console.log(SVEDataArray);
          //   }
        }

        // 20 for sample, 40 for EDF
        //     let peakList = detectPeaks(IndexedDataSet[1], 200, dataRate);
        // let peakList: any = [];
        // console.log(peakList);
        //  d3.selectAll(".testDots").remove();
        // var i = 1;
        // zone = 1;
        // for (var j = 0; j < peakList.length; j++) {
        //   let QRS = detectQRS(IndexedDataSet[1], peakList[j]);
        //   console.log("peak : " + peakList[j]);
        //   console.log(QRS);
        //   console.log(
        //     IndexedDataSet[i][QRS.onset][0] +
        //       ":" +
        //       IndexedDataSet[i][peakList[j]][0] +
        //       ":" +
        //       IndexedDataSet[i][QRS.offset][0]
        //   );

        //   svg
        //     .append("circle")
        //     .attr("class", "testDots")
        //     .attr(
        //       "cx",
        //       xScale(IndexedDataSet[i][peakList[j]][0] - zone * localInterval)
        //     )
        //     .attr(
        //       "cy",
        //       yScale(
        //         invertGraph === 0
        //           ? IndexedDataSet[i][peakList[j]][1]
        //           : -IndexedDataSet[i][peakList[j]][1]
        //       ) +
        //         zone * heightOfRow
        //     )
        //     .attr("r", 2)
        //     .attr("stroke", "rgba(0, 0, 250,0.8)")
        //     .attr("fill", "rgba(0, 0, 250,0.1)");

        // svg
        //   .append("circle")
        //   .attr("class", "testDots")
        //   .attr(
        //     "cx",
        //     xScale(IndexedDataSet[i][QRS.onset][0] - zone * localInterval)
        //   )
        //   .attr(
        //     "cy",
        //     yScale(
        //       invertGraph === 0
        //         ? IndexedDataSet[i][QRS.onset][1]
        //         : -IndexedDataSet[i][QRS.onset][1]
        //     ) +
        //       zone * heightOfRow
        //   )
        //   .attr("r", 2)
        //   .attr("stroke", "rgba(250, 0, 0,0.8)")
        //   .attr("fill", "rgba(250, 0, 0,0.1)");

        // svg
        //   .append("circle")
        //   .attr("class", "testDots")
        //   .attr(
        //     "cx",
        //     xScale(IndexedDataSet[i][QRS.offset][0] - zone * localInterval)
        //   )
        //   .attr(
        //     "cy",
        //     yScale(
        //       invertGraph === 0
        //         ? IndexedDataSet[i][QRS.offset][1]
        //         : -IndexedDataSet[i][QRS.offset][1]
        //     ) +
        //       zone * heightOfRow
        //   )
        //   .attr("r", 2)
        //   .attr("stroke", "rgba(250, 0, 250,0.8)")
        //   .attr("fill", "rgba(250, 0, 250,0.1)");
        //        }

        //      const Fs = 250; // sampling rate
        //      const windowSize = 0.1; // window size for moving average filter in seconds
        //      const data: number[] = [
        /* ECG data goes here */
        //      ];
        /*
        for (var i = 0; i < IndexedDataSet.length; i++) {
          // Running this from second to second last - so that we can have the -1 and +1 index check
          for (var j = 1; j < IndexedDataSet[i].length - 1; j++) {
            data.push(IndexedDataSet[i][j][1]);
          }
        }

        const filteredData = movingAverage(data, Math.round(windowSize * Fs));
        const QRS = detectQRS(filteredData, Fs);
        const PT = detectPT(data, QRS);
        console.log("data length: " + data.length);
        console.log(QRS);
        console.log(PT);

        var overAllIndex = 0;
        var QRSIndex = 0;
        var PIndex = 0;
        var TIndex = 0;

        d3.selectAll(".testDots").remove();
        var fullString = "[";
        for (var i = 0; i < IndexedDataSet.length; i++) {
          zone = i;
          // Running this from second to second last - so that we can have the -1 and +1 index check
          for (var j = 1; j < IndexedDataSet[i].length - 1; j++) {
            // Check for simple peak
            if (QRSIndex < QRS.length) {
              if (overAllIndex === QRS[QRSIndex]) {
                // console.log(
                //   "detected at index :" +
                //     overAllIndex +
                //     ", time: " +
                //     IndexedDataSet[i][j][0] +
                //     ", value: " +
                //     IndexedDataSet[i][j][1]
                // );
                QRSIndex += 1;
                // Draw a Dot at the location
                //IndexedDataSet[i][j][1]
                if (true) {
                  svg
                    .append("circle")
                    .attr("class", "testDots")
                    .attr(
                      "cx",
                      xScale(IndexedDataSet[i][j][0] - zone * localInterval)
                    )
                    .attr(
                      "cy",
                      // yScale(
                      //   invertGraph === 0
                      //     ? IndexedDataSet[i][j][1]
                      //     : -IndexedDataSet[i][j][1]
                      // ) +
                      zone * heightOfRow + 50
                    )
                    .attr("r", 2)
                    .attr("stroke", "rgba(0, 0, 250,0.8)")
                    .attr("fill", "rgba(0, 0, 250,0.1)");
                }
              }
            }
            console.log("index: " + overAllIndex);
            if (PT.P[PIndex].end < overAllIndex) {
              PIndex += 1;
            }

            if (PIndex < PT.P.length) {
              if (overAllIndex === PT.P[PIndex].end) {
                PIndex += 1;
                // Draw a Dot at the location
                //IndexedDataSet[i][j][1]
                if (true) {
                  console.log(
                    "detected at index :" +
                      overAllIndex +
                      ", time: " +
                      IndexedDataSet[i][j][0] +
                      ", value: " +
                      IndexedDataSet[i][j][1]
                  );

                  svg
                    .append("circle")
                    .attr("class", "testDots")
                    .attr(
                      "cx",
                      xScale(IndexedDataSet[i][j][0] - zone * localInterval)
                    )
                    .attr(
                      "cy",
                      // yScale(
                      //   invertGraph === 0
                      //     ? IndexedDataSet[i][j][1]
                      //     : -IndexedDataSet[i][j][1]
                      // ) +
                      zone * heightOfRow + 60
                    )
                    .attr("r", 2)
                    .attr("stroke", "rgba(250, 0, 0,0.8)")
                    .attr("fill", "rgba(250, 0, 0,0.1)");
                }
              }
            }

            if (PT.T[TIndex].end < overAllIndex) {
              TIndex += 1;
            }

            if (TIndex < PT.T.length) {
              if (overAllIndex === PT.T[TIndex].end) {
                TIndex += 1;
                // Draw a Dot at the location
                //IndexedDataSet[i][j][1]
                if (true) {
                  console.log(
                    "detected at index :" +
                      overAllIndex +
                      ", time: " +
                      IndexedDataSet[i][j][0] +
                      ", value: " +
                      IndexedDataSet[i][j][1]
                  );

                  svg
                    .append("circle")
                    .attr("class", "testDots")
                    .attr(
                      "cx",
                      xScale(IndexedDataSet[i][j][0] - zone * localInterval)
                    )
                    .attr(
                      "cy",
                      // yScale(
                      //   invertGraph === 0
                      //     ? IndexedDataSet[i][j][1]
                      //     : -IndexedDataSet[i][j][1]
                      // ) +
                      zone * heightOfRow + 60
                    )
                    .attr("r", 2)
                    .attr("stroke", "rgba(250, 0, 250,0.8)")
                    .attr("fill", "rgba(250, 0, 250,0.1)");
                }
              }
            }

            overAllIndex += 1;
          }
        }
*/
        //        console.log("== new index list==");
        //   console.log(IndexedDataSet);

        /*        d3.selectAll(".testDots").remove();
        var fullString = "[";
        for (var i = 0; i < IndexedDataSet.length; i++) {
          zone = i;
          // Running this from second to second last - so that we can have the -1 and +1 index check
          for (var j = 1; j < IndexedDataSet[i].length - 1; j++) {
            // Check for simple peak
            if (
              IndexedDataSet[i][j][1] > IndexedDataSet[i][j - 1][1] &&
              IndexedDataSet[i][j][1] < IndexedDataSet[i][j + 1][1]
            ) {
              // Draw a Dot at the location
              //IndexedDataSet[i][j][1]
              if (IndexedDataSet[i][j][1] > 200) {
                svg
                  .append("circle")
                  .attr("class", "testDots")
                  .attr(
                    "cx",
                    xScale(IndexedDataSet[i][j][0] - zone * localInterval)
                  )
                  .attr(
                    "cy",
                    yScale(
                      invertGraph === 0
                        ? IndexedDataSet[i][j][1]
                        : -IndexedDataSet[i][j][1]
                    ) +
                      zone * heightOfRow
                  )
                  .attr("r", 2)
                  .attr("stroke", "rgba(0, 0, 250,0.8)")
                  .attr("fill", "rgba(0, 0, 250,0.1)");
              }
            }

            // fullString =
            //   fullString +
            //   "[" +
            //   IndexedDataSet[i][j][0] +
            //   "," +
            //   IndexedDataSet[i][j][1] +
            //   "],";
            //          console.log(IndexList[i][j][0] + "," + IndexList[i][j][1]);
          }
        }
        //console.log(fullString + "]");
*/
        //END of Test

        d3.selectAll(".mainViewRuler").remove();
        // Define the marker for the blue arrow head
        svg
          .append("defs")
          .append("marker")
          .attr("id", "rulerarrowhead")
          .attr("class", "mainViewRuler")
          .attr("viewBox", "0 -5 10 10")
          .attr("refX", 6)
          .attr("refY", 0)
          .attr("markerWidth", 4)
          .attr("markerHeight", 4)
          .attr("orient", "auto")
          .append("path")
          .attr("d", "M0,-4L12,0L0,4")
          .attr("fill", "blue");

        let bpmSections = [];
        let bpmPrevStartSeconds = GraphStartTimeRef.current;
        let bpmPrevStartX = 0;
        let bpmPrevStartZone = 0;
        // Draw a line below each zone and write Date/Time on right - Also the ruler if it is on
        for (let i = 0; i < IndexedDataSet.length; i++) {
          //console.log(IndexedDataSet[i]);
          zone = i;
          svg
            .append("line")
            .attr("class", "mainViewLine")
            .style("stroke", "#e0816e")
            .style("stroke-width", 1)
            .attr("x1", 0)
            .attr("y1", (zone + 1) * heightOfRow)
            .attr("x2", w)
            .attr("y2", (zone + 1) * heightOfRow);

          svg
            .append("text")
            .attr("class", "mainViewLine")
            .text(
              "D1 -" +
                moment(IndexedDataSet[i].date).format("YYYY-MM-DD") +
                ", " +
                Utility.ConvertSecondsIntoTimeFormat(IndexedDataSet[i][0][0])
            )
            .attr("text-anchor", "end")
            .attr("fill", "#AAAAAA")
            .style("font-weight", "semi-bold")
            .attr("font-size", "12px")
            .attr("x", w - 15)
            .attr("y", zone * heightOfRow + 24);

          if (IndexedDataSet[i].length > 1) {
            let pointsInThisZone = getRulerPoints(
              IndexedDataSet[i][0][0],
              IndexedDataSet[i][IndexedDataSet[i].length - 1][0],
              6
            );
            if (pointsInThisZone && pointsInThisZone.length > 0) {
              for (let j = 0; j < pointsInThisZone.length; j++) {
                let rulerPointX = xScale(
                  pointsInThisZone[j] - zone * localInterval
                );
                let rulerPointBaseY = (zone + 1) * heightOfRow;

                // Set up the bpm point
                let currentBPMSection = {
                  startSeconds: bpmPrevStartSeconds,
                  endSeconds: pointsInThisZone[j],
                  startX: bpmPrevStartX,
                  endX: rulerPointX,
                  startZone: bpmPrevStartZone,
                  endZone: zone,
                };

                // Push this point into the BPM sections array
                bpmSections.push(currentBPMSection);

                // Set up the start variables for next time
                bpmPrevStartSeconds = pointsInThisZone[j];
                bpmPrevStartX = rulerPointX;
                bpmPrevStartZone = zone;

                if (showRuler === 1) {
                  svg
                    .append("line")
                    .attr("class", "mainViewRuler")
                    .attr("x1", rulerPointX)
                    .attr("y1", rulerPointBaseY)
                    .attr("x2", rulerPointX)
                    .attr("y2", rulerPointBaseY - 12)
                    .attr("stroke", "blue")
                    .attr("stroke-width", 3)
                    .attr("marker-end", "url(#rulerarrowhead)");
                }
              }
            }
          }
        }

        // TODO: Push the last BPM section here if its needed

        // Check if there are any BPM sections, if there are, calculate their BPM and figure out where to draw them. Only if there is peaks data
        // if (bpmSections.length > 0 && IndexedPeaksDataSet.length > 0) {
        //   // Skipping the first section
        //   for (let i = 0; i < bpmSections.length; i++) {
        //     let bpmOfThisSection: any = getBPMAndBeats(
        //       bpmSections[i].startSeconds,
        //       bpmSections[i].endSeconds
        //     );
        //     if (bpmOfThisSection.Beats > 0) {
        //       if (bpmSections[i].startZone === bpmSections[i].endZone) {
        //         // Since both are in the same zone, we can show the BPM in this zone if there is space
        //         if (bpmSections[i].endX - bpmSections[i].startX > 25) {
        //           let middleX =
        //             (bpmSections[i].startX + bpmSections[i].endX) / 2;
        //           // Draw the BPM for this group of 6 seconds
        //           svg
        //             .append("text")
        //             .attr("class", "mainViewLine")
        //             .text(Math.round(bpmOfThisSection.BPM) + " bpm")
        //             .attr("text-anchor", "end")
        //             .attr("fill", "#555555")
        //             .style("font-weight", "bold")
        //             .attr("font-size", "13px")
        //             .attr("x", middleX - 15)
        //             .attr(
        //               "y",
        //               (bpmSections[i].startZone + 1) * heightOfRow - 18
        //             );
        //         }
        //       } else if (
        //         bpmSections[i].endZone ===
        //         bpmSections[i].startZone + 1
        //       ) {
        //         // Since the end zone is greater than start zone - next one to be precise, we check where to write the value
        //         let spaceInFirstRow = w - bpmSections[i].startX;
        //         let distanceToMiddleX =
        //           (spaceInFirstRow + bpmSections[i].endX) / 2;
        //         if (spaceInFirstRow > bpmSections[i].endX) {
        //           // This is true if there is more space in the first row
        //           // Draw the BPM for this group of 6 seconds
        //           svg
        //             .append("text")
        //             .attr("class", "mainViewLine")
        //             .text(Math.round(bpmOfThisSection.BPM) + " bpm")
        //             .attr("text-anchor", "end")
        //             .attr("fill", "#555555")
        //             .style("font-weight", "bold")
        //             .attr("font-size", "13px")
        //             .attr("x", bpmSections[i].startX + distanceToMiddleX - 15)
        //             .attr(
        //               "y",
        //               (bpmSections[i].startZone + 1) * heightOfRow - 18
        //             );
        //         } else {
        //           // Draw the BPM for this group of 6 seconds
        //           svg
        //             .append("text")
        //             .attr("class", "mainViewLine")
        //             .text(Math.round(bpmOfThisSection.BPM) + " bpm")
        //             .attr("text-anchor", "end")
        //             .attr("fill", "#555555")
        //             .style("font-weight", "bold")
        //             .attr("font-size", "13px")
        //             .attr("pointer-events", "none")
        //             .attr("x", bpmSections[i].endX - distanceToMiddleX - 15)
        //             .attr("y", (bpmSections[i].endZone + 1) * heightOfRow - 18);
        //         }
        //       }
        //     }
        //   }
        // }

        // Split View Area in Main grid
        d3.selectAll(".mainViewSplitBox").remove();
        if (BottomDiv.ECGSplitView === true) {
          // Draw a box around the selected area

          let localStartOfSplit = 0;
          if (startOfSplitBoxRef.current < GraphStartTimeRef.current) {
            localStartOfSplit = GraphStartTimeRef.current;
            setStartOfSplitBox(localStartOfSplit);
          } else {
            localStartOfSplit = startOfSplitBoxRef.current;
          }

          let splitZone = Math.floor(
            (localStartOfSplit - GraphStartTimeRef.current) / interval
          );
          let splitWithinZone = localStartOfSplit - splitZone * interval;
          let splitBoxStartX = xScale(splitWithinZone);
          let splitBoxEndX = xScale(splitWithinZone + secondsInSplit);
          //      console.log(localStartOfSplit + "," + splitBoxStartX + "," + splitBoxEndX);
          svg
            .append("rect")
            .attr("class", "mainViewSplitBox")
            .attr("x", splitBoxStartX + 1)
            .attr("y", splitZone * heightOfRow + 1)
            .attr("width", splitBoxEndX - splitBoxStartX - 2)
            .attr("height", heightOfRow - 2)
            .attr("stroke", "#75b8eb")
            .attr("stroke-width", "2")
            .attr("fill", "rgba(154, 201, 237, 0.4)")
            .style("cursor", "move")
            .on("mousedown", SplitBoxMouseDown);
        }

        d3.selectAll(".classificationText").remove();

        var classificationFontSize = "13px";

        if (localInterval > 50 && localInterval <= 100) {
          classificationFontSize = "12px";
        } else if (localInterval > 100) {
          classificationFontSize = "8px";
        }

        // if (BottomDiv.ECGSplitView === true) {
        //   classificationFontSize = "8px";
        // }

        // TODO: need to get actual value for this dataset
        let numberOfSamples = 125;

        if (
          IndexedPeaksDataSet &&
          IndexedPeaksDataSet.length > 0 &&
          startSignal === 1
        ) {
          // Remove current lines

          let lastPeakTime = 0;
          let lastPeakZone = 0;
          let lastPeakX = 0;

          for (let i = 0; i < IndexedPeaksDataSet.length; i++) {
            zone = i;

            for (let j = 0; j < IndexedPeaksDataSet[i].length; j++) {
              let thisPeakX = xScale(
                IndexedPeaksDataSet[i][j].value[0] - zone * localInterval
              );

              svg
                .append("text")
                .attr("class", "classificationText")
                .text(IndexedPeaksDataSet[i][j].classification)
                .attr("fill", "#666666")
                .attr("font-size", classificationFontSize)
                .attr("x", thisPeakX - 6)
                .attr("y", zone * heightOfRow + 12);

              if (lastPeakTime > 0) {
                let timeDifference =
                  IndexedPeaksDataSet[i][j].value[0] - lastPeakTime;
                let lastSectionBPM = Math.round(60 / timeDifference);
                let bpmX = (thisPeakX + lastPeakX) / 2;
                if (zone === lastPeakZone) {
                  svg
                    .append("text")
                    .attr("class", "classificationText")
                    .text(lastSectionBPM)
                    .attr("fill", "#888888")
                    .attr("font-size", "10px")
                    .attr("x", bpmX - 6)
                    .attr("y", zone * heightOfRow + 36);
                }
              }
              lastPeakTime = IndexedPeaksDataSet[i][j].value[0];
              lastPeakX = thisPeakX;
              lastPeakZone = zone;
            }
          }
        }

        // Clear existing bars
        d3.selectAll(".rythmBar").remove();
        //    console.log(interval + ' : local : ' + localInterval);

        // Loop through the Annotations data
        if (
          currentControlRef.current === CurrentTool.TagTool &&
          rythmStarted === false &&
          AllAnnotations.length > 0
        ) {
          let indexToShow = AllAnnotations.length - 1;

          if (
            selectedAnnotationIndex >= 0 &&
            setSelectedAnnotationIndex < AllAnnotations.length
          ) {
            indexToShow = selectedAnnotationIndex;
          }

          if (AllAnnotations[indexToShow].DataType === 2) {
            // Get Zone of Start
            let startTimeFromGraphStart =
              AllAnnotations[indexToShow].StartTime - GraphStartTimeRef.current;
            let startZone = Math.floor(startTimeFromGraphStart / localInterval);

            let startX =
              ((startTimeFromGraphStart - startZone * localInterval) /
                localInterval) *
              globalW;

            let startY = startZone * heightOfRow;

            let totalTimeToEnd =
              startTimeFromGraphStart + AllAnnotations[indexToShow].Duration;
            let endZone = Math.floor(totalTimeToEnd / localInterval);

            let endX =
              ((totalTimeToEnd - endZone * localInterval) / localInterval) *
              globalW;
            let endY = endZone * heightOfRow;

            drawRythmBar(
              getTagTextFromType(AllAnnotations[indexToShow].Type),
              startX,
              startY,
              endX,
              endY
            );
          }
        }

        // Draw the latest or selected caliper
        d3.selectAll(".savedCalipers").remove();
        if (
          currentControlRef.current === CurrentTool.Caliper &&
          dragStarted === false &&
          AllMeasurements.length > 0
        ) {
          let indexToShow = AllMeasurements.length - 1;

          if (selectedMeasurementIndex >= 0) {
            indexToShow = selectedMeasurementIndex;
          }

          // Get Zone of Start
          let startTimeFromGraphStart =
            AllMeasurements[indexToShow].StartTime - GraphStartTimeRef.current;
          let startZone = Math.floor(startTimeFromGraphStart / localInterval);

          let startX =
            ((startTimeFromGraphStart - startZone * localInterval) /
              localInterval) *
            globalW;

          let startY = heightOfRow / 2 + startZone * heightOfRow;

          let totalTimeToEnd =
            startTimeFromGraphStart + AllMeasurements[indexToShow].Duration;
          let endZone = Math.floor(totalTimeToEnd / localInterval);
          let endX =
            ((totalTimeToEnd - endZone * localInterval) / localInterval) *
            globalW;
          let endY = heightOfRow / 2 + endZone * heightOfRow;

          drawHorizontalCaliper(
            startX,
            startY,
            endX,
            endY,
            AllMeasurements[indexToShow].Duration,
            AllMeasurements[indexToShow].BPM,
            "savedCalipers"
          );
        }

        // Draw the Click Pointer Rect if needed
        d3.selectAll(".clickPointerRect").remove();
        // Draw the Click Rect if needed
        d3.selectAll(".clickRect").remove();

        if (lastClick === AppArea.MainGraph && clickPointerStart > 0) {
          let startTimeFromGraphStart =
            clickPointerStart - GraphStartTimeRef.current;
          let startZone = Math.floor(startTimeFromGraphStart / localInterval);

          let startX =
            ((startTimeFromGraphStart - startZone * localInterval) /
              localInterval) *
            globalW;

          let startY = startZone * heightOfRow;

          svg
            .append("rect")
            .attr("class", "clickPointerRect")
            .attr("x", startX - 1)
            .attr("y", startY)
            .attr("width", 2)
            .attr("height", heightOfRow)
            .attr("fill", "rgba(70, 130, 180,0.8)");
        } else if (clickRectStart > 0) {
          let startTimeFromGraphStart =
            clickRectStart - GraphStartTimeRef.current;
          let startZone = Math.floor(startTimeFromGraphStart / localInterval);

          let startX =
            ((startTimeFromGraphStart - startZone * localInterval) /
              localInterval) *
            globalW;

          let startY = startZone * heightOfRow;

          svg
            .append("rect")
            .attr("class", "clickRect")
            .attr("x", startX - 1)
            .attr("y", startY)
            .attr("width", 2)
            .attr("height", heightOfRow)
            .attr("fill", "rgba(70, 130, 180,0.8)");
        }

        if (!showScroll) {
          setShowScroll(true);
        }
      }
    }
  }, [
    IndexedDataSet,
    showGrid,
    showRuler,
    mmvValue,
    invertGraph,
    AllAnnotations,
    AllMeasurements,
    BottomDiv,
    startOfSplitBox,
    dragStarted,
    currentControl,
    dimensionsDone,
    clickPointerStart,
    props.selectedView,
    props.showTestSymbols,
  ]);

  // Start of the QRS Detection Alogorithm -

  function detectPeaks(
    data: number[][],
    threshold: number,
    windowSize: number
  ): number[] {
    const peaks: number[] = [];

    for (let i = windowSize; i < data.length - windowSize; i++) {
      const segment = data.slice(i - windowSize, i + windowSize + 1);
      const max = Math.max(...segment.map((obj) => obj[1]));

      if (max >= threshold && max === data[i][1]) {
        peaks.push(i);
      }
    }

    return peaks;
  }

  interface QRSComplex {
    onset: number;
    offset: number;
  }

  function detectQRS(signal: number[][], rPeakIndex: number): QRSComplex {
    const searchWindowDuration = 1000; // in milliseconds
    const sampleRate = 20; // in milliseconds
    const searchWindowWidth =
      Math.round(searchWindowDuration * sampleRate) / 1000;

    console.log("searchWindowWidth : " + searchWindowWidth);
    const searchWindowStart = Math.max(
      0,
      rPeakIndex - Math.floor(searchWindowWidth / 2)
    );
    const searchWindowEnd = Math.min(
      signal.length - 1,
      rPeakIndex + Math.ceil(searchWindowWidth / 2)
    );

    const searchWindow = signal.slice(searchWindowStart, searchWindowEnd + 1);
    console.log(searchWindow);
    // Step 2: Calculate the derivative of the ECG signal within the search window
    const derivative = [];
    for (let i = 0; i < searchWindow.length - 1; i++) {
      derivative[i] = searchWindow[i + 1][1] - searchWindow[i][1];
    }

    console.log(derivative);

    // Step 3: Apply a threshold to detect QRS complexes

    // Calculate the mean
    const sum = derivative.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const mean = sum / derivative.length;

    // Calculate the sum of (x - mean)^2
    const squaredDifferencesSum = derivative.reduce(
      (accumulator, currentValue) => {
        const difference = currentValue - mean;
        return accumulator + difference * difference;
      },
      0
    );

    // Calculate the standard deviation
    const stdDev = Math.sqrt(squaredDifferencesSum / derivative.length - 1);

    // const mean =
    //   derivative.reduce((acc, val) => acc + val, 0) / derivative.length;

    console.log("mean: " + mean);

    // const stdDev = Math.sqrt(
    //   derivative.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) /
    //     (derivative.length - 1)
    // );
    console.log("stdDev: " + stdDev);

    const threshold = 0.5 * stdDev;
    const peakIndices = [];
    let isPeak = false;
    let peakStart = 0;
    let peakEnd = 0;

    for (let i = 0; i < derivative.length; i++) {
      if (!isPeak && derivative[i] >= threshold) {
        isPeak = true;
        peakStart = i;
      } else if (isPeak && derivative[i] < threshold) {
        isPeak = false;
        peakEnd = i - 1;
        const peakIndex =
          searchWindowStart +
          peakStart +
          findMaxIndex(
            signal.slice(
              searchWindowStart + peakStart,
              searchWindowStart + peakEnd
            )
          );
        if (
          peakIndex > rPeakIndex &&
          peakIndex - rPeakIndex < Math.floor(searchWindowWidth / 2)
        ) {
          peakIndices.push(peakIndex);
        }
      }
    }

    // Step 4: Choose the QRS candidate with the highest amplitude
    let qrsIndex = -1;
    let qrsAmplitude = -Infinity;
    for (const index of peakIndices) {
      const amplitude = Math.abs(signal[index][1] - signal[rPeakIndex][1]);
      if (amplitude > qrsAmplitude) {
        qrsIndex = index;
        qrsAmplitude = amplitude;
      }
    }

    if (qrsIndex < 0) {
      // alert("something wrong");
      qrsIndex = 0;
    }

    // Step 5: Set the QRS onset as the first point of the QRS complex
    let onset = qrsIndex;
    while (
      onset > 0 &&
      Math.abs(signal[onset][1] - signal[rPeakIndex][1]) >
        Math.abs(qrsAmplitude) * 0.05
    ) {
      onset--;
    }

    // Step 6: Set the QRS offset as the last point of the QRS complex
    let offset = qrsIndex;
    while (
      offset < signal.length - 1 &&
      Math.abs(signal[offset][1] - signal[rPeakIndex][1]) >
        Math.abs(qrsAmplitude) * 0.05
    ) {
      offset++;
    }

    // Step 7: Return the QRS onset and offset
    return { onset, offset };
  }

  function findMaxIndex(arr: number[][]): number {
    let maxIndex: number = 0;
    let maxValue: any = -Infinity;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][1] > maxValue) {
        maxValue = arr[i][1];
        maxIndex = i;
      }
    }
    return maxIndex;
  }

  /*
  function detectECGFeatures(data) {
    // initialize variables
    var Fs = 250; // sampling rate
    var windowSize = 0.12; // window size for QRS detection in seconds
    var LPFcutoff = 15; // low-pass filter cutoff frequency
    var HPFcutoff = 5; // high-pass filter cutoff frequency
  
    // apply bandpass filter
    var filteredData = bandpassFilter(data, LPFcutoff, HPFcutoff, Fs);
  
    // apply derivative filter
    var derivativeData = derivativeFilter(filteredData);
  
    // apply squaring function
    var squaredData = squareFilter(derivativeData);
  
    // apply moving average filter
    var MAData = movingAverageFilter(squaredData, Math.round(windowSize * Fs));
  
    // detect QRS complex
    var QRS = detectQRS(MAData, Fs);
  
    // detect P and T waves
    var PT = detectPT(data, QRS);
  
    return {
      QRS: QRS,
      P: PT.P,
      T: PT.T
    };
  }
  
  // bandpass filter function
  function bandpassFilter(data, LPFcutoff, HPFcutoff, Fs) {
    var LPF = lowpassFilter(data, LPFcutoff, Fs);
    var HPF = highpassFilter(LPF, HPFcutoff, Fs);
    return HPF;
  }
  
  // lowpass filter function
  function lowpassFilter(data, cutoff, Fs) {
    var RC = 1.0 / (2.0 * Math.PI * cutoff);
    var dt = 1.0 / Fs;
    var alpha = RC / (RC + dt);
    var filteredData = [];
    var prev = 0;
    for (var i = 0; i < data.length; i++) {
      var current = alpha * data[i] + (1 - alpha) * prev;
      filteredData.push(current);
      prev = current;
    }
    return filteredData;
  }
  
  // highpass filter function
  function highpassFilter(data, cutoff, Fs) {
    var RC = 1.0 / (2.0 * Math.PI * cutoff);
    var dt = 1.0 / Fs;
    var alpha = RC / (RC + dt);
    var filteredData = [];
    var prev = 0;
    for (var i = 0; i < data.length; i++) {
      var current = alpha * (prev + data[i] - data[i - 1]);
      filteredData.push(current);
      prev = current;
    }
    return filteredData;
  }
  
  // derivative filter function
  function derivativeFilter(data) {
    var filteredData = [];
    for (var i = 0; i < data.length - 1; i++) {
      var current = data[i + 1] - data[i];
      filteredData.push(current);
    }
    return filteredData;
  }
  
  // square filter function
  function squareFilter(data) {
    var filteredData = [];
    for (var i = 0; i < data.length; i++) {
      var current = data[i] * data[i];
      filteredData.push(current);
    }
    return filteredData;
  }
  
  // moving average filter function
  function movingAverageFilter(data, windowSize) {
    var filteredData = [];
    var sum = 0;
    for (var i = 0; i < data.length; i++) {
  sum += data[i];
  if (i >= windowSize) {
  sum -= data[i - windowSize];
  filteredData.push(sum / windowSize);
  } else {
  filteredData.push(sum / (i + 1));
  }
  }
  return filteredData;
  }
  // QRS detection function using Pan-Tompkins algorithm
  function detectQRS(data, Fs) {
  var delay = 0.15; // delay for the filter
  var threshold = 0.6; // threshold for QRS detection
  var refractoryPeriod = 0.2; // refractory period in seconds
  var delaySamples = Math.round(delay * Fs);
  var filteredData = [];
  var prev = 0;
  for (var i = 0; i < data.length; i++) {
  var current = data[i] - prev;
  filteredData.push(current);
  prev = data[i];
  }
  var thresholdValue = calculateThreshold(filteredData, threshold);
  var QRS = [];
  var lastQRS = -refractoryPeriod * Fs;
  for (var i = delaySamples; i < filteredData.length; i++) {
  if (filteredData[i] > thresholdValue && i - lastQRS > refractoryPeriod * Fs) {
  QRS.push(i);
  lastQRS = i;
  }
  }
  return QRS;
  }
  // threshold calculation function
  function calculateThreshold(data, threshold) {
  var sortedData = data.slice().sort(function(a, b) { return a - b; });
  var index = Math.round(sortedData.length * threshold);
  return sortedData[index];
  }
  // peak detection function for P and T waves
  function detectPT(data, QRS) {
  var Fs = 250; // sampling rate
  var windowSize = 0.2; // window size for peak detection in seconds
  var PT = { P: [], T: [] };
  var windowSamples = Math.round(windowSize * Fs);
  for (var i = 0; i < QRS.length; i++) {
  var QRSstart = QRS[i] - Math.round(0.05 * Fs);
  var QRSend = QRS[i] + Math.round(0.2 * Fs);
  var windowStart = QRS[i] - Math.round(0.4 * Fs);
  var windowEnd = QRS[i] + Math.round(0.4 * Fs);
  if (windowStart < 0) {
  windowStart = 0;
  }
  if (windowEnd > data.length) {
  windowEnd = data.length;
  }
  var windowData = data.slice(windowStart, windowEnd);
  var windowMax = Math.max(...windowData);
  var windowMaxIndex = windowData.indexOf(windowMax) + windowStart;
  if (windowMaxIndex < QRSstart) {
  var Pstart = windowMaxIndex - Math.round(0.1 * Fs);
  var Pend = windowMaxIndex + Math.round(0.05 * Fs);
  PT.P.push({ start: Pstart, end: Pend });
  } else if (windowMaxIndex > QRSend) {
  var Tstart = windowMaxIndex - Math.round(0.05 * Fs);
  var Tend = windowMaxIndex + Math.round(0.2 * Fs);
  PT.T.push({ start: Tstart, end: Tend });
  }
  }
  return PT;
  }
  
*/

  // END of the QRS Detection Algorithm

  // Start of v1.5
  /*
  interface PTWave {
    start: number;
    end: number;
  }

  interface PT {
    P: PTWave[];
    T: PTWave[];
  }

  function movingAverage(data: number[], windowSize: number): number[] {
    const filteredData: number[] = [];
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i];
      if (i >= windowSize) {
        sum -= data[i - windowSize];
        filteredData.push(sum / windowSize);
      } else {
        filteredData.push(sum / (i + 1));
      }
    }
    return filteredData;
  }

  function detectQRS(data: number[], Fs: number): number[] {
    const delay = 0.15; // delay for the filter
    const threshold = 0.6; // threshold for QRS detection
    const refractoryPeriod = 0.2; // refractory period in seconds
    const delaySamples = Math.round(delay * Fs);
    const filteredData: number[] = [];
    let prev = 0;
    for (let i = 0; i < data.length; i++) {
      const current = data[i] - prev;
      filteredData.push(current);
      prev = data[i];
    }
    const thresholdValue = calculateThreshold(filteredData, threshold);
    const QRS: number[] = [];
    let lastQRS = -refractoryPeriod * Fs;
    for (let i = delaySamples; i < filteredData.length; i++) {
      if (
        filteredData[i] > thresholdValue &&
        i - lastQRS > refractoryPeriod * Fs
      ) {
        QRS.push(i);
        lastQRS = i;
      }
    }
    return QRS;
  }

  function calculateThreshold(data: number[], threshold: number): number {
    const sortedData = data.slice().sort((a, b) => a - b);
    const index = Math.round(sortedData.length * threshold);
    return sortedData[index];
  }

  function detectPT(data: number[], QRS: number[]): PT {
    const Fs = 250; // sampling rate
    const windowSize = 0.2; // window size for peak detection in seconds
    const PT: PT = { P: [], T: [] };
    const windowSamples = Math.round(windowSize * Fs);
    for (let i = 0; i < QRS.length; i++) {
      const QRSstart = QRS[i] - Math.round(0.05 * Fs);
      const QRSend = QRS[i] + Math.round(0.2 * Fs);
      let windowStart = QRS[i] - Math.round(0.4 * Fs);
      let windowEnd = QRS[i] + Math.round(0.4 * Fs);
      if (windowStart < 0) {
        windowStart = 0;
      }
      if (windowEnd > data.length) {
        windowEnd = data.length;
      }
      const windowData = data.slice(windowStart, windowEnd);
      const windowMax = Math.max(...windowData);
      const windowMaxIndex = windowData.indexOf(windowMax) + windowStart;
      if (windowMaxIndex < QRSstart) {
        const Pstart = windowMaxIndex - Math.round(0.1 * Fs);
        const Pend = windowMaxIndex + Math.round(0.05 * Fs);
        PT.P.push({ start: Pstart, end: Pend });
      } else if (windowMaxIndex > QRSend) {
        const Tstart = windowMaxIndex + Math.round(0.05 * Fs);
        const Tall = windowData.slice(
          Tstart - windowStart,
          Tstart - windowStart + windowSamples
        );
        const Tmax = Math.max(...Tall);
        const Tend = Tall.indexOf(Tmax) + Tstart;
        PT.T.push({ start: Tstart, end: Tend });
      } else {
        const Pstart = windowMaxIndex - Math.round(0.1 * Fs);
        const Pend = windowMaxIndex + Math.round(0.05 * Fs);
        const Tstart = windowMaxIndex + Math.round(0.05 * Fs);
        const Tall = windowData.slice(
          Tstart - windowStart,
          Tstart - windowStart + windowSamples
        );
        const Tmax = Math.max(...Tall);
        const Tend = Tall.indexOf(Tmax) + Tstart;
        PT.P.push({ start: Pstart, end: Pend });
        PT.T.push({ start: Tstart, end: Tend });
      }
    }
    return PT;
  }
*/
  // end of v1.5

  // Start of v2
  /*
  interface PTWave {
    start: number;
    end: number;
  }

  interface PT {
    P: PTWave[];
    T: PTWave[];
  }

  interface TimeValuePair {
    time: number;
    value: number;
  }

  interface RangeWithTime {
    startTime: number;
    startValue: number;
    endTime: number;
    endValue: number;
  }

  function movingAverage(data: number[][], windowSize: number): TimeValuePair[] {
    const filteredData: TimeValuePair[] = [];
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i][1];
      if (i >= windowSize) {
        sum -= data[i - windowSize][1];
        
        const thisTime = data[i][0];
        const thisValue = sum / windowSize;
        filteredData.push({ time: thisTime, value: thisValue });

 //       filteredData.push(sum / windowSize);
      } else {
        
        const thisTime = data[i][0];
        const thisValue = sum / (i + 1);
        filteredData.push({ time: thisTime, value: thisValue });
        
   //     filteredData.push(sum / (i + 1));


      }
    }
    return filteredData;
  }

  function detectQRS(data: TimeValuePair[], Fs: number): TimeValuePair[] {
    const delay = 0.15; // delay for the filter
    const threshold = 0.6; // threshold for QRS detection
    const refractoryPeriod = 0.2; // refractory period in seconds
    const delaySamples = Math.round(delay * Fs);
    const filteredData: TimeValuePair[] = [];
    let prev = ({ time: 0, value: 0 });
    for (let i = 0; i < data.length; i++) {
      const current = data[i].value - prev.value;
      filteredData.push({ time: data[i].time, value: current });
      prev = data[i];
    }
    const thresholdValue = calculateThreshold(filteredData, threshold);
    const QRS: number[] = [];
    let lastQRS = -refractoryPeriod * Fs;
    for (let i = delaySamples; i < filteredData.length; i++) {
      if (
        filteredData[i] > thresholdValue &&
        i - lastQRS > refractoryPeriod * Fs
      ) {
        QRS.push(i);
        lastQRS = i;
      }
    }
    return QRS;
  }

  function calculateThreshold(data: TimeValuePair[], threshold: number): number {
    const sortedData = data.slice().sort((a, b) => a - b);
    const index = Math.round(sortedData.length * threshold);
    return sortedData[index];
  }

  function detectPT(data: number[], QRS: number[]): PT {
    const Fs = 250; // sampling rate
    const windowSize = 0.2; // window size for peak detection in seconds
    const PT: PT = { P: [], T: [] };
    const windowSamples = Math.round(windowSize * Fs);
    for (let i = 0; i < QRS.length; i++) {
      const QRSstart = QRS[i] - Math.round(0.05 * Fs);
      const QRSend = QRS[i] + Math.round(0.2 * Fs);
      let windowStart = QRS[i] - Math.round(0.4 * Fs);
      let windowEnd = QRS[i] + Math.round(0.4 * Fs);
      if (windowStart < 0) {
        windowStart = 0;
      }
      if (windowEnd > data.length) {
        windowEnd = data.length;
      }
      const windowData = data.slice(windowStart, windowEnd);
      const windowMax = Math.max(...windowData);
      const windowMaxIndex = windowData.indexOf(windowMax) + windowStart;
      if (windowMaxIndex < QRSstart) {
        const Pstart = windowMaxIndex - Math.round(0.1 * Fs);
        const Pend = windowMaxIndex + Math.round(0.05 * Fs);
        PT.P.push({ start: Pstart, end: Pend });
      } else if (windowMaxIndex > QRSend) {
        const Tstart = windowMaxIndex + Math.round(0.05 * Fs);
        const Tall = windowData.slice(
          Tstart - windowStart,
          Tstart - windowStart + windowSamples
        );
        const Tmax = Math.max(...Tall);
        const Tend = Tall.indexOf(Tmax) + Tstart;
        PT.T.push({ start: Tstart, end: Tend });
      } else {
        const Pstart = windowMaxIndex - Math.round(0.1 * Fs);
        const Pend = windowMaxIndex + Math.round(0.05 * Fs);
        const Tstart = windowMaxIndex + Math.round(0.05 * Fs);
        const Tall = windowData.slice(
          Tstart - windowStart,
          Tstart - windowStart + windowSamples
        );
        const Tmax = Math.max(...Tall);
        const Tend = Tall.indexOf(Tmax) + Tstart;
        PT.P.push({ start: Pstart, end: Pend });
        PT.T.push({ start: Tstart, end: Tend });
      }
    }
    return PT;
  }
*/
  // End of V2

  const ScrollMouseDown = (e: any) => {
    //   console.log("Scroll Mouse Down");
    setScrollDragStarted(true);
  };

  const ScrollMouseUp = (e: any) => {
    //      console.log("Scroll Mouse Up");
    setScrollDragStarted(false);
  };

  const ScrollMouseMove = (e: any) => {
    //     console.log("Scroll Mouse Move");
    if (scrollDragStartedRef.current) {
      let mouseY = getScrollSVGy(e.pageY);

      let newMouseY = mouseY - 10;
      if (newMouseY < 0) {
        newMouseY = 0;
      }

      let localScrollHeight = scrollHeightRef.current;
      // Scroll X is always 0, Y is based on where the person has dragged it OR used the Next/Prev row buttons
      if (newMouseY + scrollBoxHeight > localScrollHeight) {
        newMouseY = localScrollHeight - scrollBoxHeight;
      }

      let localLineCount = LineCountRef.current;
      let localInterval = intervalRef.current;
      // Since scroll has changed, we can update the Start position of the graph
      let newRowNumber = Math.floor(
        (newMouseY / (localScrollHeight - scrollBoxHeight)) *
          (LoadedDataRowsRef.current - localLineCount)
      );

      let newStartTime = newRowNumber * localInterval;
      setGraphStartTime(newStartTime);
      setStartOfSplitBox(newStartTime);
      MakeIndexedList(newStartTime, localInterval, localLineCount);

      setScrollY(newMouseY);
      setUpdateScroll(true);
    }
  };

  // This is for drawing the scroll SVG
  useEffect(() => {
    if (props.selectedView === AppContext.view.Analysis) {
      var scrollHeight = document.getElementById("ScrollBar")?.clientHeight;
      let h = 600;
      if (scrollHeight) {
        h = scrollHeight - 103;
      }

      setScrollHeight(h);

      let boundingRect: any = document
        .getElementById("scrollSVG")
        ?.getBoundingClientRect();
      setsvgScrollTop(boundingRect.top);

      d3.selectAll(".scrollBox").remove();
    }
    setscrollDimensionsDone(true);
  }, [scrollY, showScroll, props.selectedView]);

  // This is for drawing the scroll SVG
  useEffect(() => {
    if (scrollDimensionsDoneRef.current && updateScrollRef.current) {
      setUpdateScroll(false);

      let w = 25;
      let localHeight = scrollHeightRef.current;

      if (
        IndexedDataSet &&
        IndexedDataSet.length > 0 &&
        startSignal === 1 &&
        showScroll
      ) {
        svgScrollView = d3
          .select(svgScrollRef.current)
          .attr("width", w)
          .attr("height", localHeight)
          .style("overflow", "hidden")
          .on("mousedown", ScrollMouseDown)
          .on("mouseup", ScrollMouseUp)
          .on("mousemove", ScrollMouseMove);

        d3.selectAll(".scrollBox").remove();

        let localScrollY = scrollYRef.current;

        svgScrollView
          .append("rect")
          .attr("class", "scrollBox")
          .attr("x", 0)
          .attr("y", localScrollY)
          .attr("width", w)
          .attr("height", scrollBoxHeight)
          .attr("fill", "#BBBBBB");
      }
    }
  }, [scrollDimensionsDone, scrollY, showScroll]);

  // // This is for drawing the SplitView
  // useEffect(() => {
  //   if (BottomDiv.ECGSplitView === true) {
  //     var BottomDivWidth =
  //       document.getElementById("MainBottomDiv")?.clientWidth;
  //     let w = globalW;
  //     let heighOfSplitRow = 200;
  //     if (BottomDivWidth) {
  //       w = BottomDivWidth;
  //     }

  //     let localIntervalSplitView = w / (mmsValueSplitView * 5);
  //     setintervalSplitView(localIntervalSplitView);

  //     svgSplitView = d3
  //       .select(svgSplitViewRef.current)
  //       .attr("width", globalW)
  //       .attr("height", heighOfSplitRow)
  //       .style("overflow", "hidden");

  //     let boundingRect: any = document
  //       .getElementById("splitViewSVG")
  //       ?.getBoundingClientRect();
  //     svgSplitViewLeft = boundingRect.left;
  //     svgSplitViewTop = boundingRect.top;

  //     // setting the scaling
  //     xScaleSplitView = d3
  //       .scaleLinear()
  //       .domain([
  //         startOfSplitBoxRef.current,
  //         startOfSplitBoxRef.current + secondsInSplit,
  //       ])
  //       .range([0, w]);

  //     let localYRange = 30000 / mmvValueSplitView;

  //     yScaleSplitView = d3
  //       .scaleLinear()
  //       .domain([-localYRange, localYRange])
  //       .range([heighOfSplitRow, 0]);

  //     d3.selectAll(".splitViewLine").remove();

  //     if (IndexedDataSet && IndexedDataSet.length > 0 && startSignal === 1) {
  //       d3.selectAll(".splitViewGrid").remove();

  //       if (showGridSplitView === 1) {
  //         // Define the pattern for the image
  //         const pattern = svgSplitView
  //           .append("defs")
  //           .append("pattern")
  //           .attr("class", "splitViewGrid")
  //           .attr("id", "image-pattern")
  //           .attr("x", 0)
  //           .attr("y", 0)
  //           .attr("width", heighOfSplitRow * 2)
  //           .attr("height", heighOfSplitRow)
  //           .attr("patternUnits", "userSpaceOnUse")
  //           .append("image")
  //           .attr("xlink:href", "/images/Grid3.png")
  //           .attr("width", heighOfSplitRow * 2)
  //           .attr("height", heighOfSplitRow);

  //         // Append a rectangle element with the image pattern
  //         svgSplitView
  //           .append("rect")
  //           .attr("class", "splitViewGrid")
  //           .attr("x", 0)
  //           .attr("y", 0)
  //           .attr("width", w)
  //           .attr("height", h)
  //           .attr("fill", "url(#image-pattern)");
  //       }

  //       let startOfSplit = getBeatBeforeFromIndexedSet(
  //         startOfSplitBoxRef.current
  //       );
  //       let endOfSplit = getBeatBeforeFromIndexedSet(
  //         startOfSplitBoxRef.current + secondsInSplit
  //       );

  //       let prevI = -1;
  //       let prevJ = -1;
  //       for (let i = startOfSplit.i; i <= endOfSplit.i; i++) {
  //         for (let j = startOfSplit.j; j <= endOfSplit.j; j++) {
  //           if (prevI >= 0 && prevJ >= 0) {
  //             svgSplitView
  //               .append("line")
  //               .attr("class", "splitViewLine")
  //               .style("stroke", "green")
  //               .attr("x1", xScaleSplitView(IndexedDataSet[prevI][prevJ][0]))
  //               .attr("y1", yScaleSplitView(IndexedDataSet[prevI][prevJ][1]))
  //               .attr("x2", xScaleSplitView(IndexedDataSet[i][j][0]))
  //               .attr("y2", yScaleSplitView(IndexedDataSet[i][j][1]));
  //           }
  //           prevI = i;
  //           prevJ = j;
  //         }
  //       }
  //     }
  //   }
  // }, [
  //   IndexedDataSet,
  //   showGridSplitView,
  //   showRuler,
  //   mmvValue,
  //   invertGraph,
  //   AllAnnotations,
  //   BottomDiv,
  //   startOfSplitBox,
  // ]);

  function SetRythmAnnotation(e: any, type: any) {
    setRythmAnnotationObj((prevState: any) => ({
      ...prevState,
      Pause: false,
      AVB: false,
      AF: false,
      SVT: false,
      Noise: false,
      AT: false,
      Normal: false,
    }));

    RythmAnnotationObj[e.target.name] = e.target.checked;

    setRythmAnnotationObj((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));

    SetType(type);
  }

  function SetMCTEventRythmAnnotation(e: any) {
    SetMCTEvent(e.target.checked);
  }

  function SetReportRythmAnnotation(e: any) {
    SetReport(e.target.checked);
  }

  function SetReportRythmAnnotationSubValue(Id: any) {
    if (Report === true) {
      SetReportOption(Id);
    }
  }

  function getTagTextFromType(tag: any) {
    let tagText = "N/A";
    if (tag === 1) {
      tagText = "AF";
    } else if (tag === 2) {
      tagText = "Pause";
    } else if (tag === 3) {
      tagText = "Noise";
    } else if (tag === 4) {
      tagText = "AVB";
    } else if (tag === 5) {
      tagText = "AT";
    } else if (tag === 6) {
      tagText = "SVT";
    } else if (tag === 7) {
      tagText = "VE";
    } else if (tag === 8) {
      tagText = "SVE";
    } else if (tag === 9) {
      tagText = "Normal";
    }
    return tagText;
  }

  function SaveRythmAnnotation() {
    setShowRhythmPopup(false);
    d3.selectAll(".rythmMarkers").remove();
    d3.selectAll(".rythmMarkersSplitView").remove();
    PushAnnotationInArray(
      Type,
      Report,
      ReportOption,
      MCTEvent,
      tagStartX,
      tagStartY,
      tagEndX,
      tagEndY
    );

    setRythmAnnotationObj({
      Pause: false,
      AVB: false,
      AF: false,
      SVT: false,
      Noise: false,
      AT: false,
      Normal: false,
    });
    SetType(0);
    SetMCTEvent(false);
    SetReport(false);
    SetReportOption(0);

    //   drawRythmBar(tagText, tagStartX, tagStartY, tagEndX, tagEndY);
    //    console.log(RythmAnnotationObj);
  }

  function getRulerPoints(start: any, end: any, NumberOfSeconds: any) {
    // First we round the start up to the nearest second and end down to the nearest second
    let roundedStart = Math.ceil(start);
    let roundedEnd = Math.floor(end);
    let rulerPoints = [];

    for (let i = roundedStart; i <= roundedEnd; i++) {
      if (i % NumberOfSeconds === 0) {
        rulerPoints.push(i);
      }
    }
    return rulerPoints;
  }

  function GenerateUniqueGUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function PushAnnotationInArray(
    Type: any,
    Report: any,
    ReportOption: any,
    MCTEvent: any,
    StartX: any,
    StartY: any,
    EndX: any,
    EndY: any
  ) {
    // Get the difference between Start and End of annotation and current start of visible data
    let StartTimeDifference =
      timeDifferenceFixedY(0, 0, StartX, StartY) + GraphStartTimeRef.current;
    let EndTimeDifference =
      timeDifferenceFixedY(0, 0, EndX, EndY) + GraphStartTimeRef.current;
    let DurationDifference = timeDifferenceFixedY(StartX, StartY, EndX, EndY);

    var l_oBPM = getBPMAndBeats(StartTimeDifference, EndTimeDifference) as any;

    let Annotationobject = new Annotation(
      GenerateUniqueGUID(),
      2,
      Type,
      SelectedDataSet[0].date,
      StartTimeDifference,
      DurationDifference,
      l_oBPM.Beats,
      Math.ceil(l_oBPM.BPM),
      Report,
      ReportOption,
      MCTEvent
    );

    // Set the annotations in the range
    setAnnotationPeaksInRange(StartTimeDifference, EndTimeDifference, Type);

    setAllAnnotations([...AllAnnotations, Annotationobject]);

    // TODO: Check of this is needed
    MakeIndexedList(
      GraphStartTimeRef.current,
      intervalRef.current,
      LineCountRef.current
    );
  }

  function getCharacterForClassification(ClassificationType: any) {
    let Classification = "";

    // const AnnotationTagType = {
    //   AF: 1,
    //   Pause: 2,
    //   Noise: 3,
    //   AVB: 4,
    //   AT: 5,
    //   SVT: 6,
    //   VE: 7,
    //   SVE: 8,
    // };

    if (ClassificationType === AnnotationTagType.None) {
      Classification = "";
    } else if (ClassificationType === AnnotationTagType.AF) {
      Classification = "A";
    } else if (ClassificationType === AnnotationTagType.Pause) {
      Classification = "P";
    } else if (ClassificationType === AnnotationTagType.Noise) {
      Classification = "AT";
    } else if (ClassificationType === AnnotationTagType.AVB) {
      Classification = "A";
    } else if (ClassificationType === AnnotationTagType.AT) {
      Classification = "A";
    } else if (ClassificationType === AnnotationTagType.SVT) {
      Classification = "S";
    } else if (ClassificationType === AnnotationTagType.VE) {
      Classification = "V";
    } else if (ClassificationType === AnnotationTagType.SVE) {
      Classification = "S";
    } else if (ClassificationType === AnnotationTagType.Normal) {
      Classification = "N";
    } else {
      Classification = "";
    }
    return Classification;
  }

  function setAnnotationPeaksInRange(
    StartTime: any,
    EndTime: any,
    ClassificationType: any
  ) {
    // Get the starting Peak and ending Peak
    let startPeakIndex = getNearestPeak(StartTime, 1);
    let endPeakIndex = getNearestPeak(EndTime, 2);

    let Classification = getCharacterForClassification(ClassificationType);

    // Loop through the peaks and update all of them
    for (let i = startPeakIndex.i; i <= endPeakIndex.i; i++) {
      for (let j = 0; j < SelectedDataSetAllRef.current[i].peaks.length; j++) {
        if (
          i > startPeakIndex.i ||
          (i === startPeakIndex.i && j >= startPeakIndex.j)
        ) {
          if (
            i < endPeakIndex.i ||
            (i === endPeakIndex.i && j <= endPeakIndex.j)
          ) {
            SelectedDataSetAll[i].peaks[j].classification = Classification;
          }
        }
      }
    }
  }

  function revertAnnotationPeaksInRange(StartTime: any, EndTime: any) {
    // Get the starting Peak and ending Peak
    let startPeakIndex = getNearestPeak(StartTime, 1);
    let endPeakIndex = getNearestPeak(EndTime, 2);

    // Loop through the peaks and update all of them
    for (let i = startPeakIndex.i; i <= endPeakIndex.i; i++) {
      for (let j = 0; j < SelectedDataSetAllRef.current[i].peaks.length; j++) {
        if (
          i > startPeakIndex.i ||
          (i === startPeakIndex.i && j >= startPeakIndex.j)
        ) {
          if (
            i < endPeakIndex.i ||
            (i === endPeakIndex.i && j <= endPeakIndex.j)
          ) {
            SelectedDataSetAll[i].peaks[j].classification =
              BackupDataSetAll[i].peaks[j].classification;
          }
        }
      }
    }
  }

  /* ====== Start of Coordinate Transformation Functions ===== */

  function getSVGx(x: any) {
    return x - svgLeftRef.current;
  }

  function getSVGy(y: any) {
    // TODO: svgTopRef should change when BeatDiv opens/closes
    if (BeatDiv) {
      return y - (svgTopRef.current + 250);
    }
    return y - svgTopRef.current;
  }

  function getZoneFromY(y: any) {
    return Math.floor(y / heightOfRow);
  }

  function getScrollSVGy(y: any) {
    return y - svgScrollTopRef.current;
  }

  /* ====== End of Coordinate Transformation Functions ====== */

  /* ====== Start of Functions to find data ======= */

  function getSingleAnnotationsInRange(StartTime: any, EndTime: any) {
    // Filter All Annotations of TagType = 1 in this range
    //AllAnnotations
    var SingleAnnotations = [];
    for (let i = 0; i < AllAnnotations.length; i++) {
      if (AllAnnotations[i].DataType === 1) {
        if (
          StartTime >= AllAnnotations[i].StartTime &&
          AllAnnotations[i].StartTime <= EndTime
        ) {
          SingleAnnotations.push(AllAnnotations[i]);
        }
      }
      return SingleAnnotations;
    }
  }

  function getIndexRangeForSearch(SearchableVal: number, Size: number) {
    let Range = {} as any;
    var Start = 0;
    var End = Size;
    var Result = SearchableVal / 60.0;
    var ResultRound = Math.floor(Result);
    if (Result != ResultRound) {
      //console.log('res:'+Result+', rounded res:'+ResultRound);
    }
    if (ResultRound <= Size) {
      Start = ResultRound - 2 < 0 ? ResultRound : ResultRound - 2;
      End = Start + 2 < Size ? Start + 2 : Start;
    }
    Range.Start = Start;
    Range.End = End;
    return Range;
  }

  function getNearestPeak(Seconds: any, Direction: number) {
    // Direction 0 means nearest, 1 means greater than and 2 means less than
    // AR: First get the Index Range for which the outer loop need to be run
    var IndexRange = getIndexRangeForSearch(
      Seconds,
      SelectedDataSetAllRef.current.length
    );
    // Uncomment below 2 lines for complete loop
    /*
    IndexRange.Start=0;
    IndexRange.End=SelectedDataSetAllRef.current.length-1;
    */

    var minDiff = 100000;
    var NearestPeakMainIndex = -1;
    var NearestPeakSecondIndex = -1;
    if (Direction === 0) {
      for (let i = IndexRange.Start; i <= IndexRange.End; i++) {
        for (
          let j = 0;
          j < SelectedDataSetAllRef.current[i].peaks.length;
          j++
        ) {
          var m = Math.abs(
            Seconds - SelectedDataSetAllRef.current[i].peaks[j].value[0]
          );
          if (m < minDiff) {
            minDiff = m;
            NearestPeakMainIndex = i;
            NearestPeakSecondIndex = j;
          }
        }
      }
    } else if (Direction === 1) {
      for (let i = IndexRange.Start; i <= IndexRange.End; i++) {
        for (
          let j = 0;
          j < SelectedDataSetAllRef.current[i].peaks.length;
          j++
        ) {
          if (SelectedDataSetAllRef.current[i].peaks[j].value[0] > Seconds) {
            var m = Math.abs(
              Seconds - SelectedDataSetAllRef.current[i].peaks[j].value[0]
            );
            if (m < minDiff) {
              minDiff = m;
              NearestPeakMainIndex = i;
              NearestPeakSecondIndex = j;
            }
          }
        }
      }
    } else if (Direction === 2) {
      for (let i = IndexRange.Start; i <= IndexRange.End; i++) {
        for (
          let j = 0;
          j < SelectedDataSetAllRef.current[i].peaks.length;
          j++
        ) {
          if (SelectedDataSetAllRef.current[i].peaks[j].value[0] < Seconds) {
            var m = Math.abs(
              Seconds - SelectedDataSetAllRef.current[i].peaks[j].value[0]
            );
            if (m < minDiff) {
              minDiff = m;
              NearestPeakMainIndex = i;
              NearestPeakSecondIndex = j;
            }
          }
        }
      }
    }

    /*    alert(
          "Seconds: " +
            Seconds +
            "; NearestPeak: " +
            NearestPeak +
            "; NearestPeakIndex: " +
            NearestPeakIndex
        );*/
    let Index = {} as any;
    Index.i = NearestPeakMainIndex;
    Index.j = NearestPeakSecondIndex;

    return Index;
  }

  // TODO: This will probably not be needed - was added for testing
  function getNearestBeatFromIndexedDataSet(Seconds: any) {
    var minDiff = 1000;
    var NearestBeati = 0;
    var NearestBeatj = 0;

    for (var i = 0; i < IndexedDataSet.length; i++) {
      for (var j = 1; j < IndexedDataSet[i].length - 1; j++) {
        var m = Math.abs(Seconds - IndexedDataSet[i][j][0]);
        if (m < minDiff) {
          minDiff = m;
          var NearestBeati = i;
          var NearestBeatj = j;
        }
      }
    }
    return { i: NearestBeati, j: NearestBeatj };
  }

  function getNearestBeat(Seconds: any) {
    var minDiff = 1000;
    var NearestBeat;
    var NearestBeatIndex = 0;
    for (let i = 0; i < SelectedDataSet[0].data.length; i++) {
      var m = Math.abs(Seconds - SelectedDataSet[0].data[i][0]);
      if (m < minDiff) {
        minDiff = m;
        NearestBeat = SelectedDataSet[0].data[i][0];
        NearestBeatIndex = i;
      }
    }
    // alert(
    //   "Seconds: " +
    //     Seconds +
    //     "; NearestBeat: " +
    //     NearestBeat +
    //     "; NearestBeatIndex: " +
    //     NearestBeatIndex
    // );
    return NearestBeatIndex;
  }

  function getAllBPMFromDataSet(sorted: boolean) {
    var l_arrBPM = [];
    for (let i = 0; i < SelectedDataSetAllRef.current.length; i++) {
      let BPMobject = {} as any;

      // Use this if we can trust their BPM figure
      //      BPMobject.bpm = SelectedDataSetAllRef.current[i].bpm;

      // Use this to calculate the BPM from Peaks
      var numberOfBeats = SelectedDataSetAllRef.current[i].peaks.length;
      var timeOfFirstBeat = SelectedDataSetAllRef.current[i].peaks[0].value[0];
      var timeOfLastBeat =
        SelectedDataSetAllRef.current[i].peaks[numberOfBeats - 1].value[0];

      BPMobject.bpm =
        (60 * (numberOfBeats - 1)) / (timeOfLastBeat - timeOfFirstBeat);
      //console.log(bpm);

      // End

      BPMobject.minute = SelectedDataSetAllRef.current[i].minute;
      BPMobject.hour = SelectedDataSetAllRef.current[i].hour;
      BPMobject.totalMinutes =
        SelectedDataSetAllRef.current[i].hour * 60 +
        SelectedDataSetAllRef.current[i].minute;
      l_arrBPM.push(BPMobject);
    }

    if (!sorted) {
      return l_arrBPM;
    }

    var l_arrSortedBPM = l_arrBPM.sort(function (a, b) {
      return a.bpm - b.bpm;
    });

    return l_arrSortedBPM;
  }

  function getBPMAndBeats(StartSeconds: any, EndSeconds: any) {
    var minDiffStartSeconds = 1000;
    var NearestPeakForStartSeconds: any;
    var NearestPeakMainIndexForStartSeconds: any;
    var NearestPeakInternalIndexForStartSeconds: any;

    var minDiffEndSeconds = 1000;
    var NearestPeakForEndSeconds: any;
    var NearestPeakMainIndexForEndSeconds: any;
    var NearestPeakInternalIndexForEndSeconds: any;

    var IndexDifference;

    let StartIndex = 0;
    StartIndex = Math.floor(StartSeconds / 60);
    if (StartIndex <= 0) {
      StartIndex = 0;
    }

    let EndIndex = 0;
    EndIndex = Math.ceil(StartSeconds / 60);

    if (EndIndex <= 0) {
      EndIndex = 0;
    }

    // for (let i = 0; i < SelectedDataSetAllRef.current.length; i++) {
    for (let i = StartIndex; i <= EndIndex; i++) {
      for (let j = 0; j < SelectedDataSetAllRef.current[i].peaks.length; j++) {
        var StartSecondsDiff = Math.abs(
          StartSeconds - SelectedDataSetAllRef.current[i].peaks[j].value[0]
        );
        if (StartSecondsDiff < minDiffStartSeconds) {
          minDiffStartSeconds = StartSecondsDiff;
          NearestPeakForStartSeconds =
            SelectedDataSetAllRef.current[i].peaks[j].value[0];
          NearestPeakMainIndexForStartSeconds = i;
          NearestPeakInternalIndexForStartSeconds = j;
        }

        var EndSecondsDiff = Math.abs(
          EndSeconds - SelectedDataSetAllRef.current[i].peaks[j].value[0]
        );
        if (EndSecondsDiff < minDiffEndSeconds) {
          minDiffEndSeconds = EndSecondsDiff;
          NearestPeakForEndSeconds =
            SelectedDataSetAllRef.current[i].peaks[j].value[0];
          NearestPeakMainIndexForEndSeconds = i;
          NearestPeakInternalIndexForEndSeconds = j;
        }
      }
    }

    //IndexDifference = total data points
    if (
      NearestPeakMainIndexForStartSeconds === NearestPeakMainIndexForEndSeconds
    ) {
      IndexDifference =
        NearestPeakInternalIndexForEndSeconds -
        NearestPeakInternalIndexForStartSeconds -
        1;
    } else {
      //for handling different index
      IndexDifference =
        NearestPeakInternalIndexForEndSeconds +
        (SelectedDataSetAllRef.current[NearestPeakMainIndexForStartSeconds]
          .peaks.length -
          NearestPeakInternalIndexForStartSeconds) -
        1;
    }

    var l_BPM = 0;

    if (IndexDifference > 0) {
      l_BPM =
        (60 * IndexDifference) /
        (NearestPeakForEndSeconds - NearestPeakForStartSeconds);
    }

    // console.log(
    //   "IndexDifference: " +
    //     IndexDifference +
    //     "; NearestPeakForStartSeconds: " +
    //     NearestPeakForStartSeconds +
    //     "; NearestPeakForEndSeconds: " +
    //     NearestPeakForEndSeconds +
    //     "; BPM: " +
    //     l_BPM
    // );

    let l_oBPMobject = {} as any;
    l_oBPMobject.BPM = l_BPM;
    l_oBPMobject.Beats = IndexDifference + 1;

    return l_oBPMobject;
  }

  /* ======= END of functions to find data ======= */

  // TODO: Lets check why this is needed
  useEffect(() => {
    if (SelectedDataSet.length > 0) {
      let localInterval = getAndUpdateInterval(
        globalW,
        BottomDiv.ECGSplitView,
        mmsValue
      );

      MakeIndexedList(
        GraphStartTimeRef.current,
        localInterval,
        LineCountRef.current
      );
    }
  }, [startSignal, mmsValue, BottomDiv]);

  function getAndUpdateInterval(
    width: any,
    splitVisible: any,
    localMMSValue: any
  ) {
    let oneMMperSec = heightOfRow / 20;
    let widthOfOneSecond = localMMSValue * oneMMperSec;
    let numberOfSecondsInWidth = width / widthOfOneSecond;
    let localInterval = numberOfSecondsInWidth;

    // If we are in split View, ignore these intervals and a smaller scale
    if (splitVisible === true) {
      localInterval = width / 12;
    }
    setinterval(localInterval);
    return localInterval;
  }

  function detectPeaksInEDFData_Test(
    threshold: number,
    windowSize: number
  ): any[] {
    const peaks: any = [];

    //  let Signal = 0;
    let numberOfDataRecords = decodedEDFData._header.nbDataRecords; // 3864;
    let numberOfSignals = decodedEDFData._header.nbSignals; //3;
    let signalIndex = 0;

    // The time when the data starts in seconds from 00:00
    let dataStartSeconds = 0;
    let numberOfSamples =
      decodedEDFData._header.signalInfo[signalIndex].nbOfSamples; //125;
    let secondsPerIndex = 1 / numberOfSamples;

    var slidingWindow = [];
    const doubleWindowSize = windowSize * 2;

    let denoisedData = preprocessECG(
      decodedEDFData._physicalSignals[signalIndex],
      40
    );

    console.log("denoised data");
    console.log(denoisedData);
    return denoisedData;

    /*
    if (numberOfDataRecords > 0) {
      if (decodedEDFData._physicalSignals[signalIndex][0].length > windowSize) {
        for (let i = 0; i < windowSize; i++) {
          slidingWindow.push(
            decodedEDFData._physicalSignals[signalIndex][0][i]
          );
        }
      }
    }
    // TODO: Check if data is empty, include a fix

    for (let j = 0; j < numberOfDataRecords; j++) {
      let timeOfThisRowInSeconds = j + dataStartSeconds;
      for (
        let k = 0;
        k < decodedEDFData._physicalSignals[signalIndex][j].length;
        k++
      ) {
        let subSecondTimeOfThisRecord = k * secondsPerIndex;
        let fullTimeOfThisRecord =
          timeOfThisRowInSeconds + subSecondTimeOfThisRecord;

        var outerIndex = j;
        var innerIndex = k + windowSize;
        if (
          innerIndex >= decodedEDFData._physicalSignals[signalIndex][j].length
        ) {
          outerIndex += 1;
          innerIndex =
            innerIndex - decodedEDFData._physicalSignals[signalIndex][j].length;
        }

        if (outerIndex < numberOfDataRecords) {
          slidingWindow.push(
            decodedEDFData._physicalSignals[signalIndex][outerIndex][innerIndex]
          );
        }

        if (slidingWindow.length >= doubleWindowSize) {
          var max = -100000;
          var maxIndex = -1;
          var sum = 0;
          for (let m = 0; m < slidingWindow.length; m++) {
            sum += slidingWindow[m];
            if (slidingWindow[m] > max) {
              max = slidingWindow[m];
              maxIndex = m;
            }
          }

          var average = sum / slidingWindow.length;

          var thresholdAdjustment = 0;
          if (average > 200) {
            thresholdAdjustment = average - 200;
          }
          if (average < -200) {
            thresholdAdjustment = average + 200;
          }
          // Our record is at windowSize
          if (
            max >= threshold + thresholdAdjustment &&
            maxIndex === windowSize - 1
          ) {
            let l_singleRowData = [
              fullTimeOfThisRecord,
              decodedEDFData._physicalSignals[signalIndex][j][k],
            ];
            peaks.push(l_singleRowData);
          }

          // Pop first record
          slidingWindow.shift();
        }
      }
    }
    */
    //  return peaks;
  }

  function detectPeaksInEDFData_Backup(
    threshold: number,
    windowSize: number
  ): any[] {
    const peaks: any = [];

    //  let Signal = 0;
    let numberOfDataRecords = decodedEDFData._header.nbDataRecords; // 3864;
    let numberOfSignals = decodedEDFData._header.nbSignals; //3;
    let signalIndex = 0;

    // The time when the data starts in seconds from 00:00
    let dataStartSeconds = 0;
    let numberOfSamples =
      decodedEDFData._header.signalInfo[signalIndex].nbOfSamples; //125;
    let secondsPerIndex = 1 / numberOfSamples;

    var slidingWindow = [];
    const doubleWindowSize = windowSize * 2;

    if (numberOfDataRecords > 0) {
      if (decodedEDFData._physicalSignals[signalIndex][0].length > windowSize) {
        for (let i = 0; i < windowSize; i++) {
          slidingWindow.push(
            decodedEDFData._physicalSignals[signalIndex][0][i]
          );
        }
      }
    }
    // TODO: Check if data is empty, include a fix

    for (let j = 0; j < numberOfDataRecords; j++) {
      let timeOfThisRowInSeconds = j + dataStartSeconds;
      for (
        let k = 0;
        k < decodedEDFData._physicalSignals[signalIndex][j].length;
        k++
      ) {
        let subSecondTimeOfThisRecord = k * secondsPerIndex;
        let fullTimeOfThisRecord =
          timeOfThisRowInSeconds + subSecondTimeOfThisRecord;

        var outerIndex = j;
        var innerIndex = k + windowSize;
        if (
          innerIndex >= decodedEDFData._physicalSignals[signalIndex][j].length
        ) {
          outerIndex += 1;
          innerIndex =
            innerIndex - decodedEDFData._physicalSignals[signalIndex][j].length;
        }

        if (outerIndex < numberOfDataRecords) {
          slidingWindow.push(
            decodedEDFData._physicalSignals[signalIndex][outerIndex][innerIndex]
          );
        }

        // console.log(
        //   "A1:" +
        //     decodedEDFData._physicalSignals[signalIndex][j][k] +
        //     ",2:" +
        //     slidingWindow[windowSize - 1]
        // );

        //        slidingWindow.push(decodedEDFData._physicalSignals[signalIndex][j][k]);
        if (slidingWindow.length >= doubleWindowSize) {
          // Check
          //const max = Math.max(...slidingWindow.map((obj) => obj));

          // console.log(
          //   "B1:" +
          //     decodedEDFData._physicalSignals[signalIndex][j][k] +
          //     ",2:" +
          //     slidingWindow[windowSize - 1]
          // );

          var max = -100000;
          var maxIndex = -1;
          var sum = 0;
          for (let m = 0; m < slidingWindow.length; m++) {
            sum += slidingWindow[m];
            if (slidingWindow[m] > max) {
              max = slidingWindow[m];
              maxIndex = m;
            }
          }

          var average = sum / slidingWindow.length;

          var thresholdAdjustment = 0;
          if (average > 200) {
            thresholdAdjustment = average - 200;
          }
          if (average < -200) {
            thresholdAdjustment = average + 200;
          }
          // Our record is at windowSize
          if (
            max >= threshold + thresholdAdjustment &&
            maxIndex === windowSize - 1
          ) {
            let l_singleRowData = [
              fullTimeOfThisRecord,
              decodedEDFData._physicalSignals[signalIndex][j][k],
            ];
            peaks.push(l_singleRowData);
            //    console.log(average);

            //            console.log(l_singleRowData);
            //            console.log(slidingWindow);
          }

          // Pop first record
          slidingWindow.shift();
        }
      }
    }

    // for (let i = windowSize; i < data.length - windowSize; i++) {
    //   const segment = data.slice(i - windowSize, i + windowSize + 1);
    //   const max = Math.max(...segment.map((obj) => obj[1]));

    //   if (max >= threshold && max === data[i][1]) {
    //     peaks.push(i);
    //   }
    // }
    //console.log(peaks);
    return peaks;
  }

  function detectSVEInEDFData(peaks: any) {
    // How many Peaks in the sliding window
    const windowSize = 20;

    // Sliding window
    //

    var SVEArray = [] as any;
    // var derivative = [] as any;
    for (let i = 1; i < peaks.length - windowSize; i++) {
      //      const segment = peaks.slice(i - 1, i + windowSize);
      let distanceForCurrent = peaks[i][0] - peaks[i - 1][0];
      let sumOfDistances = 0;
      for (let j = i; j < i + windowSize; j++) {
        let distanceFromPrevious = peaks[j][0] - peaks[j - 1][0];
        sumOfDistances += distanceFromPrevious;
      }
      let averageOfDistances = sumOfDistances / windowSize;
      if (distanceForCurrent < 0.7 * averageOfDistances) {
        SVEArray.push(peaks[i]);
      }
    }
    setSVEDataSet(SVEArray);

    var SVEEctopyArray = [] as any;

    for (let k = 0; k < SVEArray.length; k++) {
      if (k >= 100) {
        break;
      }
      let newObject = {
        EctopyID: "Beta" + (100 + k),
        VType: 2,
        Class: 1,
        Data: [],
        Time: SVEArray[k][0],
      };
      SVEEctopyArray.push(newObject);
    }

    const newArray = [...AllEctopies, ...SVEEctopyArray];
    setAllEctopies(newArray);
  }

  function MakeIndexedList(StartTime: any, Interval: any, LineCount: any) {
    //console.log("MakeIndex : " + StartTime);
    var IndexList = [] as any;
    // let peaks = [] as any;
    if (isEDFData === true) {
      // if (!peakDetectionDone) {
      //   //  console.log("- - -");
      //   //  console.log(decodedEDFData);
      //   //  console.log("- <-> -");

      //   setdataRate(40);
      //   peaks = detectPeaksInEDFData(200, 50);
      //   setPeaksDataSet(peaks);
      //   setpeakDetectionDone(true);
      //   detectSVEInEDFData(peaks);
      // }

      // console.log(decodedEDFData);

      // The time when the data starts in seconds from 00:00
      let dataStartSeconds = 0;

      let recordingDate = decodedEDFData._header.recordingDate;
      let year = recordingDate.getFullYear();

      if (year < 2000 && year > 1900) {
        recordingDate.setFullYear(year + 100);
      }
      SelectedDataSet[0].date = moment(recordingDate).format("YYYY-MM-DD"); // will remove this when we get rid of jsondata
      setSelectedDate("D1 - " + moment(recordingDate).format("YYYY-MM-DD"));

      // Number of Seconds of data
      let numberOfDataRecords = decodedEDFData._header.nbDataRecords;
      let numberOfSignals = decodedEDFData._header.nbSignals;

      let Signal = 0;

      if (
        numberOfSignals > 0 &&
        numberOfSignals >= Signal + 1 &&
        numberOfDataRecords > 0
      ) {
        let minimumSeconds = dataStartSeconds;
        let maximumSeconds = dataStartSeconds + numberOfDataRecords;

        if (StartTime >= minimumSeconds && StartTime <= maximumSeconds) {
          let secondIndexStart = Math.ceil(StartTime) - dataStartSeconds; //changed to ceil to fix length issue
          let secondIndexMax =
            secondIndexStart + Math.ceil(Interval * LineCount);

          if (secondIndexMax > numberOfDataRecords - 1) {
            secondIndexMax = numberOfDataRecords - 1;
          }

          let outputIndex = 0;
          let nextInterval = StartTime + Interval;

          // If we need to loop signals, put everything below here inside the loop
          let numberOfSamples =
            decodedEDFData._header.signalInfo[Signal].nbOfSamples; //125;
          let secondsPerIndex = 1 / numberOfSamples;

          let signalIndex = 0;
          let fullBreak = false;
          let singleRowData = [] as any;
          for (let j = secondIndexStart; j <= secondIndexMax; j++) {
            if (fullBreak) {
              break;
            }
            let rowStartIndex = 0;
            if (j === secondIndexStart) {
              let decimalPortionOfStartTime = StartTime - Math.floor(StartTime);
              rowStartIndex = Math.floor(
                decimalPortionOfStartTime * numberOfSamples
              );
            }

            let timeOfThisRowInSeconds = j + dataStartSeconds;
            for (
              let k = rowStartIndex;
              k < decodedEDFData._physicalSignals[signalIndex][j].length;
              k++
            ) {
              let subSecondTimeOfThisRecord = k * secondsPerIndex;
              let fullTimeOfThisRecord =
                timeOfThisRowInSeconds + subSecondTimeOfThisRecord;
              if (fullTimeOfThisRecord > secondIndexMax) {
                fullBreak = true;
                break;
              }
              if (fullTimeOfThisRecord >= nextInterval) {
                outputIndex++;
                nextInterval = nextInterval + Interval;
                singleRowData.date = recordingDate;
                IndexList.push(singleRowData);
                singleRowData = [];
              }

              let l_singleRowData = [
                fullTimeOfThisRecord,
                decodedEDFData._physicalSignals[signalIndex][j][k],
              ];
              singleRowData.push(l_singleRowData);
            }
          }
        }
      }

      // Handle Peaks data - check peaks variable first otherwise use from state.
      let peaks = PeaksDataSet;
      var PeaksList = [];
      var SinglePeakList = [];
      var PeaksInitValue = StartTime;
      var PeaksNextBreakPoint = Interval;
      console.log("Peaks inside MakeIndexedList");
      console.log(peaks);
      for (var k = 0; k < peaks.length; k++) {
        if (PeaksList.length >= LineCount) {
          boolPeakList = true;
          break;
        }
        //  console.log("row : " + k);
        //  console.log(peaks[k]);
        let thisPeakTime = peaks[k].value[0];
        let thisPeakValue = peaks[k].value[1];
        if (
          thisPeakTime >= PeaksInitValue &&
          thisPeakTime <= PeaksNextBreakPoint + StartTime
        ) {
          SinglePeakList.push({
            value: [thisPeakTime, thisPeakValue],
            classification: peaks[k].classification,
          });
        } else {
          if (thisPeakTime >= PeaksInitValue) {
            PeaksList.push(SinglePeakList);
            SinglePeakList = [];
            PeaksInitValue = PeaksNextBreakPoint;
            PeaksNextBreakPoint = PeaksNextBreakPoint + Interval;
            SinglePeakList.push({
              value: [thisPeakTime, thisPeakValue],
              classification: peaks[k].classification,
            });
          }
        }
      }

      if (SinglePeakList.length > 0) {
        PeaksList.push(SinglePeakList);
      }

      // Set the number of Rows of data available in this dataset
      setLoadedDataRows(Math.ceil(numberOfDataRecords / interval));
      setIndexedDataSet(IndexList.slice(0, LineCount));
      setIndexedPeaksDataSet(PeaksList.slice(0, LineCount));
    } else {
      setdataRate(20);
      var PeaksList = [];
      var InitValue = StartTime;
      var NextBreakPoint = Interval;
      var PeaksInitValue = StartTime;
      var PeaksNextBreakPoint = Interval;
      var SingleIntervalList = [] as any;
      var SinglePeakList = [];
      var SetList = SelectedDataSetAllRef.current;

      var boolPeakList = false;
      var boolBeatList = false;

      for (var i = 0; i < SetList.length; i++) {
        if (boolPeakList && boolBeatList) {
          break;
        }

        // Split the Beats data

        for (var j = 0; j < SetList[i].data.length; j++) {
          if (IndexList.length >= LineCount) {
            boolBeatList = true;
            break;
          }
          if (
            SetList[i].data[j][0] >= InitValue &&
            SetList[i].data[j][0] <= NextBreakPoint + StartTime
          ) {
            SingleIntervalList.push(SetList[i].data[j]);
            SingleIntervalList.date = SetList[i].date;
          } else {
            if (SetList[i].data[j][0] >= InitValue) {
              IndexList.push(SingleIntervalList);
              SingleIntervalList = [];
              InitValue = NextBreakPoint;
              NextBreakPoint = NextBreakPoint + Interval;
              SingleIntervalList.push(SetList[i].data[j]);
              SingleIntervalList.date = SetList[i].date;
            }
          }
        }
        // Split the Peaks data

        for (var k = 0; k < SetList[i].peaks.length; k++) {
          if (PeaksList.length >= LineCount) {
            boolPeakList = true;
            break;
          }
          if (dataSetFormat === 2) {
            if (SetList[i].peaks[k].value) {
              if (
                SetList[i].peaks[k].value[0] >= PeaksInitValue &&
                SetList[i].peaks[k].value[0] <= PeaksNextBreakPoint + StartTime
              ) {
                SinglePeakList.push(SetList[i].peaks[k]);
              } else {
                if (SetList[i].peaks[k].value[0] >= PeaksInitValue) {
                  PeaksList.push(SinglePeakList);
                  SinglePeakList = [];
                  PeaksInitValue = PeaksNextBreakPoint;
                  PeaksNextBreakPoint = PeaksNextBreakPoint + Interval;
                  SinglePeakList.push(SetList[i].peaks[k]);
                }
              }
            }
          } else if (dataSetFormat === 1) {
            if (
              SetList[i].peaks[k][0] >= PeaksInitValue &&
              SetList[i].peaks[k][0] <= PeaksNextBreakPoint + StartTime
            ) {
              SinglePeakList.push(SetList[i].peaks[k]);
            } else {
              if (SetList[i].peaks[k][0] >= PeaksInitValue) {
                PeaksList.push(SinglePeakList);
                SinglePeakList = [];
                PeaksInitValue = PeaksNextBreakPoint;
                PeaksNextBreakPoint = PeaksNextBreakPoint + Interval;
                SinglePeakList.push(SetList[i].peaks[k]);
              }
            }
          }
        }
      }

      if (SingleIntervalList.length > 0) {
        IndexList.push(SingleIntervalList);
      }
      if (SinglePeakList.length > 0) {
        PeaksList.push(SinglePeakList);
      }

      if (SelectedDataSetAllRef.current.length > 0) {
        if (SelectedDataSetAllRef.current[0].data.length > 0) {
          //        console.log("start seconds : " + SelectedDataSetAllRef.current[0].data[0][0]);

          // TODO: Check if there should be a floor here or not
          setLoadedDataStartTime(
            Math.floor(SelectedDataSetAllRef.current[0].data[0][0])
          );
        }
        if (
          SelectedDataSetAllRef.current[
            SelectedDataSetAllRef.current.length - 1
          ].data.length > 0
        ) {
          let lastIndex = SelectedDataSetAllRef.current.length - 1;
          let lastRowlastIndex =
            SelectedDataSetAllRef.current[lastIndex].data.length - 1;
          //        console.log("end seconds : " + SelectedDataSetAllRef.current[lastIndex].data[lastRowlastIndex][0]);

          // TODO: Check if there should be a floor here - probably needs a +1 at the end to get the partial row but only if there is one
          setLoadedDataRows(
            Math.floor(
              SelectedDataSetAllRef.current[lastIndex].data[
                lastRowlastIndex
              ][0] / interval
            )
          );
        }
      }

      //    SelectedDataSetAllRef[0].data[0][0]

      //console.log(IndexList);
      // console.log(PeaksList);
      //    console.log("before : " + scrollYRef.current + " , " + scrollY);
      let newScrollY = 0;
      if (isNaN(scrollYRef.current)) {
        //    console.log(" NaN scrollYRef.current");
        setScrollY(0);
      } else {
        newScrollY =
          (Math.floor(StartTime / Interval) /
            (LoadedDataRowsRef.current - LineCount)) *
          (scrollHeightRef.current - 20);
        // console.log(
        //   "ScrollY - from : " + scrollYRef.current + " to " + newScrollY
        // );
        setScrollY(newScrollY);
      }

      // console.log(
      //   "newScrollY: " +
      //     newScrollY +
      //     " StartTime: " +
      //     StartTime +
      //     " Interval: " +
      //     Interval +
      //     " LoadedDataRowsRef.current: " +
      //     LoadedDataRowsRef.current
      // );
      // console.log(
      //   "scrollHeightRef.current: " +
      //     scrollHeightRef.current +
      //     " scrollYRef.current: " +
      //     scrollYRef.current
      // );

      //  console.log("after : " + scrollYRef.current  + " , " + scrollYRef.current);

      setIndexedDataSet(IndexList.slice(0, LineCount));
      setIndexedPeaksDataSet(PeaksList.slice(0, LineCount));
      // console.log("PeaksList");
      // console.log(PeaksList);
    }
  }

  function MakeIndexedList_NormalisationTest(
    StartTime: any,
    Interval: any,
    LineCount: any
  ) {
    //console.log("MakeIndex : " + StartTime);
    var IndexList = [] as any;
    let peaks = [] as any;
    if (isEDFData === true) {
      if (!peakDetectionDone) {
        console.log("- - -");
        console.log(decodedEDFData);
        console.log("- <-> -");

        setdataRate(40);
        //  peaks = detectPeaksInEDFData(200, 50);
        setPeaksDataSet(peaks);
        setpeakDetectionDone(true);
        detectSVEInEDFData(peaks);
      }

      // console.log(decodedEDFData);

      // The time when the data starts in seconds from 00:00
      let dataStartSeconds = 0;

      let recordingDate = decodedEDFData._header.recordingDate;
      let year = recordingDate.getFullYear();

      if (year < 2000 && year > 1900) {
        recordingDate.setFullYear(year + 100);
      }
      SelectedDataSet[0].date = moment(recordingDate).format("YYYY-MM-DD"); // will remove this when we get rid of jsondata
      setSelectedDate("D1 - " + moment(recordingDate).format("YYYY-MM-DD"));

      // Number of Seconds of data
      let numberOfDataRecords = decodedEDFData._header.nbDataRecords;
      let numberOfSignals = decodedEDFData._header.nbSignals;

      let Signal = 0;

      if (
        numberOfSignals > 0 &&
        numberOfSignals >= Signal + 1 &&
        numberOfDataRecords > 0
      ) {
        let minimumSeconds = dataStartSeconds;
        let maximumSeconds = dataStartSeconds + numberOfDataRecords;

        if (StartTime >= minimumSeconds && StartTime <= maximumSeconds) {
          let secondIndexStart = Math.ceil(StartTime) - dataStartSeconds; //changed to ceil to fix length issue
          let secondIndexMax =
            secondIndexStart + Math.ceil(Interval * LineCount);

          if (secondIndexMax > numberOfDataRecords - 1) {
            secondIndexMax = numberOfDataRecords - 1;
          }

          let outputIndex = 0;
          let nextInterval = StartTime + Interval;

          // If we need to loop signals, put everything below here inside the loop
          let numberOfSamples =
            decodedEDFData._header.signalInfo[Signal].nbOfSamples; //125;
          let secondsPerIndex = 1 / numberOfSamples;

          let signalIndex = 0;
          let fullBreak = false;
          let singleRowData = [] as any;

          for (let i = 0; i < 16 * 125; i++) {
            let onePointData = [secondsPerIndex * i, peaks[i]];

            singleRowData.push(onePointData);
          }
          IndexList.push(singleRowData);
          singleRowData = [];
        }
      }

      // Handle Peaks data - check peaks variable first otherwise use from state.

      // Set the number of Rows of data available in this dataset
      setLoadedDataRows(Math.ceil(numberOfDataRecords / interval));
      setIndexedDataSet(IndexList.slice(0, LineCount));
    }
  }

  function MakeIndexedList_Backup(
    StartTime: any,
    Interval: any,
    LineCount: any
  ) {
    //console.log("MakeIndex : " + StartTime);
    var IndexList = [] as any;
    let peaks = [] as any;
    if (isEDFData === true) {
      if (!peakDetectionDone) {
        console.log("- - -");
        console.log(decodedEDFData);
        console.log("- <-> -");

        setdataRate(40);
        //   peaks = detectPeaksInEDFData(200, 50);
        setPeaksDataSet(peaks);
        setpeakDetectionDone(true);
        detectSVEInEDFData(peaks);
      }

      // console.log(decodedEDFData);

      // The time when the data starts in seconds from 00:00
      let dataStartSeconds = 0;

      let recordingDate = decodedEDFData._header.recordingDate;
      let year = recordingDate.getFullYear();

      if (year < 2000 && year > 1900) {
        recordingDate.setFullYear(year + 100);
      }
      SelectedDataSet[0].date = moment(recordingDate).format("YYYY-MM-DD"); // will remove this when we get rid of jsondata
      setSelectedDate("D1 - " + moment(recordingDate).format("YYYY-MM-DD"));

      // Number of Seconds of data
      let numberOfDataRecords = decodedEDFData._header.nbDataRecords;
      let numberOfSignals = decodedEDFData._header.nbSignals;

      let Signal = 0;

      if (
        numberOfSignals > 0 &&
        numberOfSignals >= Signal + 1 &&
        numberOfDataRecords > 0
      ) {
        let minimumSeconds = dataStartSeconds;
        let maximumSeconds = dataStartSeconds + numberOfDataRecords;

        if (StartTime >= minimumSeconds && StartTime <= maximumSeconds) {
          let secondIndexStart = Math.ceil(StartTime) - dataStartSeconds; //changed to ceil to fix length issue
          let secondIndexMax =
            secondIndexStart + Math.ceil(Interval * LineCount);

          if (secondIndexMax > numberOfDataRecords - 1) {
            secondIndexMax = numberOfDataRecords - 1;
          }

          let outputIndex = 0;
          let nextInterval = StartTime + Interval;

          // If we need to loop signals, put everything below here inside the loop
          let numberOfSamples =
            decodedEDFData._header.signalInfo[Signal].nbOfSamples; //125;
          let secondsPerIndex = 1 / numberOfSamples;

          let signalIndex = 0;
          let fullBreak = false;
          let singleRowData = [] as any;
          for (let j = secondIndexStart; j <= secondIndexMax; j++) {
            if (fullBreak) {
              break;
            }
            let rowStartIndex = 0;
            if (j === secondIndexStart) {
              let decimalPortionOfStartTime = StartTime - Math.floor(StartTime);
              rowStartIndex = Math.floor(
                decimalPortionOfStartTime * numberOfSamples
              );
            }

            let timeOfThisRowInSeconds = j + dataStartSeconds;
            for (
              let k = rowStartIndex;
              k < decodedEDFData._physicalSignals[signalIndex][j].length;
              k++
            ) {
              let subSecondTimeOfThisRecord = k * secondsPerIndex;
              let fullTimeOfThisRecord =
                timeOfThisRowInSeconds + subSecondTimeOfThisRecord;
              if (fullTimeOfThisRecord > secondIndexMax) {
                fullBreak = true;
                break;
              }
              if (fullTimeOfThisRecord >= nextInterval) {
                outputIndex++;
                nextInterval = nextInterval + Interval;
                singleRowData.date = recordingDate;
                IndexList.push(singleRowData);
                singleRowData = [];
              }

              let l_singleRowData = [
                fullTimeOfThisRecord,
                decodedEDFData._physicalSignals[signalIndex][j][k],
              ];
              singleRowData.push(l_singleRowData);
            }
          }
        }
      }

      // Handle Peaks data - check peaks variable first otherwise use from state.

      var PeaksList = [];
      var SinglePeakList = [];
      var PeaksInitValue = StartTime;
      var PeaksNextBreakPoint = Interval;
      for (var k = 0; k < peaks.length; k++) {
        if (PeaksList.length >= LineCount) {
          boolPeakList = true;
          break;
        }
        if (
          peaks[k][0] >= PeaksInitValue &&
          peaks[k][0] <= PeaksNextBreakPoint + StartTime
        ) {
          SinglePeakList.push({
            value: [peaks[k][0], peaks[k][1]],
            classification: "N",
          });
        } else {
          if (peaks[k][0] >= PeaksInitValue) {
            PeaksList.push(SinglePeakList);
            SinglePeakList = [];
            PeaksInitValue = PeaksNextBreakPoint;
            PeaksNextBreakPoint = PeaksNextBreakPoint + Interval;
            SinglePeakList.push({
              value: [peaks[k][0], peaks[k][1]],
              classification: "N",
            });
          }
        }
      }

      if (SinglePeakList.length > 0) {
        PeaksList.push(SinglePeakList);
      }

      // Set the number of Rows of data available in this dataset
      setLoadedDataRows(Math.ceil(numberOfDataRecords / interval));
      setIndexedDataSet(IndexList.slice(0, LineCount));
      setIndexedPeaksDataSet(PeaksList.slice(0, LineCount));

      if (!peakDetectionDone) {
        // MakeFullEDFData();
      }
    } else {
      setdataRate(20);
      var PeaksList = [];
      var InitValue = StartTime;
      var NextBreakPoint = Interval;
      var PeaksInitValue = StartTime;
      var PeaksNextBreakPoint = Interval;
      var SingleIntervalList = [] as any;
      var SinglePeakList = [];
      var SetList = SelectedDataSetAllRef.current;

      var boolPeakList = false;
      var boolBeatList = false;

      for (var i = 0; i < SetList.length; i++) {
        if (boolPeakList && boolBeatList) {
          break;
        }

        // Split the Beats data

        for (var j = 0; j < SetList[i].data.length; j++) {
          if (IndexList.length >= LineCount) {
            boolBeatList = true;
            break;
          }
          if (
            SetList[i].data[j][0] >= InitValue &&
            SetList[i].data[j][0] <= NextBreakPoint + StartTime
          ) {
            SingleIntervalList.push(SetList[i].data[j]);
            SingleIntervalList.date = SetList[i].date;
          } else {
            if (SetList[i].data[j][0] >= InitValue) {
              IndexList.push(SingleIntervalList);
              SingleIntervalList = [];
              InitValue = NextBreakPoint;
              NextBreakPoint = NextBreakPoint + Interval;
              SingleIntervalList.push(SetList[i].data[j]);
              SingleIntervalList.date = SetList[i].date;
            }
          }
        }
        // Split the Peaks data

        for (var k = 0; k < SetList[i].peaks.length; k++) {
          if (PeaksList.length >= LineCount) {
            boolPeakList = true;
            break;
          }
          if (dataSetFormat === 2) {
            if (SetList[i].peaks[k].value) {
              if (
                SetList[i].peaks[k].value[0] >= PeaksInitValue &&
                SetList[i].peaks[k].value[0] <= PeaksNextBreakPoint + StartTime
              ) {
                SinglePeakList.push(SetList[i].peaks[k]);
              } else {
                if (SetList[i].peaks[k].value[0] >= PeaksInitValue) {
                  PeaksList.push(SinglePeakList);
                  SinglePeakList = [];
                  PeaksInitValue = PeaksNextBreakPoint;
                  PeaksNextBreakPoint = PeaksNextBreakPoint + Interval;
                  SinglePeakList.push(SetList[i].peaks[k]);
                }
              }
            }
          } else if (dataSetFormat === 1) {
            if (
              SetList[i].peaks[k][0] >= PeaksInitValue &&
              SetList[i].peaks[k][0] <= PeaksNextBreakPoint + StartTime
            ) {
              SinglePeakList.push(SetList[i].peaks[k]);
            } else {
              if (SetList[i].peaks[k][0] >= PeaksInitValue) {
                PeaksList.push(SinglePeakList);
                SinglePeakList = [];
                PeaksInitValue = PeaksNextBreakPoint;
                PeaksNextBreakPoint = PeaksNextBreakPoint + Interval;
                SinglePeakList.push(SetList[i].peaks[k]);
              }
            }
          }
        }
      }

      if (SingleIntervalList.length > 0) {
        IndexList.push(SingleIntervalList);
      }
      if (SinglePeakList.length > 0) {
        PeaksList.push(SinglePeakList);
      }

      if (SelectedDataSetAllRef.current.length > 0) {
        if (SelectedDataSetAllRef.current[0].data.length > 0) {
          //        console.log("start seconds : " + SelectedDataSetAllRef.current[0].data[0][0]);

          // TODO: Check if there should be a floor here or not
          setLoadedDataStartTime(
            Math.floor(SelectedDataSetAllRef.current[0].data[0][0])
          );
        }
        if (
          SelectedDataSetAllRef.current[
            SelectedDataSetAllRef.current.length - 1
          ].data.length > 0
        ) {
          let lastIndex = SelectedDataSetAllRef.current.length - 1;
          let lastRowlastIndex =
            SelectedDataSetAllRef.current[lastIndex].data.length - 1;
          //        console.log("end seconds : " + SelectedDataSetAllRef.current[lastIndex].data[lastRowlastIndex][0]);

          // TODO: Check if there should be a floor here - probably needs a +1 at the end to get the partial row but only if there is one
          setLoadedDataRows(
            Math.floor(
              SelectedDataSetAllRef.current[lastIndex].data[
                lastRowlastIndex
              ][0] / interval
            )
          );
        }
      }

      //    SelectedDataSetAllRef[0].data[0][0]

      //console.log(IndexList);
      // console.log(PeaksList);
      //    console.log("before : " + scrollYRef.current + " , " + scrollY);
      let newScrollY = 0;
      if (isNaN(scrollYRef.current)) {
        //    console.log(" NaN scrollYRef.current");
        setScrollY(0);
      } else {
        newScrollY =
          (Math.floor(StartTime / Interval) /
            (LoadedDataRowsRef.current - LineCount)) *
          (scrollHeightRef.current - 20);
        // console.log(
        //   "ScrollY - from : " + scrollYRef.current + " to " + newScrollY
        // );
        setScrollY(newScrollY);
      }

      // console.log(
      //   "newScrollY: " +
      //     newScrollY +
      //     " StartTime: " +
      //     StartTime +
      //     " Interval: " +
      //     Interval +
      //     " LoadedDataRowsRef.current: " +
      //     LoadedDataRowsRef.current
      // );
      // console.log(
      //   "scrollHeightRef.current: " +
      //     scrollHeightRef.current +
      //     " scrollYRef.current: " +
      //     scrollYRef.current
      // );

      //  console.log("after : " + scrollYRef.current  + " , " + scrollYRef.current);

      setIndexedDataSet(IndexList.slice(0, LineCount));
      setIndexedPeaksDataSet(PeaksList.slice(0, LineCount));
      // console.log("PeaksList")
      // console.log(PeaksList)
    }
  }

  function getBeatDataInRange(StartTime: any, EndTime: any) {
    var StartTimeMinDiff = 1000;
    var StartTimeNearestBeat;
    var StartTimeNearestBeatIndex = -1;

    var EndTimeMinDiff = 1000;
    var EndTimeNearestBeat;
    var EndTimeNearestBeatIndex = -1;

    var StartMainIndex = -1;
    var EndMainIndex = -1;

    for (let i = 0; i < SelectedDataSetAll.length; i++) {
      for (let j = 0; j < SelectedDataSetAll[i].data.length; j++) {
        var StartTimeMin = Math.abs(
          StartTime - SelectedDataSetAll[i].data[j][0]
        );
        if (StartTimeMin < StartTimeMinDiff) {
          StartTimeMinDiff = StartTimeMin;
          StartTimeNearestBeat = SelectedDataSetAll[i].data[j][0];
          StartTimeNearestBeatIndex = j;
          StartMainIndex = i;
        }

        var EndTimeMin = Math.abs(EndTime - SelectedDataSetAll[i].data[j][0]);
        if (EndTimeMin < EndTimeMinDiff) {
          EndTimeMinDiff = EndTimeMin;
          EndTimeNearestBeat = SelectedDataSetAll[i].data[j][0];
          EndTimeNearestBeatIndex = j;
          EndMainIndex = i;
        }
      }
    }
    let ReturnArray = [];
    if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex === EndMainIndex
    ) {
      ReturnArray = SelectedDataSetAll[StartMainIndex].data.slice(
        StartTimeNearestBeatIndex,
        EndTimeNearestBeatIndex + 1
      );
    } else if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex !== EndMainIndex
    ) {
      let StartArray = SelectedDataSetAll[StartMainIndex].data.slice(
        StartTimeNearestBeatIndex,
        SelectedDataSetAll[StartMainIndex].data.length
      );
      let EndArray = SelectedDataSetAll[EndMainIndex].data.slice(
        0,
        EndTimeNearestBeatIndex + 1
      );
      ReturnArray = StartArray.concat(EndArray);
    }

    return ReturnArray;
  }

  function getPeakDataInRange(StartTime: any, EndTime: any) {
    var StartTimeMinDiff = 1000;
    var StartTimeNearestBeat;
    var StartTimeNearestBeatIndex = -1;

    var EndTimeMinDiff = 1000;
    var EndTimeNearestBeat;
    var EndTimeNearestBeatIndex = -1;

    var StartMainIndex = -1;
    var EndMainIndex = -1;

    for (let i = 0; i < SelectedDataSetAll.length; i++) {
      for (let j = 0; j < SelectedDataSetAll[i].peaks.length; j++) {
        var StartTimeMin = Math.abs(
          StartTime - SelectedDataSetAll[i].peaks[j].value[0]
        );
        if (StartTimeMin < StartTimeMinDiff) {
          StartTimeMinDiff = StartTimeMin;
          StartTimeNearestBeat = SelectedDataSetAll[i].peaks[j].value[0];
          StartTimeNearestBeatIndex = j;
          StartMainIndex = i;
        }

        var EndTimeMin = Math.abs(
          EndTime - SelectedDataSetAll[i].peaks[j].value[0]
        );
        if (EndTimeMin < EndTimeMinDiff) {
          EndTimeMinDiff = EndTimeMin;
          EndTimeNearestBeat = SelectedDataSetAll[i].peaks[j].value[0];
          EndTimeNearestBeatIndex = j;
          EndMainIndex = i;
        }
      }
    }
    let ReturnArray = [];
    if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex === EndMainIndex
    ) {
      ReturnArray = SelectedDataSetAll[StartMainIndex].peaks.slice(
        StartTimeNearestBeatIndex,
        EndTimeNearestBeatIndex + 1
      );
    } else if (
      StartMainIndex > -1 &&
      EndMainIndex > -1 &&
      StartMainIndex !== EndMainIndex
    ) {
      let StartArray = SelectedDataSetAll[StartMainIndex].peaks.slice(
        StartTimeNearestBeatIndex,
        SelectedDataSetAll[StartMainIndex].peaks.length
      );
      let EndArray = SelectedDataSetAll[EndMainIndex].peaks.slice(
        0,
        EndTimeNearestBeatIndex + 1
      );
      ReturnArray = StartArray.concat(EndArray);
    }

    return ReturnArray;
  }

  function getBeatDataInRangeEDF(StartTime: any, EndTime: any, Signal: any) {
    //   console.log("StartTime: " + StartTime + " , EndTime: " + EndTime);
    let singleRowData = [] as any;
    if (isEDFData === true) {
      // The time when the data starts in seconds from 00:00
      let dataStartSeconds = 0;

      // Number of Seconds of data
      let numberOfDataRecords = decodedEDFData._header.nbDataRecords;
      let numberOfSignals = decodedEDFData._header.nbSignals;

      if (
        numberOfSignals > 0 &&
        numberOfSignals >= Signal + 1 &&
        numberOfDataRecords > 0
      ) {
        let minimumSeconds = dataStartSeconds;
        let maximumSeconds = dataStartSeconds + numberOfDataRecords;

        if (StartTime >= minimumSeconds && StartTime <= maximumSeconds) {
          // SecondIndex means the Index of seconds which is on the first Array
          let secondIndexStart = Math.floor(StartTime) - dataStartSeconds; //changed to ceil to fix length issue
          let secondIndexMax = Math.floor(EndTime) - dataStartSeconds;

          if (secondIndexMax > numberOfDataRecords - 1) {
            secondIndexMax = numberOfDataRecords - 1;
          }

          // If we need to loop signals, put everything below here inside the loop
          let numberOfSamples =
            decodedEDFData._header.signalInfo[Signal].nbOfSamples; //125;
          let secondsPerIndex = 1 / numberOfSamples;

          let signalIndex = 0;
          let fullBreak = false;
          for (let j = secondIndexStart; j <= secondIndexMax; j++) {
            // console.log("j: " + j);

            if (fullBreak) {
              break;
            }
            let rowStartIndex = 0;
            if (j === secondIndexStart) {
              // TODO: Check if there should be a -1 here since index starts at 0
              let decimalPortionOfStartTime = StartTime - Math.floor(StartTime);
              rowStartIndex = Math.floor(
                decimalPortionOfStartTime * numberOfSamples
              );
            }

            let timeOfThisRowInSeconds = j + dataStartSeconds;
            for (
              let k = rowStartIndex;
              k < decodedEDFData._physicalSignals[signalIndex][j].length;
              k++
            ) {
              // console.log("k: " + k);
              let subSecondTimeOfThisRecord = k * secondsPerIndex;
              let fullTimeOfThisRecord =
                timeOfThisRowInSeconds + subSecondTimeOfThisRecord;
              // if (fullTimeOfThisRecord > secondIndexMax) {
              //   fullBreak = true;
              //   break;
              // }
              if (fullTimeOfThisRecord >= EndTime) {
                fullBreak = true;
                break;
              }

              let l_singleRowData = [
                fullTimeOfThisRecord,
                decodedEDFData._physicalSignals[signalIndex][j][k],
              ];
              singleRowData.push(l_singleRowData);
            }
          }
        }
      }
    }
    return singleRowData;
  }

  function ProcessStats() {
    if (isEDFData === true) {
      /*    // console.log(decodedEDFData);

      // The time when the data starts in seconds from 00:00
      let dataStartSeconds = 0;

      let recordingDate = decodedEDFData._header.recordingDate;
      SelectedDataSet[0].date = moment(recordingDate).format("YYYY-MM-DD"); // will remove this when we get rid of jsondata
      setSelectedDate("D1 - " + moment(recordingDate).format("YYYY-MM-DD"));

      // Number of Seconds of data
      let numberOfDataRecords = decodedEDFData._header.nbDataRecords; // 3864;
      let numberOfSignals = decodedEDFData._header.nbSignals; //3;

      //  let StartTime = 500;
      //  let Interval = 21.6;
      //  let LineCount = 6;
      let Signal = 0;

      if (
        numberOfSignals > 0 &&
        numberOfSignals >= Signal + 1 &&
        numberOfDataRecords > 0
      ) {
        let minimumSeconds = dataStartSeconds;
        let maximumSeconds = dataStartSeconds + numberOfDataRecords;

        // This is from Signal Info edf.signalInfo[Signal].nbOfSamples
        let numberOfSamples =
          decodedEDFData._header.signalInfo[Signal].nbOfSamples; //125;

        // Min data - StartTime
        // Max data - StartTime + (Interval * LineCount)
        if (StartTime >= minimumSeconds && StartTime <= maximumSeconds) {
          let secondIndexStart = Math.ceil(StartTime) - dataStartSeconds; //changed to ceil to fix length issue
          let secondIndexMax =
            secondIndexStart + Math.ceil(Interval * LineCount);

          if (secondIndexMax > numberOfDataRecords - 1) {
            secondIndexMax = numberOfDataRecords - 1;
          }

          let outputIndex = 0;
          let nextInterval = StartTime + Interval;

          // If we need to loop signals, put everything below here inside the loop
          let numberOfSamples =
            decodedEDFData._header.signalInfo[Signal].nbOfSamples; //125;
          let secondsPerIndex = 1 / numberOfSamples;

          let signalIndex = 0;
          let fullBreak = false;
          let singleRowData = [] as any;
          for (let j = secondIndexStart; j <= secondIndexMax; j++) {
            if (fullBreak) {
              break;
            }
            let rowStartIndex = 0;
            if (j === secondIndexStart) {
              let decimalPortionOfStartTime = StartTime - Math.floor(StartTime);
              rowStartIndex = Math.floor(
                decimalPortionOfStartTime * numberOfSamples
              );
            }

            let timeOfThisRowInSeconds = j + dataStartSeconds;
            for (
              let k = rowStartIndex;
              k < decodedEDFData._physicalSignals[signalIndex][j].length;
              k++
            ) {
              let subSecondTimeOfThisRecord = k * secondsPerIndex;
              let fullTimeOfThisRecord =
                timeOfThisRowInSeconds + subSecondTimeOfThisRecord;
              if (fullTimeOfThisRecord > secondIndexMax) {
                fullBreak = true;
                break;
              }
              if (fullTimeOfThisRecord >= nextInterval) {
                outputIndex++;
                nextInterval = nextInterval + Interval;
                singleRowData.date = recordingDate;
                IndexList.push(singleRowData);
                singleRowData = [];
              }

              let l_singleRowData = [
                fullTimeOfThisRecord,
                decodedEDFData._physicalSignals[signalIndex][j][k],
              ];
              singleRowData.push(l_singleRowData);

            }
          }
        }
      }

      // Set the number of Rows of data available in this dataset
      setLoadedDataRows(Math.ceil(numberOfDataRecords / interval));

      setIndexedDataSet(IndexList.slice(0, LineCount));*/
    } else {
      var SetList = SelectedDataSetAllRef.current;
      if (!SetList) {
        return;
      }

      var PeaksList = [];
      var PeaksInitValue = 0;
      if (SetList.length > 0) {
        if (SetList[0].peaks.length > 0) {
          PeaksInitValue = SetList[0].peaks[0].value[0];
        }
      }
      // We take the bpm at intervals of 6 seconds
      var bpmInterval = 6;
      var PeaksNextBreakPoint = PeaksInitValue + bpmInterval;

      var SinglePeakList = [];

      for (var i = 0; i < SetList.length; i++) {
        // Split the Peaks data
        for (var k = 0; k < SetList[i].peaks.length; k++) {
          if (dataSetFormat === 2) {
            if (SetList[i].peaks[k].value) {
              if (
                SetList[i].peaks[k].value[0] >= PeaksInitValue &&
                SetList[i].peaks[k].value[0] <= PeaksNextBreakPoint
              ) {
                SinglePeakList.push(SetList[i].peaks[k]);
              } else {
                if (SetList[i].peaks[k].value[0] >= PeaksInitValue) {
                  PeaksList.push(SinglePeakList);
                  SinglePeakList = [];
                  PeaksInitValue = PeaksNextBreakPoint;
                  PeaksNextBreakPoint = PeaksNextBreakPoint + bpmInterval;
                  SinglePeakList.push(SetList[i].peaks[k]);
                }
              }
            }
          } else if (dataSetFormat === 1) {
            // TODO: Handle this for dataformat 1
            // if (
            //   SetList[i].peaks[k][0] >= PeaksInitValue &&
            //   SetList[i].peaks[k][0] <= PeaksNextBreakPoint + StartTime
            // ) {
            //   SinglePeakList.push(SetList[i].peaks[k]);
            // } else {
            //   if (SetList[i].peaks[k][0] >= PeaksInitValue) {
            //     PeaksList.push(SinglePeakList);
            //     SinglePeakList = [];
            //     PeaksInitValue = PeaksNextBreakPoint;
            //     PeaksNextBreakPoint = PeaksNextBreakPoint + Interval;
            //     SinglePeakList.push(SetList[i].peaks[k]);
            //   }
            // }
          }
        }
      }

      var localBPMList = [];
      var minBPM = 10000;
      var maxBPM = 0;

      if (PeaksList.length > 0) {
        // Loop through all the peaks split into 6 second groups and calculate their BPM
        for (let i = 0; i < PeaksList.length; i++) {
          if (PeaksList[i].length > 1) {
            var timeOfFirstBeat = PeaksList[i][0].value[0];
            var timeOfLastBeat = PeaksList[i][PeaksList[i].length - 1].value[0];
            var countOfBeats = PeaksList[i].length;

            var bpm =
              (60 * (countOfBeats - 1)) / (timeOfLastBeat - timeOfFirstBeat);
            let l_oBPMobject = {} as any;
            l_oBPMobject.StartTime = timeOfFirstBeat;
            l_oBPMobject.BPM = Math.round(bpm);
            if (bpm > maxBPM) {
              maxBPM = Math.round(bpm);
            }
            if (bpm < minBPM) {
              minBPM = Math.round(bpm);
            }
            localBPMList.push(l_oBPMobject);
          } else if (PeaksList[i].length > 0) {
            let l_oBPMobject = {} as any;
            l_oBPMobject.StartTime = PeaksList[i][0].value[0];
            l_oBPMobject.BPM = 0;
            localBPMList.push(l_oBPMobject);
          }
        }
      }

      /*
      var minuteBPMList = []; 
      for (var i = 0; i < SetList.length; i++) {
        var numberOfBeats = SetList[i].peaks.length;
        var timeOfFirstBeat = SetList[i].peaks[0].value[0];
        var timeOfLastBeat =
          SetList[i].peaks[SetList[i].peaks.length - 1].value[0];

        var bpm =
          (60 * (numberOfBeats - 1)) / (timeOfLastBeat - timeOfFirstBeat);
        console.log(bpm);
        let l_oBPMobject = {} as any;
            l_oBPMobject.Min = i;
            l_oBPMobject.BPM = bpm;
            minuteBPMList.push(l_oBPMobject);
      }
*/

      // console.log("bpm list");
      //      console.log("min: " + minBPM + " ,max: " + maxBPM);
      //      console.log(localBPMList);
      // return BPMList;

      let localMinutesHR = getAllBPMFromDataSet(true) as any;
      setallMinutesHR(localMinutesHR);

      setminBPM(minBPM);
      setmaxBPM(maxBPM);
      setBPMDataSet(localBPMList);

      //setIndexedPeaksDataSet(PeaksList.slice(0, LineCount));
    }
  }

  useEffect(() => {
    if (SelectedDataSetAll && SelectedDataSetAll.length > 0) {
      ProcessStats();
      //  PushAnnotationTypeDataInAnnotationArray();
    }
  }, [SelectedDataSetAll]);

  function PushAnnotationTypeDataInAnnotationArray() {
    let l_AllAnnotations = [];
    for (let i = 0; i < SelectedDataSetAllRef.current.length; i++) {
      for (let j = 0; j < SelectedDataSetAllRef.current[i].peaks.length; j++) {
        if (
          SelectedDataSetAllRef.current[i].peaks[j].classification === "N" ||
          SelectedDataSetAllRef.current[i].peaks[j].classification === "PVC"
        ) {
          let l_starttime = SelectedDataSetAllRef.current[i].peaks[j].value[0]; // current peak value of

          let Type = AnnotationTagType.Noise;

          if (
            SelectedDataSetAllRef.current[i].peaks[j].classification === "PVC"
          ) {
            Type = AnnotationTagType.Pause;
          }

          l_AllAnnotations.push(
            new Annotation(
              GenerateUniqueGUID(),
              1,
              Type,
              SelectedDataSet[0].date,
              l_starttime,
              0.5,
              1,
              0,
              false,
              0,
              false
            )
          );
        }
      }
    }

    setAllAnnotations(l_AllAnnotations);
  }

  // End of d3 stuff

  function MakeFullBMPList(fullData: any) {
    var newArray = [];
    var isFirst = true;
    var previousTime = 0;
    var lowestHR = 1000;
    var lowestHRTime = 0;
    var highestHR = 0;
    var highestHRTime = 0;

    for (let i = 0; i < fullData.length; i++) {
      var newPeaksArray = [];
      for (let j = 0; j < fullData[i].peaks.length; j++) {
        let thisPeak = fullData[i].peaks[j];

        if (isFirst) {
          isFirst = false;
        } else {
          // Since this isn't the first one, we can get the previousTime and work out the BPM
          let lastSectionBPM = Math.round(
            60 / (thisPeak.value[0] - previousTime)
          );
          // Also work out the Time of the BPM i.e. middway between the 2 peaks
          let timeOfBPM = (thisPeak.value[0] + previousTime) / 2;
          // Create the tuple to add to the array
          let newPair = [timeOfBPM, lastSectionBPM];
          newArray.push(newPair);

          // Check if this is the highest or the lowest HR
          if (lastSectionBPM > highestHR) {
            highestHR = lastSectionBPM;
            highestHRTime = timeOfBPM;
          }
          if (lastSectionBPM < lowestHR) {
            lowestHR = lastSectionBPM;
            lowestHRTime = timeOfBPM;
          }
        }
        previousTime = thisPeak.value[0];
      }
      let lowestHRTuple = [lowestHRTime, lowestHR];
      setlowestHR(lowestHRTuple);
      let highestHRTuple = [highestHRTime, highestHR];
      sethighestHR(highestHRTuple);
      //      console.log("highestHR: " + highestHR + " at " + highestHRTime);
      //      console.log("lowestHR: " + lowestHR + " at " + lowestHRTime);
      //      console.log(newArray);
      setAllBPMs(newArray);

      setLeftMenuDataObject((prevState: any) => ({
        ...prevState,
        MinHr: lowestHRTuple[1],
        MaxHr: highestHRTuple[1],
      }));
    }
  }

  function AssignData() {
    setDataForLeftMenu(props.dataSetForSelectedPatient);
    if (dataSetFormat === 2) {
      let JsonDataSet = [] as any;
      if (props.dataSetForSelectedPatient === 2) {
        JsonDataSet = JsonTestData2;
      } else if (props.dataSetForSelectedPatient === 3) {
        JsonDataSet = JsonTestData3;
      }

      //Make full day data
      let fullDayJsonData = [] as any;
      for (let i = 0; i < 24; i++) {
        let tempJsonData = [] as any;
        tempJsonData = JSON.parse(JSON.stringify(JsonDataSet));
        for (let j = 0; j < tempJsonData.length; j++) {
          for (let k = 0; k < tempJsonData[j].data.length; k++) {
            tempJsonData[j].data[k][0] = i * 3600 + tempJsonData[j].data[k][0];
          }

          for (let x = 0; x < tempJsonData[j].peaks.length; x++) {
            tempJsonData[j].peaks[x].value[0] =
              i * 3600 + tempJsonData[j].peaks[x].value[0];
          }
        }
        fullDayJsonData = fullDayJsonData.concat(tempJsonData) as any;
      }

      JsonDataSet = JSON.parse(JSON.stringify(fullDayJsonData));
      // End of Make full day

      setSelectedDataSetAll(JsonDataSet);
      MakeFullBMPList(JsonDataSet);
      let backupData = CopyPeaksData(JsonDataSet);
      setBackupDataSetAll(backupData);
      let JsonDataSetv1: any = JsonDataSet;
      let l_JsonTestData = JsonDataSetv1.filter(
        (a: any, i: any) =>
          JsonDataSetv1.findIndex((s: any) => a.date === s.date) === i
      );

      setSelectedDataSet(l_JsonTestData);
      setSelectedDate("D1 - " + l_JsonTestData[0].date);
      SetTimeOnDropDownChanged(l_JsonTestData);
    } else if (dataSetFormat === 1) {
      setSelectedDataSetAll(JsonTestData);
      let backupData = CopyPeaksData(JsonTestData);
      setBackupDataSetAll(backupData);
      let JsonTestDatav1: any = JsonTestData;
      let l_JsonTestData = JsonTestDatav1.filter(
        (a: any, i: any) =>
          JsonTestDatav1.findIndex((s: any) => a.date === s.date) === i
      );

      setSelectedDataSet(l_JsonTestData);
      setSelectedDate("D1 - " + l_JsonTestData[0].date);
      SetTimeOnDropDownChanged(l_JsonTestData);
    }

    //    MakeIndexedList(l_JsonTestData2, interval);
    MakeIndexedList(
      GraphStartTimeRef.current,
      intervalRef.current,
      LineCountRef.current
    );

    //To load main chart
    setUpdateScroll(true);
    setStartSignal(1);
  }

  function CopyPeaksData(originalData: any) {
    var newArray = [];
    for (let i = 0; i < originalData.length; i++) {
      var newPeaksArray = [];
      for (let j = 0; j < originalData[i].peaks.length; j++) {
        let thisPeak = originalData[i].peaks[j];
        let newObj = {
          value: [thisPeak.value[0], thisPeak.value[1]],
          classification: thisPeak.classification,
        };
        newPeaksArray.push(newObj);
      }
      let newPeakObject = { peaks: newPeaksArray };
      newArray.push(newPeakObject);
    }
    return newArray;
  }

  // TODO: Change this to new MakeIndexedList
  function LoadDataSetAccordingToChannel(
    p_intChannelId: any,
    p_strChannelName: any
  ) {
    setSelectedChannel({
      ID: p_intChannelId,
      Name: p_strChannelName,
    });
    if (p_intChannelId === 1) {
      // setSelectedDataSet(TestData2);
      // MakeIndexedList(TestData2, interval);
      // setSelectedDate("D1 - " + TestData2[0].date);
      // SetTimeOnDropDownChanged(TestData2);
    } else if (p_intChannelId === 2) {
      // MakeIndexedList(TestData, interval);
      // setSelectedDataSet(TestData);
      // setSelectedDate("D1 - " + TestData[0].date);
      // SetTimeOnDropDownChanged(TestData);
    }

    ShowHideDropDown(false, false, false, false);
  }

  function SetTimeOnDropDownChanged(p_DataSet: any) {
    let l_strDate = "";
    let l_strHrs = "";
    let l_strMins = "";

    if (p_DataSet[0].hour < 10) {
      l_strHrs = "0" + p_DataSet[0].hour;
    } else {
      l_strHrs = p_DataSet[0].hour;
    }
    if (p_DataSet[0].minute < 10) {
      l_strMins = "0" + p_DataSet[0].minute;
    } else {
      l_strMins = p_DataSet[0].minute;
    }
    l_strDate = l_strHrs + ":" + l_strMins + ":00";
    setSelectedTime(l_strDate);
  }

  function handlePointClick(seconds: any) {
    let convertedTime = Utility.ConvertSecondsIntoTimeFormat(seconds);
    setSelectedTime(convertedTime);
  }

  function ShowHideDropDown(
    p_boolMV: any,
    p_boolMS: any,
    p_boolChannel: any,
    p_boolDate: any
  ) {
    setDropDown({
      MV: p_boolMV,
      MS: p_boolMS,
      Channel: p_boolChannel,
      Date: p_boolDate,
    });
  }
  function StudyDay(p_intIndex: any, p_strSelectedDate: any) {
    let index = p_intIndex + 1;
    setSelectedDate("D" + index + " - " + p_strSelectedDate);
    ShowHideDropDown(false, false, false, false);
    alert("StudyDay");
  }

  function ChangeDateTime() {
    if (IsValidTime(SelectedTime)) {
      ///alert("ChangeDateTime: " + SelectedTime);
      //check time exist in dataset

      var l_selectedTime = SelectedTime.split(":");
      var l_Hrs = GetTimeWithoutPrefix(l_selectedTime[0]);
      var l_Mins = GetTimeWithoutPrefix(l_selectedTime[1]);
      var l_Secs = GetTimeWithoutPrefix(l_selectedTime[2]) + l_Mins * 60;
      var TimeFound = false;
      for (let i = 0; i < SelectedDataSetAllRef.current.length; i++) {
        if (TimeFound) {
          break;
        }
        if (l_Hrs === SelectedDataSetAllRef.current[i].hour) {
          if (l_Mins === SelectedDataSetAllRef.current[i].minute) {
            for (
              let j = 0;
              j < SelectedDataSetAllRef.current[i].data.length;
              j++
            ) {
              if (
                l_Secs ===
                Math.floor(SelectedDataSetAllRef.current[i].data[j][0])
              ) {
                //Time found
                TimeFound = true;
                break;
              }
            }
          }
        }
      }

      if (TimeFound) {
        var newStartTime = Math.floor(l_Secs / interval) * interval;
        setGraphStartTime(newStartTime);
        setStartOfSplitBox(newStartTime);
        setUpdateScroll(true);
        MakeIndexedList(
          newStartTime,
          intervalRef.current,
          LineCountRef.current
        );
      } else {
        alert("The loaded dataset is from 04:00:00 to 20:00:00");
      }
    } else {
      alert("Please enter time in correct format.");
    }
  }

  function onEnterPress(e: any) {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      ChangeDateTime();
    }
  }

  function ChackTimeValidaton() {
    if (!IsValidTime(SelectedTime)) {
      alert("Please enter time in correct format.");
    }
  }

  function IsValidTime(p_strTime: any) {
    var IsValid = moment(p_strTime, "HH:mm:ss", true).isValid();
    return IsValid;
  }

  function GetTimeWithoutPrefix(p_time: any) {
    if (p_time === "00") {
      return 0;
    } else if (p_time === "01") {
      return 1;
    } else if (p_time === "02") {
      return 2;
    } else if (p_time === "03") {
      return 3;
    } else if (p_time === "04") {
      return 4;
    } else if (p_time === "05") {
      return 5;
    } else if (p_time === "06") {
      return 6;
    } else if (p_time === "07") {
      return 7;
    } else if (p_time === "08") {
      return 8;
    } else if (p_time === "09") {
      return 9;
    } else {
      return parseInt(p_time);
    }
  }

  /* ===== START of Measurements data management functions ===== */

  function PushMeasurementInArray(
    StartTime: any,
    Duration: any,
    Beats: any,
    BPM: any
  ) {
    let Measurementobject = {} as any;
    Measurementobject.ID = GenerateUniqueGUID();
    Measurementobject.StartTime = StartTime;
    Measurementobject.Duration = Duration;
    Measurementobject.Beats = Beats;
    Measurementobject.BPM = BPM;
    setAllMeasurements([...AllMeasurementsRef.current, Measurementobject]);
  }

  function ViewAnnotation(p_StartTime: any, index: number) {
    //    console.log("StartTime : " + p_StartTime);
    //    console.log("index : " + index);

    let newStartTime = p_StartTime;
    let localInterval = intervalRef.current;
    if (newStartTime != 0) {
      let remainder = newStartTime % localInterval;
      newStartTime = newStartTime - remainder;
    }

    setSelectedAnnotationIndex(index);
    setGraphStartTime(newStartTime);
    setStartOfSplitBox(newStartTime);
    setUpdateScroll(true);
    MakeIndexedList(newStartTime, localInterval, LineCountRef.current);
  }

  function handleMoveGraph(StartTime: any, Duration: any) {
    let localInterval = intervalRef.current;
    let newStartTime = StartTime;

    if (newStartTime != 0) {
      let remainder = newStartTime % localInterval;
      newStartTime = newStartTime - remainder;
    }

    let convertedTime = Utility.ConvertSecondsIntoTimeFormat(StartTime);
    setSelectedTime(convertedTime);

    // Set the Rect to be shown on the graph
    setclickRectStart(StartTime);

    setGraphStartTime(newStartTime);
    setStartOfSplitBox(newStartTime);
    setUpdateScroll(true);
    MakeIndexedList(newStartTime, localInterval, LineCountRef.current);
  }

  function ShowAnnotationsDetail(p_intType: any) {
    //ShowDeleteOrClearAllDiv(-1);
    if (AnnotationType === p_intType) {
      setAnnotationType("");
    } else {
      setAnnotationType(p_intType);
    }
  }

  function ViewMeasurement(p_StartTime: any, index: number) {
    let newStartTime = p_StartTime;
    let localInterval = intervalRef.current;
    if (newStartTime != 0) {
      let remainder = newStartTime % localInterval;
      newStartTime = newStartTime - remainder;
    }

    setSelectedMeasurementIndex(index);
    setGraphStartTime(newStartTime);
    setStartOfSplitBox(newStartTime);
    setUpdateScroll(true);
    MakeIndexedList(newStartTime, localInterval, LineCountRef.current);
  }

  /* ===== END of Measurements data management functions ===== */

  function changeCurrentControl(newValue: any) {
    // Before we change the control, lets clear anything drawn
    clearTempStuff();
    setcurrentControl(newValue);
  }

  function EcgGraphClick() {
    changeCurrentControl(CurrentTool.Pointer);
  }

  function HorizontalCaliperTool() {
    resetVariables();
    if (currentControl !== CurrentTool.Caliper) {
      changeCurrentControl(CurrentTool.Caliper);
    } else {
      changeCurrentControl(CurrentTool.Pointer);
    }
    ShowHideBottomDiv(false, false, false);
    setBeatDiv(false);
  }

  function TagTool() {
    resetVariables();
    if (currentControl !== CurrentTool.TagTool) {
      changeCurrentControl(CurrentTool.TagTool);
    } else {
      changeCurrentControl(CurrentTool.Pointer);
    }
    ShowHideBottomDiv(false, false, false);
    setBeatDiv(false);
  }

  function BeatAnnotationTool() {
    resetVariables();
    if (currentControl !== CurrentTool.AnnotationTool) {
      changeCurrentControl(CurrentTool.AnnotationTool);
    } else {
      changeCurrentControl(CurrentTool.Pointer);
    }
    ShowHideBottomDiv(false, false, false);
    setBeatDiv(false);
  }

  function EcgInvert() {
    if (invertGraph === 1) {
      setinvertGraph(0);
    } else {
      setinvertGraph(1);
    }
  }

  function ShowHideGrid() {
    if (showGrid === 1) {
      setshowGrid(0);
    } else {
      setshowGrid(1);
    }
  }

  function ShowHideRuler() {
    if (showRuler === 1) {
      setshowRuler(0);
    } else {
      setshowRuler(1);
    }
  }
  function SignalStartTool() {
    alert("StartSignal");
    // setUpdateScroll(true);
    // setStartSignal(1);
    //PushSVEAndVEInAnnotationArray();
  }

  function SignalStartPrevious() {
    alert("SignalStartPrevious");
  }

  function SignalStartNext() {
    alert("SignalStartNext");
  }

  function ChangeAmplitude() {
    ShowHideDropDown(false, false, false, false);
  }

  function ChangeSpeed() {
    ShowHideDropDown(false, false, false, false);
  }

  function ShowHideTrends() {
    //getAllBPMFromDataSet();
    ShowHideBottomDiv(!BottomDiv.HRTrendView, false, false);
    changeCurrentControl(CurrentTool.Pointer);
  }
  function ShowHideHRTrends1() {
    SetShoweHRTrends1(true);
    SetShoweHRTrends2(false);
  }
  function ShowHideHRTrends2() {
    SetShoweHRTrends1(false);
    SetShoweHRTrends2(true);
  }

  function ShowHideLeftMenu() {
    setTimeout(() => {
      changeGraphsAfterResize();
    }, 100);
  }

  function ShowHideTemplates() {
    ShowHideBottomDiv(false, !BottomDiv.BottomDiv2, false);
    changeCurrentControl(CurrentTool.Pointer);
  }
  function ShowHideBeatsView() {
    setBeatDiv(!BeatDiv);
    changeCurrentControl(CurrentTool.Pointer);
  }
  function UndoDelete() {
    // TODO: This will need to be updated if we're undoing multiple deletes
    if (DeletedAnnotations && DeletedAnnotations.length > 0) {
      var index = DeletedAnnotations.length - 1;
      setAnnotationPeaksInRange(
        DeletedAnnotations[index].StartTime,
        DeletedAnnotations[index].StartTime +
          DeletedAnnotations[index].Duration,
        DeletedAnnotations[index].Type
      );
    }

    setAllAnnotations([...AllAnnotations, ...DeletedAnnotations]);
    setDeletedAnnotations([]);
  }

  function activateUndo() {
    // Make undo visible
    // Save deleted Annotation Array - will work for ClearAll Tool
  }

  function ShowStripsPanel() {
    setShowStrips(true);
  }
  function HideStripsPanel() {
    setShowStrips(false);
  }

  function OnOffEcgSplitView() {
    if (BottomDiv.ECGSplitView === true) {
      setSplitBoxDragStarted(false);
    }
    ShowHideBottomDiv(false, false, !BottomDiv.ECGSplitView);
    changeCurrentControl(CurrentTool.Pointer);
    //    alert("OnOffEcgSplitView");
    //LoadSplitView();
  }

  /*

  1  (22.6 / 21.6)

  newScrollY = RowNumber/160 * 20;

  10 / 353

  let newRowNumber =  (localScrollY / 353) * 160;


  let newStartTime = newRowNumber * intervalRef.current;

 /*
 


 ScrollY - from : 23.3125 to 25.64375
ECG.js:2141 newScrollY: 25.64375 StartTime: 258.39688385269125 Interval: 21.6 LoadedDataRowsRef.current: 166
ECG.js:2142 scrollHeightRef.current: 373 scrollYRef.current: 23.3125
ECG.js:1454 localScrollY: 25.64375 h: 373 scrollBoxHeight: 20 LoadedDataRowsRef.current: 166
ECG.js:1455 newRowNumber: 12.059100566572239 newStartTime: 260.4765722379604 intervalRef.current: 21.6
ECG.js:2135 ScrollY - from : 25.64375 to 27.974999999999998
ECG.js:2141 newScrollY: 27.974999999999998 StartTime: 260.4765722379604 Interval: 21.6 LoadedDataRowsRef.current: 166
ECG.js:2142 scrollHeightRef.current: 373 scrollYRef.current: 25.64375
ECG.js:1454 localScrollY: 27.974999999999998 h: 373 scrollBoxHeight: 20 LoadedDataRowsRef.current: 166
ECG.js:1455 newRowNumber: 13.155382436260622 newStartTime: 284.15626062322946 intervalRef.current: 21.6
ECG.js:2135 ScrollY - from : 27.974999999999998 to 30.306250000000002
 */

  function jumpStartTime(NumberOfRows: number) {
    let localInterval = intervalRef.current;
    let localLineCount = LineCountRef.current;
    let newStartTime = GraphStartTimeRef.current + localInterval * NumberOfRows;

    // TODO - check this against data start time instead of 0, if reached then don't decrease
    if (newStartTime < 0) {
      newStartTime = 0;
    }

    // Stop increasing rows once last row is visible
    let lineNumber = newStartTime / localInterval;
    let maxLineNumber = LoadedDataRowsRef.current - localLineCount + 1;
    if (lineNumber > maxLineNumber) {
      newStartTime = maxLineNumber * localInterval;
    }

    setGraphStartTime(newStartTime);
    setStartOfSplitBox(newStartTime);
    setUpdateScroll(true);
    MakeIndexedList(newStartTime, localInterval, localLineCount);
  }

  function ShowHideBottomDiv(
    p_boolHRTrendView: any,
    p_boolBottomDiv2: any,
    p_boolECGSplitView: any
  ) {
    setBottomDiv({
      HRTrendView: p_boolHRTrendView,
      BottomDiv2: p_boolBottomDiv2,
      ECGSplitView: p_boolECGSplitView,
    });
  }

  useEffect(() => {
    if (props.EDFBuffer) {
      readEdfFile(props.EDFBuffer);
    }
  }, [props.EDFBuffer]);

  function detectRPeaks(ecgData: number[], samplingRate: number): number[] {
    // Differentiation
    const diffData = ecgData.map((value, index, array) => {
      if (index === 0) return 0;
      return value - array[index - 1];
    });

    // Squaring
    const squaredData = diffData.map((value) => value * value);

    // Moving Window Integration
    const mwiWindow = Math.round(samplingRate * 0.15); // 150 ms window
    const mwiData = [];
    for (let i = 0; i < squaredData.length; i++) {
      let sum = 0;
      for (
        let j = Math.max(0, i - mwiWindow);
        j <= Math.min(i + mwiWindow, squaredData.length - 1);
        j++
      ) {
        sum += squaredData[j];
      }
      mwiData.push(sum);
    }
    console.log("mwiData.length : " + mwiData.length);
    // Peak detection
    const threshold = 0.6 * arrayMax(mwiData); //Math.max(...mwiData);
    var rPeaks = [1, 2];
    for (let j = 0; j < 100000; j++) {
      if (mwiData[j] > threshold) {
        rPeaks.push(j);
      }
    }
    console.log("5");

    return rPeaks;
  }

  function arrayMax(arr: any) {
    var len = arr.length,
      max = -Infinity;
    while (len--) {
      if (arr[len] > max) {
        max = arr[len];
      }
    }
    return max;
  }

  function getSquaredSignal(ecgSignal: number[]) {
    const derivative: number[] = [];
    const squaredSignal: number[] = [];
    // Differentiation
    for (let i = 0; i < ecgSignal.length - 1; i++) {
      derivative.push(ecgSignal[i + 1] - ecgSignal[i]);
    }
    console.log("p 2 derivative: " + derivative.length);
    // Squaring
    for (let i = 0; i < derivative.length; i++) {
      squaredSignal.push(derivative[i] * derivative[i]);
    }
    return squaredSignal;
  }

  function panTompkinsQRS(ecgSignal: number[], samplingRate: number): number[] {
    const WINDOW_SIZE = Math.round(0.12 * samplingRate);
    const DERIVATIVE_WINDOW_SIZE = Math.round(0.04 * samplingRate);
    const SQUARING_WINDOW_SIZE = Math.round(0.15 * samplingRate);
    const MOVING_INTEGRAL_WINDOW_SIZE = Math.round(0.2 * samplingRate);

    // const derivative: number[] = [];
    //const squaredSignal: number[] = [];
    const integratedSignal: number[] = [];
    console.log("p 1");
    // Differentiation
    // for (let i = 0; i < ecgSignal.length - 1; i++) {
    //   derivative.push(ecgSignal[i + 1] - ecgSignal[i]);
    // }
    // console.log("p 2 derivative: " + derivative.length);
    // // Squaring
    // for (let i = 0; i < derivative.length; i++) {
    //   squaredSignal.push(derivative[i] * derivative[i]);
    // }
    const squaredSignal = getSquaredSignal(ecgSignal);
    console.log("p 3 squaredSignal: " + squaredSignal.length);
    // Moving Window Integration
    for (let i = 0; i < squaredSignal.length; i++) {
      let sum = 0;
      for (let j = i - MOVING_INTEGRAL_WINDOW_SIZE + 1; j <= i; j++) {
        if (j >= 0) {
          sum += squaredSignal[j];
        }
      }
      integratedSignal.push(sum);
    }

    // Find the maximum in the integrated signal
    //    const maxIntegrated = Math.max(...integratedSignal);

    let maxIntegrated: number = 0;
    for (let k = 100; k < integratedSignal.length; k++) {
      if (integratedSignal[k] > maxIntegrated) {
        maxIntegrated = integratedSignal[k];
      }
    }

    // Set a threshold for QRS detection
    const threshold = 0.5 * maxIntegrated;
    console.log("p 4 integratedSignal : " + integratedSignal.length);
    // Detect R-peaks (QRS complexes)
    const qrsPeaks: number[] = [];
    for (let i = 0; i < integratedSignal.length; i++) {
      if (integratedSignal[i] > threshold) {
        qrsPeaks.push(i);
      }
    }
    console.log("p 5 qrsPeaks: " + qrsPeaks.length);
    console.log(qrsPeaks);

    return qrsPeaks;
  }

  async function readEdfFile(buff: any) {
    var decoder: any = new EdfDecoder();
    decoder.setInput(buff);
    decoder.decode();
    var output = decoder.getOutput();
    console.log("EDF File: ");
    console.log(output);
    let array = [];
    for (let i = 0; i < 120; i++) {
      for (let j = 0; j < 125; j++) {
        array.push(output._physicalSignals[0][i][j]);
      }
    }

    let ecgValues = array; //[0, 0, 0, 0, 0, 0];
    console.log("data sent to API: ");
    console.log(ecgValues);
    let returnData = await getDataFromAPI(ecgValues, 125);
    console.log("data coming back from API: ");
    console.log(returnData);
    let obj = {} as any;
    let beatArray = [];
    for (let i = 0; i < returnData.data.beats.length; i++) {
      obj = {};

      let X =
        ((returnData.data.beats[i].beat_end_x -
          returnData.data.beats[i].beat_start_x) /
          2 +
          returnData.data.beats[i].beat_start_x) /
        125;
      obj.classification =
        returnData.data.beats[i].beat_classification_tag_short;
      obj.classificationID = 1;
      obj.value = [X, 100];
      beatArray.push(obj);
    }

    setIsEDFData(true);

    console.log("preprocessing start");
    // TODO: Put checks in here to confirm there is data and also which signal to pick up
    let denoisedDataobject: any = preprocessECG(output._physicalSignals[0], 40);

    console.log("denoisedDataobject");
    console.log(denoisedDataobject);

    // Put double array into this variable
    output._physicalSignals[0] = denoisedDataobject.returnData;

    //denoisedDataobject.combinedData

    /*while (denoisedData.length) {
      // TODO: change the 125 to number of signal
      output._physicalSignals[0].push(denoisedData.splice(0, 125));
      console.log(denoisedData.length);
    }*/

    setDecodedEDFData(output);

    setAllAnnotations(beatArray);

    setPeaksDataSet(beatArray);
    setpeakDetectionDone(true);

    props.setProgressBarCoutDown(100);
    console.log("preprocessing end");
  }

  async function getDataFromAPI(ecgValues: any, sampleRate: number) {
    let object = {} as any;
    object.ecgValues = ecgValues;
    object.sampleRate = sampleRate;
    let returnData: any = [];
    await axiosAuth
      .post(
        "https://u7xsb7k4m9.execute-api.ap-south-1.amazonaws.com/impactv2/ecg",
        object
      )
      .then((response) => {
        returnData = response.data;
        setApiReturnData(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        return (returnData = []);
      });
    return returnData;
  }

  useEffect(() => {
    if (decodedEDFData) {
      setStartOfSplitBox(0);
      // setAllAnnotations([]);
      setAllMeasurements([]);
      setIndexedDataSet([]);
      setIndexedPeaksDataSet([]);
      setIndexedExtraDataSet([]);
      let newDataStartTime = 0;
      setGraphStartTime(newDataStartTime);
      MakeIndexedList(
        newDataStartTime,
        intervalRef.current,
        LineCountRef.current
      );
      setTimeout(() => {
        props.setProgressBarCoutDown(0);
      }, 800);
    }
  }, [decodedEDFData]);

  useEffect(() => {
    setECGTemplates([
      {
        ECGTemplatesID: "Alpha1",
        VType: 1,
        Class: 1,
        Data: [
          [0.006, 224.8175194011],
          [0.018, 352.0273988383],
          [0.038, 456.8337142805],
          [0.062, 408.5820978005],
          [0.09, -36.87133624],
          [0.108, -195.4284940905],
          [0.124, -224.6578025168],
          [0.216, -41.5256241997],
          [0.242, -58.5347458299],
          [0.256, -7.1328757036],
          [0.306, -29.9088866611],
          [0.378, -0.7239263609],
          [0.41, -28.0064115688],
          [0.44, -18.0758313407],
          [0.478, 121.5095088688],
          [0.502, 7.9068556558],
          [0.516, 14.1959294205],
          [0.528, -72.7225125132],
          [0.546, -109.4127099183],
          [0.582, -87.0917099772],
          [0.598, -119.5409813878],
          [0.606, -57.5502324185],
          [0.622, 681.3560595702],
          [0.628, 1183.2816490576],
          [0.632, 1045.2855742707],
          [0.646, -929.2792678467],
          [0.652, -917.6072159192],
          [0.664, -210.7085942333],
          [0.674, -43.7408370545],
          [0.744, -68.748907955],
          [0.76, -11.0113246761],
          [0.788, 22.3735904856],
          [0.814, 178.2099755636],
          [0.848, 439.8339759219],
          [0.864, 462.8324142448],
          [0.876, 408.6792461774],
          [0.912, -112.4928089226],
          [0.926, -211.6770811823],
          [0.946, -233.4578922625],
        ],
      },
      {
        ECGTemplatesID: "Alpha2",
        VType: 1,
        Class: 2,
        Data: [
          [0.016, -66.8646535156],
          [0.096, 1.4786737416],
          [0.126, -18.8023960158],
          [0.246, -31.6527545115],
          [0.26, 31.5251227561],
          [0.288, 77.6681265638],
          [0.296, 128.2689145365],
          [0.316, 9.8061650689],
          [0.328, 10.9409271895],
          [0.348, -88.2477493139],
          [0.414, -102.5210284843],
          [0.422, 23.0270414619],
          [0.436, 708.6655721228],
          [0.442, 1155.4808256826],
          [0.444, 1123.7284902032],
          [0.46, -984.0718389146],
          [0.464, -991.584497984],
          [0.478, -181.8673573602],
          [0.482, -76.1604080515],
          [0.51, -41.5058963071],
          [0.566, -42.8252001723],
          [0.592, -0.3148936648],
          [0.614, 91.8357284196],
          [0.652, 379.0981376227],
          [0.672, 465.3960358471],
          [0.692, 380.2783391598],
          [0.726, -135.4861268241],
          [0.742, -207.8962013174],
          [0.786, -173.3253530609],
          [0.82, -68.5614723013],
          [0.826, -88.7053000795],
          [0.898, 4.2644077986],
          [0.926, -25.1840602015],
          [0.938, 2.9686391525],
          [0.968, -29.8407264065],
        ],
      },
      {
        ECGTemplatesID: "Alpha3",
        VType: 2,
        Class: 1,
        Data: [
          [0.006, 224.8175194011],
          [0.018, 352.0273988383],
          [0.038, 456.8337142805],
          [0.062, 408.5820978005],
          [0.09, -36.87133624],
          [0.108, -195.4284940905],
          [0.124, -224.6578025168],
          [0.216, -41.5256241997],
          [0.242, -58.5347458299],
          [0.256, -7.1328757036],
          [0.306, -29.9088866611],
          [0.378, -0.7239263609],
          [0.41, -28.0064115688],
          [0.44, -18.0758313407],
          [0.478, 121.5095088688],
          [0.502, 7.9068556558],
          [0.516, 14.1959294205],
          [0.528, -72.7225125132],
          [0.546, -109.4127099183],
          [0.582, -87.0917099772],
          [0.598, -119.5409813878],
          [0.606, -57.5502324185],
          [0.622, 681.3560595702],
          [0.628, 1183.2816490576],
          [0.632, 1045.2855742707],
          [0.646, -929.2792678467],
          [0.652, -917.6072159192],
          [0.664, -210.7085942333],
          [0.674, -43.7408370545],
          [0.744, -68.748907955],
          [0.76, -11.0113246761],
          [0.788, 22.3735904856],
          [0.814, 178.2099755636],
          [0.848, 439.8339759219],
          [0.864, 462.8324142448],
          [0.876, 408.6792461774],
          [0.912, -112.4928089226],
          [0.926, -211.6770811823],
          [0.946, -233.4578922625],
        ],
      },
    ]);

    setAllEctopies([
      {
        EctopyID: "Beta1",
        VType: 1,
        Class: 1,
        Data: [],
        Time: 5.63,
      },
      {
        EctopyID: "Beta2",
        VType: 1,
        Class: 2,
        Data: [],
        Time: 26.652,
      },
      {
        EctopyID: "Beta3",
        VType: 1,
        Class: 1,
        Data: [],
        Time: 13.872,
      },
      {
        EctopyID: "Beta4",
        VType: 1,
        Class: 2,
        Data: [],
        Time: 229.138,
      },
      {
        EctopyID: "Beta5",
        VType: 1,
        Class: 1,
        Data: [],
        Time: 256.358,
      },
      {
        EctopyID: "Beta6",
        VType: 1,
        Class: 2,
        Data: [],
        Time: 40.774,
      },
      {
        EctopyID: "Beta7",
        VType: 1,
        Class: 2,
        Data: [],
        Time: 55.81,
      },
      {
        EctopyID: "Beta8",
        VType: 1,
        Class: 2,
        Data: [],
        Time: 221.152,
      },
      {
        EctopyID: "Beta9",
        VType: 1,
        Class: 2,
        Data: [],
        Time: 218.776,
      },
    ]);
  }, []);

  function PushSVEAndVEInAnnotationArray() {
    let tempEctopies = [];
    for (let i = 0; i < AllEctopies.length; i++) {
      let type = 0;
      if (AllEctopies[i].VType === 1) {
        type = AnnotationTagType.VE;
      } else if (AllEctopies[i].VType === 2) {
        type = AnnotationTagType.SVE;
      }

      let Annotationobject = new Annotation(
        GenerateUniqueGUID(),
        2,
        type,
        SelectedDataSet[0].date,
        AllEctopies[i].Time,
        1,
        3,
        0,
        false,
        false,
        false
      );

      tempEctopies.push(Annotationobject);
    }
    setAllAnnotations(tempEctopies); //[...AllAnnotations, Annotationobject]
  }

  function download() {
    var filecontent = "hello this is test file content.";
    var filename = "holter.json";
    //Utility.WriteAndDownloadFile(filename, filecontent);

    //fetchFile('sample.EDF', 'sample.EDF')
  }

  const [isStripeToogleView, setIsStripeToogleView] = useState(false);

  function ToogleView() {
    //view: boolean
    //setIsStripeToogleView(view);
    setIsStripeToogleView(!isStripeToogleView);
  }

  let tempArray = [
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab",
      2,
      1,
      "2022-09-13",
      103.52,
      5.953488372093024,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c49",
      2,
      1,
      "2022-09-13",
      106.846,
      5.126614987080104,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a",
      2,
      1,
      "2022-09-13",
      110.036,
      6.583979328165371,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab1",
      2,
      1,
      "2022-09-13",
      113.968,
      5.953488372093021,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c491",
      2,
      1,
      "2022-09-13",
      117.186,
      5.126614987080101,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a1",
      2,
      1,
      "2022-09-13",
      119.648,
      6.583979328165371,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab2",
      2,
      1,
      "2022-09-13",
      122.83,
      5.953488372093022,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c492",
      2,
      1,
      "2022-09-13",
      125.96,
      5.126614987080102,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a2",
      2,
      1,
      "2022-09-13",
      129.938,
      6.583979328165372,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab6",
      2,
      1,
      "2022-09-13",
      133.84,
      5.953488372093026,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c496",
      2,
      1,
      "2022-09-13",
      137.71,
      5.126614987080106,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a6",
      2,
      1,
      "2022-09-13",
      141.444,
      6.583979328165376,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab3",
      2,
      1,
      "2022-09-13",
      145.348,
      5.953488372093023,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c493",
      2,
      1,
      "2022-09-13",
      150.436,
      5.126614987080103,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a3",
      2,
      1,
      "2022-09-13",
      154.994,
      6.583979328165373,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab4",
      2,
      1,
      "2022-09-13",
      158.038,
      5.9534883720930254,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c494",
      2,
      1,
      "2022-09-13",
      161.062,
      5.1266149870801054,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a4",
      2,
      1,
      "2022-09-13",
      164.196,
      6.583979328165374,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab5",
      2,
      1,
      "2022-09-13",
      170.344,
      5.953488372093025,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c495",
      2,
      1,
      "2022-09-13",
      174.23,
      5.126614987080105,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a5",
      2,
      1,
      "2022-09-13",
      178.094,
      6.5839793281653765,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f41657a5-1386-4254-96e9-672fb42598ef",
      2,
      2,
      "2022-09-13",
      181.974,
      7.5839793281653765,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "e277840a-2754-4983-9c69-f1af85696377",
      2,
      4,
      "2022-09-13",
      188.406,
      9.516795865633075,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c4938",
      2,
      1,
      "2022-09-13",
      193.232,
      5.126614987080103,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a318",
      2,
      1,
      "2022-09-13",
      200.262,
      6.583979328165373,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab48",
      2,
      1,
      "2022-09-13",
      203.398,
      5.9534883720930254,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c4948",
      2,
      1,
      "2022-09-13",
      209.11,
      5.1266149870801054,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a8",
      2,
      1,
      "2022-09-13",
      212.284,
      6.583979328165374,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f94a7314-f290-446f-a408-636f6e846cab59",
      2,
      1,
      "2022-09-13",
      218.776,
      5.953488372093025,
      5,
      59,
      false,
      0,
      false
    ),
    new Annotation(
      "584c74d3-63e9-430f-b30b-148c69889c4959",
      2,
      1,
      "2022-09-13",
      223.534,
      5.126614987080105,
      6,
      63,
      false,
      0,
      false
    ),
    new Annotation(
      "d6b23b98-718f-446d-b8c4-c75d476c6b0a59",
      2,
      1,
      "2022-09-13",
      225.914,
      6.5839793281653765,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "f41657a5-1386-4254-96e9-672fb42598ef9",
      2,
      1,
      "2022-09-13",
      231.522,
      7.5839793281653765,
      8,
      64,
      false,
      0,
      false
    ),
    new Annotation(
      "e277840a-2754-4983-9c69-f1af856963779",
      2,
      1,
      "2022-09-13",
      236.888,
      9.516795865633075,
      8,
      64,
      false,
      0,
      false
    ),
  ];

  useEffect(() => {
    setAllAnnotations(tempArray);
  }, []);

  function ReturnAnnotationsCount(p_intType: any) {
    let l_intCount = 0;
    for (let i = 0; i < AllAnnotations.length; i++) {
      if (AllAnnotations[i].Type === p_intType && AllAnnotations[i].IsStripe) {
        l_intCount++;
      }
    }
    return l_intCount;
  }

  function getSelectedRowsAndColumns(childData: any) {
    let rows = childData.Rows;
    let columns = childData.Columns;
    setIsGridViewInfo(childData);
  }

  useEffect(() => {
    let AFCount = getCountAccordingToType(AnnotationTagType.AF);
    let PauseCount = getCountAccordingToType(AnnotationTagType.Pause);
    if (AFCount > 0) {
      setLeftMenuDataObject((prevState: any) => ({
        ...prevState,
        Afib: AFCount,
        Pause: PauseCount,
      }));
    }
    setReportedStripCount(getReportedStripCount());
  }, [AllAnnotations]);

  function getReportedStripCount() {
    //will update when we will remove checks from StripsPanel.txs
    let l_intCount = 0;
    for (let i = 0; i < AllAnnotations.length; i++) {
      if (
        AllAnnotations[i].Type !== AnnotationTagType.AF ||
        (AllAnnotations[i].Type === AnnotationTagType.AF &&
          AllAnnotations[i].IsStripe)
      ) {
        l_intCount++;
      }
    }
    return l_intCount;
  }

  function getCountAccordingToType(p_intType: any) {
    let l_intCount = 0;
    for (let i = 0; i < AllAnnotations.length; i++) {
      if (AllAnnotations[i].Type === p_intType) {
        l_intCount++;
      }
    }
    return l_intCount;
  }

  useEffect(() => {
    let sinus = AllAnnotations.filter((o: any) => o.Type === 9 && o.IsStripe);

    let afib = AllAnnotations.filter((o: any) => o.Type === 1 && o.IsStripe);

    setSinusData(sinus);
    setAfibData(afib);
  }, [AllAnnotations]);

  return (
    <>
      {/* <input type="file" name="file" id="file" onChange={(e) => openFile(e)} /> */}

      <div
        className={`ecg--main ${
          (BottomDiv.HRTrendView === true && BeatDiv === true) ||
          (BottomDiv.ECGSplitView === true && BeatDiv === true)
            ? "ecg--main--sm"
            : ""
        }`}
      >
        {props.isLeftMenu && (
          <LeftMenu
            allAnnotations={AllAnnotations}
            allMeasurements={AllMeasurements}
            allEctopies={AllEctopies}
            ecgTemplates={ECGTemplates}
            setAllMeasurements={setAllMeasurements}
            ViewAnnotation={ViewAnnotation}
            ViewMeasurement={ViewMeasurement}
            ShowAnnotationsDetail={ShowAnnotationsDetail}
            minBPM={minBPM}
            maxBPM={maxBPM}
            selectedView={props.selectedView}
            setSelectedView={props.setSelectedView}
            isEventClicked={props.isEventClicked}
            leftMenuDataObject={leftMenuDataObject}
            setLeftMenuEventSelected={setLeftMenuEventSelected}
            setLeftMenuType={setLeftMenuType}
          />
        )}
        <div
          className={`ecg--right ${
            props.isLeftMenu === false ? "LeftMenuClosedClass" : ""
          }`}
          id="MainBottomDiv2"
        >
          <div className="ecg--right__top">
            <div className="ecg--right__top__strip no-display">
              <span className="no-display">
                Bryan Kendrick 56 (M) - 17/07/2022
              </span>
              <div className="ecg--right__buttons no-display">
                <a className="ecg--right__a" onClick={ShowHideErrorModal}>
                  +
                </a>
                {/* <a onClick={() => download()}>
                  <img
                    src="/images/e16.png"
                    width="28"
                    height="28"
                    alt="cross"
                  />
                </a> */}
                <a className="btn--save" onClick={() => download()}>
                  Save
                </a>
                <a className="btn--close">Close</a>
              </div>
            </div>
            <div className="ecg--right__top__controls ecg--right__top__controls__main">
              <div className="ecg--right__top__controls__left">
                <div className="controls--pt1">
                  <a
                    title="Pointer"
                    onClick={() => EcgGraphClick()}
                    className={`${
                      currentControl === 1 ? "control--open1" : ""
                    }`}
                  >
                    <img
                      src="/images/e1.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-norm"
                    />
                    <img
                      src="/images/e1s.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-sele"
                    />
                  </a>
                  <a
                    title="Horizontal Caliper Tool"
                    onClick={() => HorizontalCaliperTool()}
                    className={`${currentControl === 2 ? "control--open2" : ""}
                    ${
                      props.selectedView !== AppContext.view.Analysis
                        ? "opacity-disable"
                        : ""
                    }
                    `}
                  >
                    <img
                      src="/images/e2.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-norm"
                    />
                    <img
                      src="/images/e2s.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-sele"
                    />
                  </a>
                  <a
                    title="Tag Tool"
                    className={`${currentControl === 3 ? "control--open3" : ""}
                    ${
                      props.selectedView !== AppContext.view.Analysis
                        ? "opacity-disable"
                        : ""
                    }
                    `}
                    onClick={() => {
                      TagTool();
                      //setShowRhythmPopup(true);
                    }}
                  >
                    <img
                      src="/images/e3.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-norm"
                    />
                    <img
                      src="/images/e3s.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-sele"
                    />
                  </a>
                  <a
                    title="Beat Annotation Tool"
                    className={`${currentControl === 4 ? "control--open4" : ""}
                    ${
                      props.selectedView !== AppContext.view.Analysis
                        ? "opacity-disable"
                        : ""
                    }
                    `}
                    onClick={() => BeatAnnotationTool()}
                  >
                    <img
                      src="/images/e4.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-norm"
                    />
                    <img
                      src="/images/e4s.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="e-sele"
                    />
                  </a>
                  <a
                    title="Ecg Invert"
                    onClick={() => EcgInvert()}
                    className={`${invertGraph === 1 ? "control--open" : ""}`}
                  >
                    <img
                      src="/images/e5.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <a
                    title="Show/Hide Grid"
                    onClick={() => ShowHideGrid()}
                    className={`${showGrid === 1 ? "control--open" : ""}`}
                  >
                    <img
                      src="/images/e6.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <a
                    title="Show/Hide Ruler"
                    onClick={() => ShowHideRuler()}
                    className={`${showRuler === 1 ? "control--open" : ""}`}
                  >
                    <img
                      src="/images/e7.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <div className="animation start-home">
                    {/* <img
                      src="/images/selected.png"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="animation--images"
                    /> */}
                    <span></span>
                  </div>
                </div>
              </div>
              <div className="ecg--right__top__controls__right">
                <div className="controls--pt2">
                  <div className="controls--pt2__ddl" title="Gain">
                    <a
                      className={`${
                        props.selectedView === AppContext.view.Reports
                          ? "opacity-disable"
                          : "no-display"
                      }
                    `}
                    >
                      {/* {SelectedmmvValue} */}
                      <span>Gain</span>
                      <img
                        src="/images/e8.png"
                        width="20px"
                        height="20px"
                        alt="cross"
                      />
                    </a>
                    <a
                      onClick={() =>
                        ShowHideDropDown(!DropDown.MV, false, false, false)
                      }
                      className={`${
                        props.selectedView === AppContext.view.Reports
                          ? "no-display"
                          : ""
                      }
                      `}
                    >
                      {/* {SelectedmmvValue} */}
                      <span>Gain</span>
                      <img
                        src="/images/e8.png"
                        width="20px"
                        height="20px"
                        alt="cross"
                      />
                    </a>
                    {DropDown.MV && (
                      <div className="controls--pt2__ddl__drop custom--scroll">
                        <a
                          onClick={() => {
                            setmmvValue(10);
                            setmmvSelectedmmvValue("10mm/mV");
                            ChangeAmplitude();
                          }}
                        >
                          10mm/mV
                        </a>
                        <a
                          onClick={() => {
                            setmmvValue(15);
                            setmmvSelectedmmvValue("15mm/mV");
                            ChangeAmplitude();
                          }}
                        >
                          15mm/mV
                        </a>
                        <a
                          onClick={() => {
                            setmmvValue(20);
                            setmmvSelectedmmvValue("20mm/mV");
                            ChangeAmplitude();
                          }}
                        >
                          20mm/mV
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="controls--pt2__ddl" title="Speed">
                    <a
                      className={`${
                        props.selectedView === AppContext.view.Reports
                          ? "opacity-disable"
                          : "no-display"
                      }
                      `}
                    >
                      {/* {SelectedmmsValue} */}
                      <span>Speed</span>
                      <img
                        src="/images/e8.png"
                        width="20px"
                        height="20px"
                        alt="cross"
                      />
                    </a>
                    <a
                      onClick={() =>
                        ShowHideDropDown(false, !DropDown.MS, false, false)
                      }
                      className={`${
                        props.selectedView === AppContext.view.Reports
                          ? "no-display"
                          : ""
                      }
                      `}
                    >
                      {/* {SelectedmmsValue} */}
                      <span>Speed</span>
                      <img
                        src="/images/e8.png"
                        width="20px"
                        height="20px"
                        alt="cross"
                      />
                    </a>
                    {DropDown.MS && (
                      <div
                        className="controls--pt2__ddl__drop custom--scroll"
                        onClick={() =>
                          ShowHideDropDown(false, false, false, false)
                        }
                      >
                        <a
                          onClick={() => {
                            setmmsValue(15);
                            setSelectedmmsValue("15mm/s");
                            ChangeSpeed();
                          }}
                        >
                          15mm/s
                        </a>
                        <a
                          onClick={() => {
                            setmmsValue(20);
                            setSelectedmmsValue("20mm/s");
                            ChangeSpeed();
                          }}
                        >
                          20mm/s
                        </a>
                        <a
                          onClick={() => {
                            setmmsValue(25);
                            setSelectedmmsValue("25mm/s");
                            ChangeSpeed();
                          }}
                        >
                          25mm/s
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="controls--pt2__ddl" title="Layout">
                    <a
                      onClick={() =>
                        ShowHideDropDown(false, false, !DropDown.Channel, false)
                      }
                    >
                      <span>{SelectedChannel.Name}</span>
                      <img
                        src="/images/e8.png"
                        width="20px"
                        height="20px"
                        alt="cross"
                      />
                    </a>
                    {DropDown.Channel && (
                      <div className="controls--pt2__ddl__drop custom--scroll">
                        <a
                          className={`${
                            SelectedChannel.ID === 1 ? "selected-Channel" : ""
                          }`}
                          onClick={() =>
                            LoadDataSetAccordingToChannel(1, "Channel 1")
                          }
                        >
                          Channel 1
                        </a>
                        <a
                          className={`${
                            SelectedChannel.ID === 2 ? "selected-Channel" : ""
                          }`}
                          onClick={() =>
                            LoadDataSetAccordingToChannel(2, "Channel 2")
                          }
                        >
                          Channel 2
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="controls--pt1">
                  <a
                    title="Previous Signal"
                    onClick={() => SignalStartPrevious()}
                    className="no-display"
                  >
                    <img
                      src="/images/e9.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <a title="Signal Start" onClick={() => SignalStartTool()}>
                    <img
                      src="/images/e10.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <a
                    className="no-display"
                    title="Next Signal"
                    onClick={() => SignalStartNext()}
                  >
                    <img
                      src="/images/e9.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="rotate-180"
                    />
                  </a>
                </div>
                <div className="controls--pt2">
                  <div className="controls--pt2__ddl" title="Study Day">
                    <a
                      onClick={() =>
                        ShowHideDropDown(false, false, false, !DropDown.Date)
                      }
                    >
                      {/* <span>{SelectedDate}</span> */}
                      <img
                        src="/images/calender.svg"
                        width="20px"
                        height="20px"
                        alt="calender"
                        className="ddl-calendar"
                      />
                      <img
                        src="/images/e8.png"
                        width="20px"
                        height="20px"
                        alt="cross"
                      />
                    </a>
                    {DropDown.Date && (
                      <div className="controls--pt2__ddl__drop custom--scroll">
                        {SelectedDataSet &&
                          SelectedDataSet.map((Item: any, index: any) => (
                            <a
                              key={index}
                              onClick={() => StudyDay(index, Item.date)}
                            >
                              D{index + 1} - {Item.date}
                            </a>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="controls--pt3">
                  {/* <input
                    type="text"
                    value={SelectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    onBlur={() => ChackTimeValidaton()}
                    placeholder="08:20:00"
                  /> */}
                  <InputMask
                    mask="99:99:99"
                    placeholder="08:20:00"
                    value={SelectedTime}
                    onChange={(e: any) => setSelectedTime(e.target.value)}
                    onBlur={() => ChackTimeValidaton()}
                  ></InputMask>
                  <a title="Jump to Date/Time" onClick={() => ChangeDateTime()}>
                    <img
                      src="/images/e11.png"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                </div>
                <div className="controls--pt1">
                  <a
                    title="Show/Hide Bookmarks Panel"
                    onClick={() => ShowHideLeftMenu()}
                    // className={`${isLeftMenu === true ? "control--open left--menu--btn" : " left--menu--btn"}`}
                    className="btn--left__menu no-display"
                  >
                    <img
                      src="/images/m1.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <a
                    title="Show/Hide Graphs Panel"
                    onClick={() => ShowHideTrends()}
                    className={`${
                      BottomDiv.HRTrendView === true ? "control--open" : ""
                    }
                    ${
                      props.selectedView === AppContext.view.Reports
                        ? "no-display"
                        : ""
                    }
                    `}
                  >
                    <img
                      src="/images/e13.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="height--20"
                    />
                  </a>
                  <a
                    className={`${
                      props.selectedView === AppContext.view.Reports
                        ? "opacity-disable"
                        : "no-display"
                    }
                    `}
                  >
                    <img
                      src="/images/e13.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="height--20"
                    />
                  </a>
                  <a
                    title="Show/Hide Templates"
                    onClick={() => ShowHideTemplates()}
                    className={`no-display${
                      BottomDiv.BottomDiv2 === true ? "control--open" : ""
                    }`}
                  >
                    <img
                      src="/images/e14.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <a
                    title="Show/Hide ECG Split View"
                    onClick={() => OnOffEcgSplitView()}
                    className={`${
                      BottomDiv.ECGSplitView === true ? "control--open" : ""
                    }
                    ${
                      props.selectedView === AppContext.view.Reports
                        ? "no-display"
                        : ""
                    }
                    `}
                  >
                    <img
                      src="/images/e15.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="height--20"
                    />
                  </a>
                  <a
                    className={`${
                      props.selectedView === AppContext.view.Reports
                        ? "opacity-disable"
                        : "no-display"
                    }
                    `}
                  >
                    <img
                      src="/images/e15.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                      className="height--20"
                    />
                  </a>

                  <a
                    title="Show/Hide Beats View"
                    onClick={() => ShowHideBeatsView()}
                    className={`${BeatDiv === true ? "control--open" : ""}
                    ${
                      props.selectedView === AppContext.view.Reports
                        ? "no-display"
                        : ""
                    }
                    `}
                  >
                    <img
                      src="/images/beat.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  <a
                    className={`${
                      props.selectedView === AppContext.view.Reports
                        ? "opacity-disable"
                        : "no-display"
                    }
                    `}
                  >
                    <img
                      src="/images/beat.svg"
                      width="20px"
                      height="20px"
                      alt="cross"
                    />
                  </a>
                  {ShowHideUndo && (
                    <a
                      title="Undo"
                      onClick={() => UndoDelete()}
                      //className={`${Undo === true ? "control--open" : ""}`}
                    >
                      <img
                        src="/images/undo.svg"
                        width="20px"
                        height="20px"
                        alt="cross"
                      />
                    </a>
                  )}
                </div>

                <a
                  onClick={CheckUncheckFinalize}
                  title="Finalize"
                  className=" no-display"
                >
                  {Clickfinalize ? (
                    <img
                      src="/images/final-on.png"
                      width="31"
                      height="20"
                      alt="cross"
                    />
                  ) : (
                    <img
                      src="/images/final-off.png"
                      width="31"
                      height="20"
                      alt="cross"
                    />
                  )}

                  <span>Finalize</span>
                </a>
                <a className="no-display" title="Download">
                  <img
                    src="/images/download.svg"
                    width="20"
                    height="20"
                    alt="cross"
                  />
                </a>
                <a className=" no-display" title="Save">
                  Save
                </a>
                <a
                  className="no-display"
                  title="Show Strips"
                  onClick={() => ShowStripsPanel()}
                >
                  <img
                    src="/images/strips.svg"
                    width="25"
                    height="25"
                    alt="cross"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="clinic-details">
            <div className="cl-left">
              <span className="dia--name">Diagnosis:</span>
              {props.dataSetForSelectedPatient === 2 && (
                <span className="dia--data dia-data-green">Normal</span>
              )}
              {props.dataSetForSelectedPatient === 3 && (
                <span className="dia--data">Abnormalities Found</span>
              )}
              {props.selectedView === AppContext.view.Events && (
                <>
                  <div className="toggle-btns">
                    <span
                      className={`toggle-text ${
                        !isStripeToogleView ? "green-text" : ""
                      }`}
                    >
                      Reclassify
                    </span>
                    <a onClick={() => ToogleView()} className="btn-events">
                      <span
                        className={`toggle-ball ${
                          !isStripeToogleView ? "" : "move-to-right"
                        }`}
                      ></span>
                    </a>
                    {/* <a
                  onClick={() => ToogleView()}
                  className={`btn-data ${isStripeToogleView ? "selected" : ""}`}
                >
                  <span className="toggle-ball"></span>
                </a> */}
                    <span
                      className={`toggle-text toggle-text-num ${
                        !isStripeToogleView ? "" : "green-text"
                      }`}
                    >
                      {/* update when we bind left menu */}
                      Add to strips
                      {isStripeToogleView && (
                        <>({ReturnAnnotationsCount(AnnotationTagType.AF)}) </>
                      )}
                    </span>
                  </div>
                  <GridSelection
                    getSelectedRowsAndColumns={getSelectedRowsAndColumns}
                  />
                  <div className="nsvaf">
                    <a>N</a>
                    <a>S</a>
                    <a>V</a>
                    <a>A</a>
                    <a>F</a>
                  </div>
                </>
              )}
            </div>

            <div className="cl-right">
              <div className="info-detail">
                <span className="c-name">Clinic Name :</span>
                <span className="c-text">Cleveland Clinic</span>
              </div>
              <div className="info-detail">
                <span className="c-name">Physician Name :</span>
                <span className="c-text">Dr. Park Ave</span>
              </div>
              <div className="info-detail">
                <span className="c-name">Clinical Notes :</span>
                <span className="c-text">Cn-ITds....</span>
              </div>
            </div>
          </div>

          {BeatDiv && props.selectedView !== AppContext.view.Reports && (
            <div id="BPMGraphDiv" className="ecg--right__top__pop">
              <BPMGraph
                beatDivShowHide={BeatDiv}
                mmsValueSplitView={mmsValueSplitView}
                globalW={globalW}
                SelectedDataSet={SelectedDataSet}
                BPMDataSet={BPMDataSet}
                DataStartDate={new Date(SelectedDataSet[0].date + " 00:00:00")}
                DataEndDate={
                  new Date(
                    SelectedDataSet[SelectedDataSet.length - 1].date +
                      " 00:00:00"
                  )
                }
                handleMoveGraph={handleMoveGraph}
                setintervalSplitView={setintervalSplitView}
                SelectedTime={SelectedTime}
                lastClick={lastClick}
                setlastClick={setlastClick}
                AppArea={AppArea}
              />
            </div>
          )}

          <div
            className={`ecg--right__bottom ${
              BottomDiv.HRTrendView === true || BottomDiv.ECGSplitView === true
                ? "ecg--right__bottom__sm"
                : ""
            } 
            ${BeatDiv === true ? "ecg--right__bottom__sm__beat" : ""}
            ${
              props.selectedView === AppContext.view.Events ||
              props.selectedView === AppContext.view.Strips
                ? "ecg--right__bottom__sm__events"
                : ""
            }
            ${
              props.selectedView === AppContext.view.Reports
                ? "ecg--right__bottom__sm__reports"
                : ""
            }
            `}
          >
            <ProgressBarAnimation
              progressBarCoutDown={props.progressBarCoutDown}
            />

            {props.selectedView === AppContext.view.Analysis && (
              <>
                <div id="MainSVGContainer" className="ecg--right__bottom__in">
                  <svg id="mainSVG" ref={svgRef}></svg>
                </div>

                <div className="ecg--right__scroll" id="ScrollBar">
                  <a className="scroll-1" onClick={() => jumpStartTime(-1)}>
                    <img
                      src="/images/up.svg"
                      width="11px"
                      height="14px"
                      alt="up"
                    />
                  </a>
                  <svg id="scrollSVG" ref={svgScrollRef}></svg>
                  <a className="scroll-2" onClick={() => jumpStartTime(1)}>
                    <img
                      src="/images/down.svg"
                      width="11px"
                      height="14px"
                      alt="down"
                    />
                  </a>
                  <a className="scroll-3" onClick={() => jumpStartTime(-5)}>
                    <img
                      src="/images/fastup.svg"
                      width="19px"
                      height="12px"
                      alt="down"
                    />
                  </a>
                  <a className="scroll-4" onClick={() => jumpStartTime(5)}>
                    <img
                      src="/images/fastdown.svg"
                      width="19px"
                      height="12px"
                      alt="down"
                    />
                  </a>
                </div>
              </>
            )}

            {props.selectedView === AppContext.view.Events && (
              <>
                <Events
                  gridViewInfo={gridViewInfo}
                  allAnnotations={AllAnnotations}
                  leftMenuType={leftMenuType}
                  setAllAnnotations={setAllAnnotations}
                  isStripeToogleView={isStripeToogleView}
                  //                  getBeatDataInRange={getBeatDataInRangeEDF}
                  getBeatDataInRange={getBeatDataInRange}
                  getPeakDataInRange={getPeakDataInRange}
                  leftMenuEventSelected={leftMenuEventSelected}
                  handleMoveGraph={handleMoveGraph}
                  setSelectedView={props.setSelectedView}
                />
              </>
            )}

            {props.selectedView === AppContext.view.Reports && (
              <>
                <div className="reports-tabs">
                  <a
                    className={`${reportType === 1 ? "SelectedTab" : ""}`}
                    onClick={() => setReportType(1)}
                  >
                    Patient Demographics
                  </a>
                  <a
                    className={`${reportType === 2 ? "SelectedTab" : ""}`}
                    onClick={() => setReportType(2)}
                  >
                    Histogram
                  </a>
                  <a
                    className={`${reportType === 3 ? "SelectedTab" : ""}`}
                    onClick={() => setReportType(3)}
                  >
                    Notification Criteria
                  </a>

                  <a
                    className={`${reportType === 4 ? "SelectedTab" : ""}`}
                    onClick={() => setReportType(4)}
                  >
                    Summary
                  </a>

                  <a
                    className={`${reportType === 5 ? "SelectedTab" : ""}`}
                    onClick={() => setReportType(5)}
                  >
                    Reports
                  </a>
                </div>
                <div className="study-panel">
                  <div className="study--inn">
                    <div className="study-data">
                      <span>
                        <b>Study Type :</b>
                      </span>
                      <span>Holter</span>
                    </div>
                    <div className="study-data">
                      <span>
                        <b>Study ID : </b>
                      </span>
                      <span>43525</span>
                    </div>
                    <div className="study-data">
                      <span>
                        <b>Facility :</b>
                      </span>
                      <span>Cardiovascular Consultants</span>
                    </div>
                    <div className="study-data">
                      <span>
                        <b>Implant Device:</b>
                      </span>
                      <img
                        src="images/heart.png"
                        alt="heart"
                        width="16"
                        height="18"
                      />
                    </div>
                  </div>
                </div>
                {props.dataSetForSelectedPatient === 2 && (
                  <>
                    {reportType === 1 && (
                      <PatientDemographicsPage></PatientDemographicsPage>
                    )}
                    {reportType === 2 && <HistogramPage></HistogramPage>}
                    {reportType === 3 && (
                      <NotificationCriteriaPage></NotificationCriteriaPage>
                    )}
                    {reportType === 4 && <SummaryPage></SummaryPage>}
                    {reportType === 5 && <ReportsPage></ReportsPage>}
                  </>
                )}

                {props.dataSetForSelectedPatient === 3 && (
                  <>
                    {reportType === 1 && (
                      <PatientDemographicsPage2></PatientDemographicsPage2>
                    )}
                    {reportType === 2 && <HistogramPage2></HistogramPage2>}
                    {reportType === 3 && (
                      <NotificationCriteriaPage2></NotificationCriteriaPage2>
                    )}
                    {reportType === 4 && <SummaryPage2></SummaryPage2>}
                    {reportType === 5 && <ReportsPage2></ReportsPage2>}
                  </>
                )}
              </>
            )}

            {props.selectedView === AppContext.view.Strips && (
              <>
                <div className="strips-main">
                  <div className="strips-inner">
                    <div className="ecg--strips__close">
                      <div className="ecg--strips__close__inn">
                        <span className="stripstxt">{reportedStripCount}</span>
                        <span className="stripstxt">Reported Strip</span>
                      </div>
                      <div className="ecg--strips__close__inn">
                        <a
                          className="strips--add__report"
                          onClick={ShowHideStripsModal}
                        >
                          <span>Add to Report</span>{" "}
                          <img
                            src="/images/tick.svg"
                            alt="up"
                            width="17"
                            height="20"
                          />
                        </a>
                      </div>
                    </div>

                    <StripsPanel
                      allAnnotations={AllAnnotations}
                      selectedDataSetAll={SelectedDataSetAll}
                      mmvValueSplitView={mmvValueSplitView}
                      showStrips={true}
                      selectedView={props.selectedView}
                    />
                  </div>
                </div>
              </>
            )}

            {/* <LeftMenuAnnotationsView
              allAnnotations={AllAnnotations}
              deletedAnnotations={DeletedAnnotations}
              AnnotationType={AnnotationType}
              ViewAnnotation={ViewAnnotation}
              revertAnnotationPeaksInRange={revertAnnotationPeaksInRange}
              setAllAnnotations={setAllAnnotations}
              setDeletedAnnotations={setDeletedAnnotations}
            /> */}
          </div>
          {BottomDiv.HRTrendView && (
            <div className="ecg--right__bottom__pop">
              {/* <>
              <div>All</div>
              {props.SelectedDataSet &&
                props.SelectedDataSet.map((Item: any, index: any) => (
                  <div key={index}>{index + 1}</div>
                ))}
            </> */}
              <div className="ecg--trends">
                {props.selectedView !== AppContext.view.Reports && (
                  <div className="ecg--trends__left">
                    <a
                      onClick={() => ShowHideHRTrends1()}
                      className={`${
                        ShoweHRTrends1 === true ? "trends--selected" : ""
                      }`}
                    >
                      HR1
                    </a>
                    <a
                      onClick={() => ShowHideHRTrends2()}
                      className={`${
                        ShoweHRTrends2 === true ? "trends--selected" : ""
                      }`}
                    >
                      HR2
                    </a>
                  </div>
                )}
                {ShoweHRTrends1 &&
                  props.selectedView !== AppContext.view.Reports && (
                    <HRTrendView
                      hrTrendsShowHide={BottomDiv.HRTrendView}
                      mmsValueSplitView={mmsValueSplitView}
                      globalW={globalW}
                      lastClick={lastClick}
                      setlastClick={setlastClick}
                      SelectedDataSet={SelectedDataSet}
                      SelectedTime={SelectedTime}
                      allMinutesHR={allMinutesHR}
                      handleMoveGraph={handleMoveGraph}
                      getAllBPMFromDataSet={getAllBPMFromDataSet}
                      setintervalSplitView={setintervalSplitView}
                      AppArea={AppArea}
                    />
                  )}
                {ShoweHRTrends2 &&
                  props.selectedView !== AppContext.view.Reports && (
                    <HRTrendBarsView
                      hrTrendsShowHide={BottomDiv.HRTrendView}
                      mmsValueSplitView={mmsValueSplitView}
                      globalW={globalW}
                      SelectedDataSet={SelectedDataSet}
                      DataStartDate={
                        new Date(SelectedDataSet[0].date + " 00:00:00")
                      }
                      DataEndDate={
                        new Date(
                          SelectedDataSet[SelectedDataSet.length - 1].date +
                            " 00:00:00"
                        )
                      }
                      handleMoveGraph={handleMoveGraph}
                      getAllBPMFromDataSet={getAllBPMFromDataSet}
                      setintervalSplitView={setintervalSplitView}
                      SelectedTime={SelectedTime}
                      lastClick={lastClick}
                      setlastClick={setlastClick}
                      AppArea={AppArea}
                    />
                  )}
              </div>
            </div>
          )}

          <div
            className={`ecg--right__bottom__pop2 ${
              BottomDiv.BottomDiv2 ? "ecg--strips__panel__open" : ""
            }`}
          >
            {BottomDiv.BottomDiv2 && (
              <Templates
                templatesShowHide={BottomDiv.BottomDiv2}
                mmvValueSplitView={mmvValueSplitView}
                selectedDataSetAll={SelectedDataSetAllRef.current}
                ECGTemplates={ECGTemplates}
                AllEctopies={AllEctopies}
                ShowHideTemplates={ShowHideTemplates}
                getBeatDataInRangeEDF={getBeatDataInRangeEDF}
                ViewMeasurement={ViewMeasurement}
                isEDFData={isEDFData}
                GraphColors={AppContext.GraphColors}
              />
            )}
          </div>

          {!BottomDiv.ECGSplitView &&
            !BottomDiv.HRTrendView &&
            SelectedDataSet &&
            SelectedDataSet.length > 0 &&
            props.selectedView !== AppContext.view.Strips &&
            props.selectedView !== AppContext.view.Reports && (
              <div className="histogram">
                <HREventChart
                  beatDivShowHide={BeatDiv}
                  mmsValueSplitView={mmsValueSplitView}
                  globalW={globalW}
                  SelectedDataSet={SelectedDataSet}
                  BPMDataSet={AllBPMs}
                  DataStartDate={
                    new Date(SelectedDataSet[0].date + " 00:00:00")
                  }
                  DataEndDate={
                    new Date(
                      SelectedDataSet[SelectedDataSet.length - 1].date +
                        " 00:00:00"
                    )
                  }
                  handleMoveGraph={handleMoveGraph}
                  setintervalSplitView={setintervalSplitView}
                  SelectedTime={SelectedTime}
                  lastClick={lastClick}
                  setlastClick={setlastClick}
                  AppArea={AppArea}
                  afibData={afibData}
                  sinusData={sinusData}
                  highestHR={highestHR}
                  lowestHR={lowestHR}
                />
              </div>
            )}

          {BottomDiv.ECGSplitView &&
            props.selectedView !== AppContext.view.Reports && (
              <SplitView
                splitViewShowHide={BottomDiv.ECGSplitView}
                mmsValueSplitView={mmsValueSplitView}
                mmvValueSplitView={mmvValueSplitView}
                globalW={globalW}
                startSignal={startSignal}
                IndexedDataSet={IndexedDataSet}
                startOfSplitBoxRef={startOfSplitBoxRef}
                secondsInSplit={secondsInSplit}
                h={h}
                showRuler={showRuler}
                mmvValue={mmvValue}
                invertGraph={invertGraph}
                AllAnnotations={AllAnnotations}
                startOfSplitBox={startOfSplitBox}
                setmmsValueSplitView={setmmsValueSplitView}
                setmmvValueSplitView={setmmvValueSplitView}
                setintervalSplitView={setintervalSplitView}
                timeDifferenceFixedY={timeDifferenceFixedY}
                getTimeFromXandY={getTimeFromXandY}
                getBPMAndBeats={getBPMAndBeats}
                PushMeasurementInArray={PushMeasurementInArray}
                GraphStartTimeRef={GraphStartTimeRef}
                setTagStartX={setTagStartX}
                setTagStartY={setTagStartY}
                setTagEndX={setTagEndX}
                setTagEndY={setTagEndY}
                setShowRhythmPopup={setShowRhythmPopup}
                getNearestPeak={getNearestPeak}
                SelectedDataSetAll={SelectedDataSetAll}
              />
            )}
        </div>
      </div>

      {/* <div
        className={`ecg--strips__panel ${
          ShowStrips === true ? "ecg--strips__panel__open" : ""
        }`}
      > */}
      {/* <div className="ecg--strips__close">
          <div className="ecg--strips__close__inn">
            <span className="stripstxt">Reported Strips:</span>
            <span className="stripstxt">{TotalCountOfSelected}</span>
          </div>
          <div className="ecg--strips__close__inn">
            <a className="strips--add__report">Add to Report </a>
            <a className="strips--close" onClick={() => HideStripsPanel()}>
              <img src="/images/close.svg" width="13" height="13" alt="cross" />
            </a>
          </div>
        </div> */}

      {/* <StripsPanel
          allAnnotations={AllAnnotations}
          selectedDataSetAll={SelectedDataSetAll}
          mmvValueSplitView={mmvValueSplitView}
          showStrips={ShowStrips}
          setTotalCountOfSelected={setTotalCountOfSelected}
          selectedView={props.selectedView}
          TotalCountOfSelected={TotalCountOfSelected}
        /> */}
      {/* </div> */}

      <Modal show={showRhythmPopup}>
        {/* onHide={!showRhythmPopup} */}
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ecg--popup">
            <div className="ecg--popup__title">Rythm Annotation</div>
            <div className="ecg--checkboxes">
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  name="Pause"
                  checked={RythmAnnotationObj.Pause}
                  className="input--type__check"
                  onChange={(e) =>
                    SetRythmAnnotation(e, AnnotationTagType.Pause)
                  }
                />
                <span className="check--mark"></span>
                <span className="input--check__text">Pause</span>
              </div>
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="AVB"
                  checked={RythmAnnotationObj.AVB}
                  onChange={(e) => SetRythmAnnotation(e, AnnotationTagType.AVB)}
                />
                <span className="check--mark"></span>
                <span className="input--check__text">AVB</span>
              </div>
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="AF"
                  checked={RythmAnnotationObj.AF}
                  onChange={(e) => SetRythmAnnotation(e, AnnotationTagType.AF)}
                />
                <span className="check--mark"></span>
                <span className="input--check__text">AF</span>
              </div>
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="SVT"
                  checked={RythmAnnotationObj.SVT}
                  onChange={(e) => SetRythmAnnotation(e, AnnotationTagType.SVT)}
                />
                <span className="check--mark"></span>
                <span className="input--check__text">SVT</span>
              </div>
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="Noise"
                  checked={RythmAnnotationObj.Noise}
                  onChange={(e) =>
                    SetRythmAnnotation(e, AnnotationTagType.Noise)
                  }
                />
                <span className="check--mark"></span>
                <span className="input--check__text">Noise</span>
              </div>
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="AT"
                  checked={RythmAnnotationObj.AT}
                  onChange={(e) => SetRythmAnnotation(e, AnnotationTagType.AT)}
                />
                <span className="check--mark"></span>
                <span className="input--check__text">AT</span>
              </div>
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="Normal"
                  checked={RythmAnnotationObj.Normal}
                  onChange={(e) =>
                    SetRythmAnnotation(e, AnnotationTagType.Normal)
                  }
                />
                <span className="check--mark"></span>
                <span className="input--check__text">Normal</span>
              </div>
            </div>
            <div className="ecg--checkboxes__report">
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="Report"
                  checked={Report}
                  onChange={(e) => SetReportRythmAnnotation(e)}
                />
                <span className="check--mark"></span>
                <span className="input--check__text">Report</span>
                <span className="report--span"></span>
              </div>
              <div
                className={`report--radio ${
                  Report === true ? "report--selected" : ""
                }`}
              >
                <div className="input--radio radio--btn">
                  <input
                    type="radio"
                    name="Report"
                    className="input--type__radio"
                    disabled={Report === false}
                    onClick={(e) =>
                      SetReportRythmAnnotationSubValue(TagReport.EntireEpisode)
                    }
                  />
                  <span className="radio--mark"></span>
                  <span className="input--radio__text">Entire Episode</span>
                </div>
                <div className="input--radio radio--btn">
                  <input
                    type="radio"
                    name="Report"
                    className="input--type__radio"
                    disabled={Report === false}
                    onClick={(e) =>
                      SetReportRythmAnnotationSubValue(TagReport.Onset)
                    }
                  />
                  <span className="radio--mark"></span>
                  <span className="input--radio__text">Onset</span>
                </div>
                <div className="input--radio radio--btn">
                  <input
                    type="radio"
                    name="Report"
                    className="input--type__radio"
                    disabled={Report === false}
                    onClick={(e) =>
                      SetReportRythmAnnotationSubValue(TagReport.Termination)
                    }
                  />
                  <span className="radio--mark"></span>
                  <span className="input--radio__text">Termination</span>
                </div>
              </div>
            </div>
            <div className="ecg--checkboxes__mct">
              <div className="input--radio check--btn">
                <input
                  type="checkbox"
                  className="input--type__check"
                  name="MCTEvent"
                  checked={MCTEvent}
                  onChange={(e) => SetMCTEventRythmAnnotation(e)}
                />
                <span className="check--mark"></span>
                <span className="input--check__text">MCT Event</span>
              </div>
            </div>
            <div className="modal--button del--modal__btn">
              <a onClick={() => SaveRythmAnnotation()}>Save</a>
              <a onClick={() => setShowRhythmPopup(false)}>Cancel</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="Error--Modal"
        show={showStripsPopup}
        onHide={ShowHideStripsModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
          <a onClick={() => setshowStripsPopup(false)}>
            <img src="/images/cross.png" width="18" height="18" alt="cross" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="error-message">
            strips has been added to report successfully
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
